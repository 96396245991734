import React, { useEffect, useState } from 'react'
import Notification from '../../../components/Notification/Notifications'
import { connect } from 'react-redux'
import { fetchFilterData, fetchStoresData, fetchUserCreatedData, resetFiltersOptions } from '../ProductProfileAction'
import ProfileTable from './ProfileTable';
import * as Notify from '../../../components/Notification/Notifications'
import Select from '../../../components/Filters/SingleSelect'
import MultiSelect from '../../../components/Filters/MultiSelect';
import { cloneDeep, intersectionWith, isEmpty, isEqual } from "lodash";
import ColumnFilter from '../../UserManagement/ColumnFilter';
import { handleSelectAll } from '../../../utils/SelectAllHandler/SelectAllHander';
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import { getLevelFiltersForUserPreference, getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getDefaultFiltersCondition, isMandatory, getFilterForClients } from '../../../utils/commonUtilities';
import AsyncMulti from '../../../components/Filters/AsyncMulti';
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";

function IARecommendedProfile({
    // level1Options,level2Options,level3Options,level4Options,styleOptions,colourOptions,
    ...props}) {

    const [level1, setLevel1] = useState(null)
    const [level2, setLevel2] = useState(null)
    const [level3, setLevel3] = useState(null)
    const [level4, setLevel4] = useState(null)
    const [level5, setLevel5] = useState(null)
    const [level6, setLevel6] = useState(null)
    const [level7, setLevel7] = useState(null)
    const [style_name, setStyle] = useState(null)
    const [colour, setColour] = useState(null)
    const [isRowClicked, setIsRowClicked] = useState(false)
    const [column, setColumn] = useState([])
    const [dynamicHeader, setDynamicHeader] = useState('DCS')
    const [request,setRequest] = useState(null)
    const maxWidth = '100%'

    const [level1Options, setlevel1Options] = useState(null);
    const [level2Options, setlevel2Options] = useState(null);
    const [level3Options, setlevel3Options] = useState(null);
    const [level4Options, setlevel4Options] = useState(null);
    const [level5Options, setlevel5Options] = useState(null);
    const [level6Options, setlevel6Options] = useState(null);
    const [level7Options, setlevel7Options] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colourOptions, setColourOptions] = useState(null);
    const [articleId, setArticleId] = useState('');
    const [tableData, setTableData] = useState([])
    const [RTInstance, setRTInstance] = useState({})
    const [initialIndex, setInitialIndex] = useState(0)
    const [prevIndex, setPrevIndex] = useState(0)
    const [nextIndex, setNextIndex] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [searchTermReq, setSearchTermReq] = useState({})
    const [sortReq, setSortReq] = useState({})
    const [isFirstCall, setisFirstCall] = useState(true)
    const [selectedFilters, setSelectedFilters] = useState({})

    useEffect(() => {
        setlevel1Options(() => props.level1Options);
      }, [props.level1Options]);
      
    
      useEffect(() => {
        setlevel2Options(() => props.level2Options);
      }, [props.level2Options]);
      
      useEffect(() => {
        setlevel3Options(() => props.level3Options);
      }, [props.level3Options]);
      
      useEffect(() => {
        setlevel4Options(() => props.level4Options);
      }, [props.level4Options]);
    
      useEffect(() => {
        setlevel5Options(() => props.level5Options);
      }, [props.level5Options]);

      useEffect(() => {
        setlevel6Options(() => props.level6Options);
      }, [props.level6Options]);

      useEffect(() => {
        setlevel7Options(() => props.level7Options);
      }, [props.level7Options]);
    
    useEffect(() => {
      setStyleOptions(() => props.styleOptions);
    }, [props.styleOptions]);
    
    useEffect(() => {   
      setColourOptions(() => props.colourOptions);
    }, [ props.colourOptions]);

    useEffect(() => {
        if(props.activeTab == "ppi")
        getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onLevel1Change,onl2Change:onLevel2Change})
        props.resetFiltersOptions([]);
        setIsRowClicked(false);
        // resetFilters()
    },[props.activeTab])

    useEffect(() => {
        setColumn(props.userCreatedProfileTabsColumns)
    },[props.userCreatedProfileTabsColumns])

    const getStoresData = (p_instance) => {
        setArticleId(p_instance.row?.original?.article)
        setIsRowClicked(true)
        props.fetchStoresData({"product_profile_code":p_instance.row?.original?.product_profile_code,"page":"ia-recommended","l1_name":[level1.value],"l2_name":Array.isArray(level2) ? level2.map(filter => filter.value) : [level2.value]
    })   
    }

    const userCreatedProfileColumns = React.useMemo(() => [
        {
            Header: `${updateArticleName(false)} ID`,
            accessor: 'article',
            Cell: (instance) => (
                <div>
                  <div className="clickable" onClick = {() => getStoresData(instance)}>{instance.row?.original?.article}</div>
                </div>
              ),
              Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} placeholder="Search in bulk by ','" searchTermValue={searchTermReq?.article?.search} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "array", ",")} />
            ),
            filter: 'bulkFilterCommaSeperated',
        },
        {
            Header: 'Style ID',
            accessor: 'style_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: 'Color ID',
            accessor: 'color_code',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.color_code?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: 'Style Description',
            accessor: 'style_name',
            width:300,
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.style_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
            
        },
        {
            Header: levelFiltersLabelFormatter("level1"),
            accessor: 'l1_name',
            disableFilters: true
            
        },
        {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l2_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: props.filterLabels?.level3 ? props.filterLabels?.level3 : levelFiltersLabelFormatter("level3"),
            accessor: "l3_name",
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l3_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: 'l4_name',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l4_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level5"),
            accessor: 'l5_name',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l5_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: levelFiltersLabelFormatter("level6"),
            accessor: 'l6_name',
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.l6_name?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        },
        {
            Header: 'Similar Mapping',
            accessor: 'description',
            width:250,
            Filter: (instance) => (
                <ColumnFilterServerSide  {...instance} searchTermValue={searchTermReq?.description?.value} changeHandler={(term, id) => fecthDataWithSearchTerm(term, id, "string")} />
            ),
        }
    ])

    const resetRestFilters = (p_index) => {
        colour && setColour(null);
        style_name && setStyle(null);
        colourOptions && setColourOptions(null)
        styleOptions && setStyleOptions(null)
        if(p_index == 0){
            level2 && setLevel2(null);
            level2Options && setlevel2Options(null);
        }
        if(p_index == 0 || p_index == 1){
            level3 && setLevel3(null);
            level3Options && setlevel3Options(null)
        } 
        if(p_index == 0 || p_index == 1 || p_index == 2){
            level4 && setLevel4(null)
            level4Options && setlevel4Options(null)
        }
        if(p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3){
            level5 && setLevel5(null)
            level5Options && setlevel5Options(null)
        }   
        if(p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4){
            level6 && setLevel6(null)
            level6Options && setlevel6Options(null)
        } 
        if(p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 ||p_index == 4 || p_index == 5){
            level7 && setLevel7(null)
            level7Options && setlevel7Options(null)
        }     
    }

    const onLevel1Change = (val,p_userPreference) => {
        // if(val.value === "Apparel"){
            // setDynamicHeader('DCS')
        // }
        // else{
        //     setDynamicHeader('Sub Category')
        // }
       setLevel1(val)
       resetRestFilters(0)
        if(p_userPreference?.user_preference) {
            props.fetchFilterData({ l1_name: [val]},{key:'l10'});
        }
        else {
            props.fetchFilterData({l1_name:[val]},{key:'l2'})
        }
    //    props.fetchFilterData({l1_name:[val]},{key:'l2'},"style_name")
    //    props.fetchFilterData({l1_name:[val]},{key:'l2'},"color")
    }

    const onLevel2Change = (value,event,that) => {
        if (Array.isArray(value)) {
            let valuesArray = handleSelectAll(value,event,that)
            setRequest(old => {
                return {
                ...old,
                'level2': valuesArray[0],
                }
            })
            setLevel2(valuesArray[1])
            resetRestFilters(1)
            //API call to get next filter
            let req_val = intersectionWith(valuesArray[0], level2Options, isEqual);
            if(event?.user_preference) { 
                props.fetchFilterData({ l1_name: [event?.l1_name], l2_name: value },{key:'l3'});
            }
            else{
                props.fetchFilterData({ l1_name: [level1], l2_name: req_val }, { key: "l3" })
            }
            // props.fetchFilterData({l1_name:[level1],l2_name: req_val},{key:'l3'})
        }
        else {
            setLevel2(value)
            if(event?.user_preference) { 
              props.fetchFilterData({ l1_name: [event?.l1_name], l2_name: [value] },{key:'l3'});
            }
            else{
              props.fetchFilterData({ l1_name: [level1], l2_name: [value] }, { key: "l3" })
            }
            setRequest(old => {
              return {
                ...old,
                'level2': value,
              }
            })
            resetRestFilters(1)
        }
    }


    const onLevel3Change = (value,event,that) => {
        if (Array.isArray(value)) {
            let valuesArray = handleSelectAll(value,event,that)
            setRequest(old => {
                return {
                ...old,
                'level3': valuesArray[0],
                'level4': null,
                'level5': null,
                'level6': null,
                'level7': null,
                'color': null,
                'style_name': null
                }
            })
            setLevel3(valuesArray[1])
            resetRestFilters(2)
            //API call to get next filter
            let req_val = intersectionWith(valuesArray[0], level3Options, isEqual);
            props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: req_val},{key:'l4'})
        }
        else {
            setLevel3(value)
            if(event?.user_preference) { 
              props.fetchFilterData({ l1_name: [event?.l1_name], l2_name:  [event?.l2_name], l3_name:  [value] },{key:'l4'});
            }
            else{
              props.fetchFilterData({ l1_name: [level1], l2_name:  [level2], l3_name:  [value] }, { key: "l4" })
            }
            setRequest(old => {
              return {
                ...old,
                'level3': value,
                'level4': null,
                "level5": null,
                'level6': null,
                "level7": null,
                'color': null,
                'style_name': null
              }
            })
            resetRestFilters(2)
        }
    }

    const onLevel4Change = (value,event,that) => {
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level4': valuesArray[0],
            "level5": null,
            'level6': null,
            'level7': null,
            'color': null,
            'style_name': null
            }
        })
        setLevel4(valuesArray[1])
        resetRestFilters(3)
        //API call to get next filter
        let req_val = intersectionWith(valuesArray[0], level4Options, isEqual);
        !shouldHideForCurrentClient("l5_name") && props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: level3,l4_name: req_val},{key:'l6'})
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: req_val},{key:'l5'},"style_name")
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: req_val},{key:'l5'},"color")
    }

    const onLevel5Change = (value,event,that) => {

        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level5': valuesArray[0],
            'level6':null,
            'level7': null,
            'color': null,
            'style_name': null
            }
        })
        setLevel5(valuesArray[1])
        resetRestFilters(4)
        //API call to get next filter
        let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
        props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: request?.level3,l4_name: request?.level4,l5_name: req_val},{key:'l7'})
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: [request?.level4], l5_name:  req_val},{key:'l5'},"style_name")
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: [request?.level4], l5_name:  req_val},{key:'l5'},"color")
    }

    const onLevel6Change =(value,event,that)=>{
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level6': valuesArray[0],
            'level7':null,
            'color': null,
            'style_name': null
            }
        })
        setLevel6(valuesArray[1])
        resetRestFilters(5)
        let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
        props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: request?.level3,l4_name: request?.level4,l5_name: request?.level5,l6_name: req_val},{key:'l8'})
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: [request?.level4], l5_name: [request?.level5] ,l6_name: req_val},{key:'l5'},"style_name")
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: [request?.level4], l5_name: [request?.level5] ,l6_name: req_val},{key:'l5'},"color")
    }

    const onLevel7Change =(value,event,that)=>{
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
            return {
            ...old,
            'level7': valuesArray[0],
            'color': null,
            'style_name': null
            }
        })
        setLevel7(valuesArray[1])
        // resetRestFilters(5)
        let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
        props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: request?.level3,l4_name: request?.level4,l5_name: request?.level5,l6_name: request?.level6,l7_name:req_val},{key:'l5'})
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: [request?.level4], l5_name: [request?.level5] ,l6_name: [request?.level6],l7_name:req_val},{key:'l5'},"style_name")
        // props.fetchFilterData({l1_name:[level1],l2_name: [level2],l3_name: [request?.level3],l4_name: [request?.level4], l5_name: [request?.level5] ,l6_name: [request?.level6],l7_name:req_val},{key:'l5'},"color")
    }

    // const onStyleChange = (value,event,that) => {
    //     let valuesArray = handleSelectAll(value,event,that)
    //   // let value = intersectionWith(val, sizeOptions, isEqual);
    //     setRequest(old => {
    //         return {
    //         ...old,
    //         'style_name': valuesArray[0]
    //         }
    //     })
    //     // let value = intersectionWith(val, styleOptions, isEqual);
    //     setStyle(valuesArray[1])
    //     //API call to get next filter
        
    // }

    const onStyleChange = (val, event, that) => {
        // let valuesArray = handleSelectAll(val, event, that);
        let req_val = intersectionWith(val, styleOptions, isEqual);
        setStyle(req_val);
        setRequest(old => {
            return {
                ...old,
                'style_name': req_val,
            }
        });
    };

    
    const onColourChange = (value,event,that) => {
        let valuesArray = handleSelectAll(value,event,that)
          setRequest(old => {
              return {
              ...old,
              'color': valuesArray[0]
              }
          })
        // let value = intersectionWith(val, colourOptions, isEqual);
        setColour(valuesArray[1])
        //API call to get next filter
    }

    const applyFilters = () => {
        setSearchTermReq({})
        setSortReq({})
        setTableData([])
        setRTInstance(null)
        setInitialIndex(0)
        setPrevIndex(0)
        setTotalCount(0);
        setNextIndex(0);
        setIsRowClicked(false)
        if(getDefaultFiltersCondition({l1_name: level1, l2_name: level2, l3_name: level3})){
            setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:level1,l2_name:level2}))
            let req = {
                level1,
                level2,
                level3: level3,
                level4: level4,
                level5: level5,
                level6: level6,
                style_name: style_name,
                colour: colour,
                "page":"ia-recommended",
            }
            setSelectedFilters(req)
            props.fetchUserCreatedData({
                ...req,
                row_index: 0,
                row_count: 1000
            })
        }
        else{
            Notify.error("Please Select Mandatory Option!!");
        }
        
    }

    const resetFilters = () => {
        setRequest(null)
        setIsRowClicked(false)
        setLevel1(null);
        setLevel2(null);
        setLevel3(null);
        setLevel4(null);
        setLevel5(null);
        setLevel6(null);
        setLevel7(null);
        setStyle(null);
        setColour(null);
        props.resetFiltersOptions([]);
    }

    const fetchData = (index) => {
        if (prevIndex >= index || nextIndex >= totalCount)
            return;
        setPrevIndex(index)
        setInitialIndex(Math.floor(nextIndex / 10))
        props.fetchUserCreatedData({ ...selectedFilters, row_index: nextIndex, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq })
    }

    useEffect(() => {
        if (props.nextIndex == 100) {
            setPrevIndex(0)
            // setInitialIndex(0)
        }
        setTotalCount(props.reviewCall ? props.recDatatotalCount : props.totalCount)
        setNextIndex(props.reviewCall ? props.recDatanextIndex : props.nextIndex)
    }, [props.totalCount, props.nextIndex, props.recDatanextIndex, props.recDatatotalCount])

    const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
        if (p_type === "array") {
          if(split_type) {
            p_term = p_term.replace(/\s*,\s*/g, ",")
          }
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                var id = p_term.split(split_type ? split_type : " ")
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {

                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "values": id,
                        "search": p_term
                    }
                }
                return {
                    ...l_searchTermReq
                }
            })
        }
        else if (Array.isArray(p_term)) {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                let min = p_term[0] == "" ? null : p_term[0]
                let max = p_term[1] == "" ? null : p_term[1]
                if (min == null && max == null) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": {
                            "min": min,
                            "max": max,
                        }
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        else {
            setSearchTermReq((old) => {
                let l_searchTermReq = cloneDeep(old)
                if (p_term?.trim() == 0) {
                    l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id]
                }
                else {
                    l_searchTermReq[p_id] = {
                        "type": p_type,
                        "value": p_term
                    }
                }
                return {
                    ...l_searchTermReq,
                }
            })
        }
        isFirstCall && setisFirstCall(false)

    }

    const fecthDataWithSorting = (p_sort) => {
        if (!isEmpty(sortReq)) {
            isFirstCall && setisFirstCall(false)
        }
        setSortReq(p_sort[0])
    }

    useEffect(() => {
        if (props.nextIndex == 100) {
            setPrevIndex(0)
            // setInitialIndex(0)
        }
        setTotalCount(props.totalCount)
        setNextIndex(props.nextIndex)
    }, [props.totalCount, props.nextIndex])

    useEffect(() => {
        if ((!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) && !isEmpty(selectedFilters)) {
            setTableData([])
            setRTInstance(null)
            setInitialIndex(0)
            setPrevIndex(0)
            props.fetchUserCreatedData({ ...selectedFilters, row_index: 0, row_count: 1000, searchColumns: searchTermReq, sortColumn: sortReq?.[0] })
        }
    }, [searchTermReq, sortReq])

    useEffect(() => {
        if (props.userCreatedData?.length > 0) {
            if (RTInstance && RTInstance.data) {
                setTableData([...RTInstance.data, ...props.userCreatedData]);
            }
            else {
                setTableData(props.userCreatedData);
            }
            // calcTotal(data)
        }
        else {
            setTableData([])
            setRTInstance(null)
        }

    }, [props.userCreatedData])
    
    return (
        <div className="userCreatedProfile__container">
        <section className="row section">
        {/* <Notification /> */}
        <div className="col-md-3 required">
                <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                <Select
                    name="level1"
                    value={level1}
                    onChange={onLevel1Change}
                    placeHolder={`Select ${props.filterLabels?.level1}`}
                    options={(level1Options?.length) ? level1Options : []}
                />
            </div>
            <div className={`col-md-3 ${isMandatory("l2_name")}`}>
                <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
                <MultiSelect
                    name="level2"
                    dropdownValue={level2}
                    onDropDownChange={onLevel2Change}
                    placeHolder={`Select ${props.filterLabels?.level2}`}
                    optionsArray={(level2Options?.length) ? level2Options : []}
                />
            </div>
            {
                !shouldHideForCurrentClient("l3_name") &&
                <>
                    <div className={`col-md-3 ${isMandatory("l3_name")}`}>
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
                        <MultiSelect
                            name="level3"
                            dropdownValue={level3}
                            onDropDownChange={onLevel3Change}
                            placeHolder={`Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`}
                            optionsArray={(level3Options?.length) ? level3Options : []}
                        />
                    </div>
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                        <MultiSelect
                            name="level4"
                            dropdownValue={level4}
                            onDropDownChange={onLevel4Change}
                            placeHolder={`Select ${props.filterLabels?.level4}`}
                            optionsArray={(level4Options?.length) ? level4Options : []}
                        />
                    </div>
                    {
                    !shouldHideForCurrentClient("l5_name") &&
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                        <MultiSelect
                            name="level5"
                            dropdownValue={level5}
                            onDropDownChange={onLevel5Change}
                            placeHolder={`Select ${props.filterLabels?.level5}`}
                            optionsArray={(level5Options?.length) ? level5Options : []}
                        />
                    </div>
                    }
                      {
                    !shouldHideForCurrentClient("l6_name") &&
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                        <MultiSelect
                            name="level6"
                            dropdownValue={level6}
                            onDropDownChange={onLevel6Change}
                            placeHolder={`Select ${props.filterLabels?.level6}`}
                            optionsArray={(level6Options?.length) ? level6Options : []}
                        />
                    </div>
                    }
                      {
                    !shouldHideForCurrentClient("l7_name") &&
                    <div className="col-md-3 fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                        <MultiSelect
                            name="level7"
                            dropdownValue={level7}
                            onDropDownChange={onLevel7Change}
                            placeHolder={`Select ${props.filterLabels?.level7}`}
                            optionsArray={(level7Options?.length) ? level7Options : []}
                        />
                    </div>
                    }
                </>
                }
            {/* <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">Style</label>
                <MultiSelect
                    name="style_name"
                    dropdownValue={style_name}
                    onDropDownChange={onStyleChange}
                    placeHolder={"Select Style"}
                    optionsArray={(styleOptions?.length) ? styleOptions : []}
                />
            </div> */}
            <div className="col-md-3  fill">
              <label className="fnt-md fnt-bold">Style</label>
              <AsyncMulti
                  name="style"
                  dropdownValue={style_name}
                  onDropDownChange={onStyleChange}
                  placeHolder={'Select Style'}
                  optionsArray={styleOptions?.length ? styleOptions : []}
              />
            </div>
            <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">Color</label>
                <MultiSelect
                    name="colour"
                    dropdownValue={colour}
                    onDropDownChange={onColourChange}
                    placeHolder={"Select Color"}
                    optionsArray={(colourOptions?.length) ? colourOptions : []}
                />
            </div>
        <div className="col-md-3">
        <button onClick={applyFilters} className="btn btn-primary filter-constraints-mode" title="Apply filters"><i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter</button>
        <button onClick={() => resetFilters('All')} className="btn undo-constraints-mode" title="Reset filters"><i className="fa fa-undo mr-2"></i>Reset</button>
        </div>
    </section>
        <div className="row section">
        <div className="required fnt-md pl-4" style={{ color: "red" }}>
          <label></label>Fields are mandatory
        </div>
      </div>
        <ProfileTable
                header = {articleId}
                style={{margin:'4rem 0 2rem'}}
                baseTableColumns = {userCreatedProfileColumns}
                baseTableData = {tableData}
                baseTableError = {props.userCreatedError}
                baseTableloading = {props.userCreatedLoading}
                // tabColumns = {userCreatedProfileTabsColumns}
                tabData = {props.storeData}  
                tabDataError = {props.storeDataError} 
                tabDataLoading = {props.storeLoading}
                isShowTabs= {isRowClicked}
                maxWidth = {maxWidth}
                tabColumns = {column}
                setRTInstance={setRTInstance}
                fetchData={fetchData}
                initialPageIndex={initialIndex}
                totalCount={totalCount}
                fecthDataWithSorting={fecthDataWithSorting}
                fecthDataWithSearchTerm={fecthDataWithSearchTerm}
        />
    </div>
    )
}

const mapStateToProps = ({ productProfile }) => ({
    userCreatedProfileTabsColumns: productProfile.storeColumn,
    level1Options: productProfile.level1Options,
    level2Options: productProfile.level2Options,
    level3Options: productProfile.level3Options,
    level4Options: productProfile.level4Options,
    level5Options: productProfile.level5Options,
    level6Options: productProfile.level6Options,
    level7Options: productProfile.level7Options,
    styleOptions: productProfile.styleOptions,
    colourOptions: productProfile.colourOptions,
    filterLabels: productProfile.filterlabels,
    userCreatedData: productProfile.userCreatedData,
    userCreatedError: productProfile.userCreatedError,
    userCreatedLoading: productProfile.userCreatedloading,
    storeData: productProfile.storeData,
    storeDataError: productProfile.storeError,
    storeLoading: productProfile.storeloading,
    totalCount: productProfile.ppTotalCount,
    nextIndex: productProfile.ppNextIndex,
  });

  const mapDispatchToProps = (dispatch) => ({
    resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
    fetchUserCreatedData: (payload) => dispatch(fetchUserCreatedData(payload)),
    fetchFilterData: (payload,filterKey, filterType) => dispatch(fetchFilterData(payload,filterKey,filterType)),
    fetchStoresData: (payload) => dispatch(fetchStoresData(payload))
  });

export default connect(mapStateToProps, mapDispatchToProps)(IARecommendedProfile)
