import React, { useState, useEffect } from "react";
import "../Filters/Filters.css";
import Filter from "../../containers/ProductProfile/ProductProfileTable/Filter";
import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping";
import {
  getDefaultFiltersCondition,
  getFilterForClients,
  isCoach,
  shouldHideForCurrentClient,
} from "../../utils/commonUtilities";
import { connect } from "react-redux";
import { createStoreGroupFiltersAction } from "../../containers/StoreGroup/StoreGroupAction";

const hide = shouldHideForCurrentClient("region", "district");

const getReqFromArrayOrString = (p_input) => {
  return Array.isArray(p_input) ? p_input : [p_input];
};

const ClusterFiltersInModal = (props) => {
  const { storeFilterData } = props;

  const [l1_name, setLevel1] = useState(null);
  const [l2_name, setLevel2] = useState(null);
  const [l3_name, setLevel3] = useState(null);
  const [l4_name, setLevel4] = useState(null);
  const [l5_name, setLevel5] = useState(null);
  const [l6_name, setLevel6] = useState(null);
  const [l7_name, setLevel7] = useState(null);

  const [country, setCountry] = useState(null);
  const [age, setAge] = useState(null);
  const [channel, setChannel] = useState(null);
  const [climate, setClimate] = useState(null);
  const [district, setDistrict] = useState(null);
  const [layout, setLayout] = useState(null);
  const [name, setName] = useState(null);
  const [stores, setStore] = useState(null);
  const [region, setRegion] = useState(null);
  const [size, setSize] = useState(null);
  const [request, setRequest] = useState(null);
  const [disable, setDisable] = useState(false);

  let filterOptions = {};
  let filterData = storeFilterData;
  const [lmsStoreAttribute, setLMSAttribute] = useState(null);
  const [lmsStoreValue, setLMSValue] = useState(null);
  // storeFilterData?.data?.length && storeFilterData.data[0]

  for (let i in filterData) {
    filterOptions[i] = filterData[i]?.map((val) => {
      return {
        label: val,
        value: val,
      };
    });
  }

  useEffect(() => {
    if (props.disableAll) {
      !disable && setDisable(true);
      // setLevel1({'label':props.department, 'value':props.department})
    } else {
      disable && setDisable(false);
      // setLevel1(null)
    }
  }, [props.disableAll]);

  useEffect(() => {
    if (!isEmpty(props.filterValues)) {
      for (let i in props.filterValues) {
        let selector = props.filterValues[i];
        switch (i) {
          case "l1_name":
            setChannel(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            setLevel1(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "l2_name":
            setLevel2(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "l3_name":
            setLevel3(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "l4_name":
            setLevel4(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "l5_name":
            setLevel5(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "l6_name":
            setLevel6(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "l7_name":
            setLevel7(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "climate":
            setClimate(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "country":
            setCountry(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "region":
            setRegion(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "district":
            setDistrict(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "size":
            setSize(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "name":
            setName(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
          case "stores":
            setStore(
              selector?.map((val) => {
                return { label: val, value: val };
              })
            );
            break;
        }
      }
    }
  }, [props.filterValues]);

  useEffect(() => {
    if (!isEmpty(props.filterValue)) {
      for (let i in props.filterValue) {
        let selector = props.filterValue[i];
        switch (i) {
          case "l1_name":
            setLevel1(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l1_name: selector[0],
              };
            });
            break;
          case "l2_name":
            setLevel2(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l2_name: selector[0],
              };
            });
            break;
          case "l3_name":
            setLevel3(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l3_name: selector[0],
              };
            });
            break;
          case "l4_name":
            setLevel4(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l4_name: selector[0],
              };
            });
            break;
          case "l5_name":
            setLevel5(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l5_name: selector[0],
              };
            });
            break;
          case "l6_name":
            setLevel6(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l6_name: selector[0],
              };
            });
            break;
          case "l7_name":
            setLevel7(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                l7_name: selector[0],
              };
            });
            break;
          case "climate":
            setClimate(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                climate: selector[0],
              };
            });
            break;
          case "country":
            setCountry(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                country: selector[0],
              };
            });
            break;
          case "region":
            setRegion(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                region: selector[0],
              };
            });
            break;
          case "district":
            setDistrict(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                district: selector[0],
              };
            });
            break;
          case "size":
            setSize(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                size: selector[0],
              };
            });
            break;
          case "name":
            setName(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                name: selector[0],
              };
            });
            break;
          case "stores":
            setStore(selector[0]);
            setRequest((old) => {
              return {
                ...old,
                stores: selector[0],
              };
            });
            break;
        }
      }
    }
  }, [props.filterValue]);

  const resetOtherFilters = (p_index) => {
    // l5_name && setLevel5(null);
    if (p_index === 0) {
      l2_name && setLevel2(null);
      name && setName(null);
      stores && setStore(null);
      country && setCountry(null);
      lmsStoreAttribute && setLMSAttribute(null)
      lmsStoreValue && setLMSValue(null)
    }
    if (p_index === 0 || p_index === 1) {
      l3_name && setLevel3(null);
      country && setCountry(null);
      lmsStoreAttribute && setLMSAttribute(null)
      lmsStoreValue && setLMSValue(null)
    }
    if (p_index === 0 || p_index === 1 || p_index === 2) {
      l4_name && setLevel4(null);
    }
    if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3) {
      l5_name && setLevel5(null);
    }
    if (
      p_index === 0 ||
      p_index === 1 ||
      p_index === 2 ||
      p_index === 3 ||
      p_index === 4
    ) {
      l6_name && setLevel6(null);
    }
    if (
      p_index === 0 ||
      p_index === 1 ||
      p_index === 2 ||
      p_index === 3 ||
      p_index === 4 ||
      p_index === 5
    ) {
      l7_name && setLevel7(null);
    }
    if (
      p_index === 0 ||
      p_index === 1 ||
      p_index === 2 ||
      p_index === 3 ||
      p_index === 4 ||
      p_index === 5 ||
      p_index === 6
    ) {
      climate && setClimate(null);
      region && setRegion(null);
      stores && setStore(null);
      district && setDistrict(null);
      size && setSize(null);
      name && setName(null);
    }
    if (p_index === 6) {
      l3_name && setLevel3(null);
      l4_name && setLevel4(null);
      l5_name && setLevel5(null);
      l6_name && setLevel6(null);
    }
  };

  const onLevel1Change = (value, event, that) => {
    if (Array.isArray(value)) {
      let { l1_name } = filterOptions;
      let valuesArray = handleSelectAll(value, event, that);
      setRequest((old) => {
        return {
          ...old,
          l1_name: valuesArray[0],
        };
      });
      setLevel1(valuesArray[1]);
      resetOtherFilters(0);
      let req_val = intersectionWith(valuesArray[0], l1_name, isEqual);
      !shouldHideForCurrentClient("l1_name", "storeEligibilityGroup") &&
        props.getStoreFiltersApi({ l1_name: req_val });
    } else {
      setLevel1([value]);
      setRequest((old) => {
        return {
          ...old,
          l1_name: [value],
        };
      });
      !shouldHideForCurrentClient("l1_name", "storeEligibilityGroup") &&
        props.getStoreFiltersApi({ l1_name: [value] }, null, {
          persistFilterOption: isCoach(),
          level1Options: filterOptions.l1_name?.map((val) => val.value)
            ? filterOptions.l1_name?.map((val) => val.value)
            : [],
        });
      resetOtherFilters(0);
    }
  };

  const onLevel2Change = (value, event, that) => {
    if (Array.isArray(value)) {
      let { l2_name } = filterOptions;
      let valuesArray = handleSelectAll(value, event, that);
      setRequest((old) => {
        return {
          ...old,
          l2_name: valuesArray[0],
        };
      });
      setLevel2(valuesArray[1]);
      resetOtherFilters(1);
      let req_val = intersectionWith(valuesArray[0], l2_name, isEqual);
      !shouldHideForCurrentClient("l2_name", "storeEligibilityGroup") && props.getStoreFiltersApi({l1_name,channel, l2_name:req_val},null,{persistFilterOption: isCoach() ,level1Options:filterOptions.l1_name?.map(val => val.value) ? filterOptions.l1_name?.map(val => val.value) : [], level2Options:filterOptions.l2_name?.map(val => val.value) ? filterOptions.l2_name?.map(val => val.value) : []});
      // !shouldHideForCurrentClient("l2_name", "storeEligibilityGroup") &&
      //   props.getStoreFiltersApi({ l1_name, channel, l2_name: req_val });
    } else {
      setLevel2([value]);
      setRequest((old) => {
        return {
          ...old,
          l2_name: [value],
        };
      });
     
      // !shouldHideForCurrentClient("l2_name", "storeEligibilityGroup") &&
      //   props.getStoreFiltersApi({ l1_name, l2_name: [value] }, null, {
      //     persistFilterOption: isCoach(),
      //     level2Options: filterOptions.l2_name?.map((val) => val.value)
      //       ? filterOptions.l2_name?.map((val) => val.value)
      //       : [],
      //   });
      resetOtherFilters(1);
    }
  };

  const onLevel3Change = (value, event, that) => {
    if (Array.isArray(value)) {
      let { l3_name } = filterOptions;
      let valuesArray = handleSelectAll(value, event, that);
      setRequest((old) => {
        return {
          ...old,
          l3_name: valuesArray[0],
        };
      });
      setLevel3(valuesArray[1]);
      resetOtherFilters(2);
      if (!shouldHideForCurrentClient("l3_name", "storeEligibilityGroup")) {
        let req_val = intersectionWith(valuesArray[0], l3_name, isEqual);
        let req = { l3_name: req_val };
        if (channel || request?.channel) {
          req.channel = getReqFromArrayOrString(channel || request.channel);
        }
        if (l1_name || request?.l1_name) {
          req.l1_name = getReqFromArrayOrString(l1_name || request.l1_name);
        }
        if (l2_name || request?.l2_name) {
          req.l2_name = getReqFromArrayOrString(l2_name || request.l2_name);
        }
        if (country || request?.country) {
          req.country = getReqFromArrayOrString(country || request.country);
        }
        props.getStoreFiltersApi(req, null, { persistFilterOption: isCoach() , level1Options: filterOptions.l1_name?.map(val => val.value) ? filterOptions.l1_name?.map(val => val.value) : [], level2Options: filterOptions.l2_name?.map(val => val.value) ? filterOptions.l2_name?.map(val => val.value) : [], level3Options: filterOptions.l3_name?.map(val => val.value) ? filterOptions.l3_name?.map(val => val.value) : [] });
      }
    } else {
      setRequest((old) => {
        return {
          ...old,
          l3_name: value,
        };
      });
      setLevel3(value);
      resetOtherFilters(2);
      if (!shouldHideForCurrentClient("l3_name", "storeEligibilityGroup")) {
        let req = { l3_name: [value] };
        if (channel || request?.channel) {
          req.channel = getReqFromArrayOrString(channel || request.channel);
        }
        if (l1_name || request?.l1_name) {
          req.l1_name = getReqFromArrayOrString(l1_name || request.l1_name);
        }
        if (l2_name || request?.l2_name) {
          req.l2_name = getReqFromArrayOrString(l2_name || request.l2_name);
        }
        if (country || request?.country) {
          req.country = getReqFromArrayOrString(country || request.country);
        }
        props.getStoreFiltersApi(req);
      }
    }
  };

  // const onLevel3Change = (value) => {
  //   setRequest(old => {
  //     return {
  //       ...old,
  //       'l3_name': value
  //     }
  //   })
  //   setLevel3(value);
  //   resetOtherFilters(2)
  //   if(!shouldHideForCurrentClient("l3_name", "storeEligibilityGroup")) {
  //     let req = { 'l3_name': [value] }
  //     if(l1_name || request?.l1_name) {
  //       req.l1_name = l1_name || request.l1_name
  //     }
  //     if(l2_name || request?.l2_name) {
  //       req.l2_name = [l2_name || request.l2_name]
  //     }
  //     props.getStoreFiltersApi(req);
  //   }
  // }

  const onLevel4Change = (value) => {
    setRequest((old) => {
      return {
        ...old,
        l4_name: value,
      };
    });
    setLevel4(value);
    resetOtherFilters(3);
    if (!shouldHideForCurrentClient("l4_name", "storeEligibilityGroup")) {
      let req = { l4_name: [value] };
      if (channel || request?.channel) {
        req.channel = getReqFromArrayOrString(channel || request.channel);
      }
      if (l1_name || request?.l1_name) {
        req.l1_name = getReqFromArrayOrString(l1_name || request.l1_name);
      }
      if (l2_name || request?.l2_name) {
        req.l2_name = getReqFromArrayOrString(l2_name || request.l2_name);
      }
      if (l3_name || request?.l3_name) {
        req.l3_name = getReqFromArrayOrString(l3_name || request.l3_name);
      }
      if (country || request?.country) {
        req.country = getReqFromArrayOrString(country || request.country);
      }
      props.getStoreFiltersApi(req);
    }
  };

  const onLevel5Change = (value, event, that) => {
    setRequest((old) => {
      return {
        ...old,
        l5_name: value,
      };
    });
    setLevel5(value);
    resetOtherFilters(4);
    if (!shouldHideForCurrentClient("l5_name", "storeEligibilityGroup")) {
      let req = { l5_name: [value] };
      if (channel || request?.channel) {
        req.channel = getReqFromArrayOrString(channel || request.channel);
      }
      if (l1_name || request?.l1_name) {
        req.l1_name = getReqFromArrayOrString(l1_name || request.l1_name);
      }
      if (l2_name || request?.l2_name) {
        req.l2_name = getReqFromArrayOrString(l2_name || request.l2_name);
      }
      if (l3_name || request?.l3_name) {
        req.l3_name = getReqFromArrayOrString(l3_name || request.l3_name);
      }
      if (l4_name || request?.l4_name) {
        req.l4_name = getReqFromArrayOrString(l4_name || request.l4_name);
      }
      if (country || request?.country) {
        req.country = getReqFromArrayOrString(country || request.country);
      }
      props.getStoreFiltersApi(req);
    }
  };

  const onLevel6Change = (value, event, that) => {
    setRequest((old) => {
      return {
        ...old,
        l6_name: value,
      };
    });
    setLevel6(value);
    resetOtherFilters(5);
    if (!shouldHideForCurrentClient("l6_name", "storeEligibilityGroup")) {
      let req = { l6_name: [value] };
      if (channel || request?.channel) {
        req.channel = getReqFromArrayOrString(channel || request.channel);
      }
      if (l1_name || request?.l1_name) {
        req.l1_name = getReqFromArrayOrString(l1_name || request.l1_name);
      }
      if (l2_name || request?.l2_name) {
        req.l2_name = getReqFromArrayOrString(l2_name || request.l2_name);
      }
      if (l3_name || request?.l3_name) {
        req.l3_name = getReqFromArrayOrString(l3_name || request.l3_name);
      }
      if (l4_name || request?.l4_name) {
        req.l4_name = getReqFromArrayOrString(l4_name || request.l4_name);
      }
      if (l5_name || request?.l5_name) {
        req.l5_name = getReqFromArrayOrString(l5_name || request.l5_name);
      }
      if (country || request?.country) {
        req.country = getReqFromArrayOrString(country || request.country);
      }
      // props.getStoreFiltersApi(req);
    }
  };

  const onLevel7Change = (value, event, that) => {
    setRequest((old) => {
      return {
        ...old,
        l7_name: value,
      };
    });
    setLevel7(value);
    resetOtherFilters();
  };

  const onAgeChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        age: valuesArray[0],
      };
    });
    setAge(valuesArray[1]);
    // let value = intersectionWith(val, filterOptions.age, isEqual);
  };
  const onChannelChange = (value, event, that) => {
    if (Array.isArray(value)) {
      let channel = filterOptions?.channel?.length
        ? filterOptions?.channel
        : filterOptions?.l1_name;

      let valuesArray = handleSelectAll(value, event, that);
      setRequest((old) => {
        return {
          ...old,
          channel: valuesArray[0],
          'l2_name': null,
          'l3_name': null,
          'l4_name': null,
          'l5_name': null,
          'l6_name': null,
          'l7_name': null,
          'climate': null,
          'district': null,
          'size': null,
          'name': null,
          'stores': null,
          // 'channel': null,
        };
      });
      setChannel(valuesArray[1]);
      let req_val = intersectionWith(valuesArray[0], channel, isEqual);

      !shouldHideForCurrentClient("channel", "storeEligibilityGroup") &&
        props.getStoreFiltersApi({ country, channel: req_val });
    } else {
      setChannel([value]);
      setLevel1([value]);
      setRequest((old) => {
        return {
          ...old,
          channel: [value],
          'l2_name': null,
          'l3_name': null,
          'l4_name': null,
          'l5_name': null,
          'l6_name': null,
          'l7_name': null,
          'climate': null,
          'district': null,
          'size': null,
          'name': null,
          'stores': null,
          'channel': null,
        };
      });
      !shouldHideForCurrentClient("channel", "storeEligibilityGroup") && props.getStoreFiltersApi({channel:[value],l1_name:[value]},null,{persistFilterOption: isCoach(), level1Options:filterOptions.l1_name?.map(val => val.value) ? filterOptions.l1_name?.map(val => val.value) : []});
      // !shouldHideForCurrentClient("channel", "storeEligibilityGroup") &&
      //   props.getStoreFiltersApi({ channel: [value] });
      resetOtherFilters(0);
    }

    // let value = intersectionWith(value,event,that, filterOptions.channel, isEqual);
  };
  const onClimateChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        climate: valuesArray[0],
      };
    });
    setClimate(valuesArray[1]);
    // let value = intersectionWith(value,event,that, filterOptions.climate, isEqual);
  };
  const onSizeChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        size: valuesArray[0],
      };
    });
    setSize(valuesArray[1]);
    // let value = intersectionWith(value,event,that, filterOptions.size, isEqual);
  };
  const onRegionChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        region: valuesArray[0],
      };
    });
    setRegion(valuesArray[1]);
    // let value = intersectionWith(value,event,that, filterOptions.region, isEqual);
  };

  const onDistrictChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        district: valuesArray[0],
      };
    });
    setDistrict(valuesArray[1]);
    // let value = intersectionWith(value,event,that, filterOptions.district, isEqual);
  };
  const onNameChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        name: valuesArray[0],
      };
    });
    setName(valuesArray[1]);
    // let value = intersectionWith(value,event,that, filterOptions.name, isEqual);
  };

  const onStoreChange = (value, event, that) => {
    let valuesArray = handleSelectAll(value, event, that);
    setRequest((old) => {
      return {
        ...old,
        stores: valuesArray[0],
      };
    });
    setStore(valuesArray[1]);
    // let value = intersectionWith(value,event,that, filterOptions.name, isEqual);
  };

  const onCountryChange = (value, event, that) => {
    if (Array.isArray(value)) {
      let { country } = filterOptions;
      let valuesArray = handleSelectAll(value, event, that);
      setRequest((old) => {
        return {
          ...old,
          country: valuesArray[0],
        };
      });
      setCountry(valuesArray[1]);
      resetOtherFilters(6);
      let req_val = intersectionWith(valuesArray[0], country, isEqual);
      !shouldHideForCurrentClient("country", "storeEligibilityGroup") &&
        props.getStoreFiltersApi({ channel, l2_name, country: req_val });
    } else {
      setCountry([value]);
      setRequest((old) => {
        return {
          ...old,
          country: [value],
        };
      });
      !shouldHideForCurrentClient("country", "storeEligibilityGroup") &&
        props.getStoreFiltersApi({ channel, l2_name, country: [value] }, null, {
          persistFilterOption: isCoach(),
          level2Options: filterOptions.l2_name?.map((val) => val.value)
            ? filterOptions.l2_name?.map((val) => val.value)
            : [],
        });
      resetOtherFilters(6);
    }
  };

  const onLayoutChange = (val) => {
    let value = intersectionWith(val, filterOptions.layout, isEqual);
    setLayout(value);
  };

  const onLMSAttributeChange = (val, event, that) => {
    setLMSValue(null);
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      setLMSAttribute(valuesArray[1]);
      if (channel && l2_name) {
        props.getStoreFiltersApi({ l1_name: channel, l2_name: l2_name, channel: channel, lms_store_attribute: [valuesArray[0]] }, { key: "lmsStoreValue" })
      }  else {
        Notify.error("Please Select Channel and Department first !!");
        setLMSAttribute(null);
      }
      setRequest(old => {
        return {
          ...old,
          'lmsStoreAttribute': valuesArray[0]
        }
      })
    }
    else {
      setLMSAttribute(val);
      if (channel && l2_name) {
        props.getStoreFiltersApi({ l1_name: channel, l2_name: l2_name, channel: channel, lms_store_attribute: [val] }, { key: "lmsStoreValue" })
      } else {
        Notify.error("Please Select Channel and Department first !!");
        setLMSAttribute(null);
      }
    } 
  }

  const onLMSValueChange = (val, event, that) => {
    if (Array.isArray(val)) {
      let valuesArray = handleSelectAll(val, event, that);
      setLMSValue(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'lmsStoreValue': valuesArray[0]
        }
      })
    }
    else {
      setLMSValue(val);
    }
  }

  const prepReq = (...rest) => {
    let req = {};
    rest.forEach((val) => {
      for (let i in val) {
        if (val[i]) {
          if (Array.isArray(val[i])) {
            if (val[i].length) req[i] = [val[i]];
          } else {
            req[i] = [val[i]];
          }
        }
      }
    });
    return req;
  };

  const applyFilters = () => {
    if (props.storeConfig) {
      let req = prepReq({
        age: request?.age,
        climate: request?.climate,
        district: request?.district,
        name: request?.name,
        layout,
        region: request?.region,
        country: request?.country,
        channel: request?.channel,
      });
      props.getStores(req);
    } else if (
      getDefaultFiltersCondition({
        l1_name: request?.l1_name || l1_name,
        l2_name: request?.l2_name || l2_name,
      })
    ) {
      let req = prepReq({
        l1_name: request?.l1_name || l1_name,
        l2_name: request?.l2_name || l2_name,
        l3_name: request?.l3_name || l3_name,
        l4_name: request?.l4_name || l4_name,
        l5_name: request?.l5_name || l5_name,
        l6_name: request?.l6_name || l6_name,
        l7_name: request?.l7_name || l7_name,
        age: request?.age || age,
        channel: request?.channel || channel,
        climate: request?.climate || climate,
        size: request?.size || size,
        district: request?.district || district,
        name: request?.name || name,
        stores: request?.stores || stores,
        layout,
        region: request?.region || region,
        country: request?.country || country,
        lms_store_attribute: request?.lmsStoreAttribute || lmsStoreAttribute,
        lms_store_value: request?.lmsStoreValue || lmsStoreValue,
      });
      props.getStores(req);
    } else {
      Notify.error("Please Select Mandatory Option!!");
    }
  };

  const resetFilters = () => {
    if (!disable) {
      setLevel1(null);
    }
    setLevel2(null);
    setLevel3(null);
    setLevel4(null);
    setLevel5(null);
    setLevel6(null);
    setLevel7(null);
    props.resetTableData(true);
    setRequest(null);
    setLayout(null);
    setAge(null);
    setClimate(null);
    setRegion(null);
    setDistrict(null);
    setName(null);
    setStore(null);
    setChannel(null);
    setSize(null);
    setCountry(null);
    setLMSAttribute(null);
    setLMSValue(null);
  };

  const getl1Name = (p_level) => {
    let l1Options = [];
    if (p_level) l1Options = p_level;
    return l1Options;
  };

  const prep = [
    getFilterForClients(
      {
        id: 1,
        label: levelFiltersLabelFormatter("level1"),
        name: "l1_name",
        dropdownValue: channel,
        // onDropDownChange: onLevel1Change,
        onDropDownChange: onChannelChange,
        placeHolder: `Select ${levelFiltersLabelFormatter("level1")}`,
        optionsArray: filterOptions.l1_name ? filterOptions.l1_name : [],
        hide: shouldHideForCurrentClient("l1_name", "storeEligibilityGroup"),
        style: { marginBottom: "10px" },
        isStandAlone: false,
        level: "l1_name",
        disableFilters: disable,
      },
      "storeEligibilityGroup"
    ),
    // {
    //   id: 1,
    //   isRequired: true,
    //   label: levelFiltersLabelFormatter("level1"),
    //   name: "l1_name",
    //   dropdownValue: l1_name,
    //   onDropDownChange: onLevel1Change,
    //   placeHolder: `Select ${levelFiltersLabelFormatter("level1")}`,
    //   isDisabled: disable,
    //   optionsArray: filterOptions.l1_name ? filterOptions.l1_name : getl1Name(l1_name),
    //   isMultiSelect: true,
    //   style: {"marginBottom": "1rem"},
    // },
    getFilterForClients(
      {
        id: 10,
        label: levelFiltersLabelFormatter("level2"),
        name: "l2_name",
        dropdownValue: l2_name,
        onDropDownChange: onLevel2Change,
        placeHolder: `Select ${levelFiltersLabelFormatter("level2")}`,
        optionsArray: filterOptions.l2_name ? filterOptions.l2_name : [],
        hide: shouldHideForCurrentClient("l2_name", "storeEligibilityGroup"),
        style: { marginBottom: "10px" },
        isStandAlone: false,
        level: "l2_name",
        disableFilters: disable,
      },
      "storeEligibilityGroup"
    ),
    {
      id: 15,
      label: "Country",
      name: "country",
      dropdownValue: country,
      onDropDownChange: onCountryChange,
      placeHolder: `Select Country`,
      optionsArray: filterOptions.country ? filterOptions.country : [],
      style: { marginBottom: "10px" },
      hide: shouldHideForCurrentClient("country", "storeEligibilityGroup"),
      isMultiSelect: true,
    },
    // {
    //   id: 10,
    //   label: levelFiltersLabelFormatter("level2"),
    //   name: "l2_name",
    //   value: l2_name,
    //   onChange: onLevel2Change,
    //   placeHolder: `Select ${levelFiltersLabelFormatter("level2")}`,
    //   options: filterOptions.l2_name ? filterOptions.l2_name : [],
    //   style: { "marginBottom": "10px" },
    //   hide: shouldHideForCurrentClient("l2_name", "storeEligibilityGroup"),
    // },
    getFilterForClients(
      {
        id: 11,
        label: levelFiltersLabelFormatter("level3"),
        name: "l3_name",
        dropdownValue: l3_name,
        onDropDownChange: onLevel3Change,
        placeHolder: `Select ${levelFiltersLabelFormatter("level3")}`,
        optionsArray: filterOptions.l3_name ? filterOptions.l3_name : [],
        hide: shouldHideForCurrentClient("l3_name", "storeEligibilityGroup"),
        style: { marginBottom: "10px" },
        isStandAlone: false,
        level: "l3_name",
      },
      "storeEligibilityGroup"
    ),
    // {
    //   id: 11,
    //   label: levelFiltersLabelFormatter("level3"),
    //   name: "l3_name",
    //   value: l3_name,
    //   onChange: onLevel3Change,
    //   placeHolder: `Select ${levelFiltersLabelFormatter("level3")}`,
    //   options: filterOptions.l3_name ? filterOptions.l3_name : [],
    //   style: { "marginBottom": "10px" },
    //   hide: shouldHideForCurrentClient("l3_name", "storeEligibilityGroup"),
    // },
    {
      id: 12,
      label: levelFiltersLabelFormatter("level4"),
      name: "l4_name",
      value: l4_name,
      onChange: onLevel4Change,
      placeHolder: `Select ${levelFiltersLabelFormatter("level4")}`,
      options: filterOptions.l4_name ? filterOptions.l4_name : [],
      style: { marginBottom: "10px" },
      hide: shouldHideForCurrentClient("l4_name", "storeEligibilityGroup"),
    },
    {
      id: 13,
      label: levelFiltersLabelFormatter("level5"),
      name: "l5_name",
      value: l5_name,
      onChange: onLevel5Change,
      placeHolder: `Select ${levelFiltersLabelFormatter("level5")}`,
      options: filterOptions.l5_name ? filterOptions.l5_name : [],
      style: { marginBottom: "10px" },
      hide: shouldHideForCurrentClient("l5_name", "storeEligibilityGroup"),
    },
    {
      id: 14,
      label: levelFiltersLabelFormatter("level6"),
      name: "l6_name",
      value: l6_name,
      onChange: onLevel6Change,
      placeHolder: `Select ${levelFiltersLabelFormatter("level6")}`,
      options: filterOptions.l6_name ? filterOptions.l6_name : [],
      style: { marginBottom: "10px" },
      hide: shouldHideForCurrentClient("l6_name", "storeEligibilityGroup"),
    },
    {
      id: 4,
      label: levelFiltersLabelFormatter("climate"),
      name: "climate",
      dropdownValue: climate,
      onDropDownChange: onClimateChange,
      placeHolder: `Select ${levelFiltersLabelFormatter("climate")}`,
      optionsArray: filterOptions.climate ? filterOptions.climate : [],
      isMultiSelect: true,
      // isDisabled: disable,
      style: { marginBottom: "1rem" },
    },
    {
      id: 8,
      label: "Region",
      name: "region",
      dropdownValue: region,
      onDropDownChange: onRegionChange,
      placeHolder: "Select Region",
      optionsArray: filterOptions.region ? filterOptions.region : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("region"),
      // isDisabled: disable,
      style: { marginBottom: "1rem" },
    },
    {
      id: 5,
      label: "District",
      name: "district",
      dropdownValue: district,
      onDropDownChange: onDistrictChange,
      placeHolder: "Select District",
      optionsArray: filterOptions.district ? filterOptions.district : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("district"),
      style: { marginBottom: "1rem" },
      // isDisabled: disable,
    },
    {
      id: 9,
      label: "Size (Sq Ft)",
      name: "size",
      dropdownValue: size,
      onDropDownChange: onSizeChange,
      placeHolder: "Select Size",
      optionsArray: filterOptions.size ? filterOptions.size : [],
      isMultiSelect: true,
      // isDisabled: disable,
    },
    {
      id: 6,
      label: "Store Name",
      name: "name",
      dropdownValue: name,
      onDropDownChange: onNameChange,
      placeHolder: "Select Store Name",
      optionsArray: filterOptions.name ? filterOptions.name : [],
      isMultiSelect: true,
      // isDisabled: disable,
    },
    {
      id: 7,
      label: "Store Number",
      name: "stores",
      dropdownValue: stores,
      onDropDownChange: onStoreChange,
      placeHolder: "Select Store",
      optionsArray: filterOptions.stores ? filterOptions.stores : [],
      isMultiSelect: true,
      // isDisabled: disable,
    },
    {
      id: 16,
      isRequired: false,
      label: "LMS Store Attribute",
      name: "lmsStoreAttribute",
      value: lmsStoreAttribute,
      onChange: onLMSAttributeChange,
      placeHolder: `Select LMS Store Attribute`,
      options: filterOptions.lms_store_attribute ? filterOptions.lms_store_attribute : [],
      style: { "marginTop": "10px" },
    },
    {
      id: 17,
      isRequired: false,
      label: "LMS Store Value",
      name: "lmsStoreValue",
      dropdownValue: lmsStoreValue,
      onDropDownChange: onLMSValueChange,
      placeHolder: `Select LMS Store Value`,
      optionsArray: filterOptions.lms_store_value ? filterOptions.lms_store_value : [],
      isMultiSelect: true,
      style: { "marginTop": "10px" },
    },
  ];

  const storeConfigPrep = [
    {
      id: 2,
      label: "Country",
      name: "country",
      dropdownValue: country,
      onDropDownChange: onCountryChange,
      placeHolder: `Select Country`,
      optionsArray: filterOptions.country ? filterOptions.country : [],
      isMultiSelect: true,
      style: { marginBottom: "1rem" },
    },
    {
      id: 3,
      label: "Channel",
      name: "channel",
      dropdownValue: channel,
      onDropDownChange: onChannelChange,
      placeHolder: `Select Channel`,
      optionsArray: filterOptions.channel ? filterOptions.channel : [],
      isMultiSelect: true,
      style: { marginBottom: "1rem" },
    },
    // {
    //   id: 1,
    //   label: levelFiltersLabelFormatter("level1"),
    //   name: "l1_name",
    //   dropdownValue: l1_name,
    //   onDropDownChange: onChannelChange,
    //   placeHolder: `Select ${levelFiltersLabelFormatter("level1")}`,
    //   optionsArray: filterOptions.channel ? filterOptions.channel : [],
    //   style: { "marginBottom": "10px" },
    //   isStandAlone: false,
    //   level: "l1_name",
    //   disableFilters: disable,
    // },
    {
      id: 4,
      label: levelFiltersLabelFormatter("climate"),
      name: "climate",
      dropdownValue: climate,
      onDropDownChange: onClimateChange,
      placeHolder: `Select ${levelFiltersLabelFormatter("climate")}`,
      optionsArray: filterOptions.climate ? filterOptions.climate : [],
      isMultiSelect: true,
      style: { marginBottom: "1rem" },
    },
    {
      id: 8,
      label: "Region",
      name: "region",
      dropdownValue: region,
      onDropDownChange: onRegionChange,
      placeHolder: "Select Region",
      optionsArray: filterOptions.region ? filterOptions.region : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("region"),
      style: { marginBottom: "1rem" },
    },
    {
      id: 5,
      label: "District",
      name: "district",
      dropdownValue: district,
      onDropDownChange: onDistrictChange,
      placeHolder: "Select District",
      optionsArray: filterOptions.district ? filterOptions.district : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("district"),
      style: { marginBottom: "1rem" },
    },
    {
      id: 6,
      label: "Store Name",
      name: "name",
      dropdownValue: name,
      onDropDownChange: onNameChange,
      placeHolder: "Select Store Name",
      optionsArray: filterOptions.name ? filterOptions.name : [],
      isMultiSelect: true,
    },
  ];

  return (
    <>
      {/* <Notification /> */}
      <Filter data={props.storeConfig ? storeConfigPrep : prep} />
      <div className="col-md-3 mt-3">
        <button
          onClick={applyFilters}
          className="btn btn-primary filter-constraints-mode"
          title="Apply filters"
          // disabled={disable}
        >
          <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
        </button>
        {!disable && (
          <button
            onClick={() => resetFilters("All")}
            className="btn undo-constraints-mode"
            title="Reset filters"
            // disabled={disable}
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        )}
      </div>
      {!props.storeConfig && (
        <div className="row section">
          <div className="required fnt-md pl-4" style={{ color: "red" }}>
            <label>Fields are mandatory</label>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStoreFiltersApi: (payload, isEdit, levelOptions) =>
    dispatch(createStoreGroupFiltersAction(payload, isEdit, levelOptions)),
});

export default connect(null, mapDispatchToProps)(ClusterFiltersInModal);
