import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import { rowsCount } from "../Constraints/Constraints"
import {
  FETCH_FILTER_DATA,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
  FETCH_STORE_GROUP_DATA,
  fetchStoreGroupDataSuccess,
  fetchStoreGroupDataError,
  FETCH_STORE_GRADE_DATA,
  fetchStoreGradeDataSuccess,
  fetchStoreGradeDataError,
  FETCH_STORE_DATA,
  fetchStoreDataSuccess,
  fetchStoreDataError,
  FETCH_CONSTRAINTS_POPUP,
  fetchPopUpDataSuccess,
  fetchPopUpDataError,
  UPDATE_TABLEDATA,
  updateTableDataSuccess,
  updateTableDataError,
  FETCH_MIN_PER_STORE_ERROR,
  FETCH_MIN_PER_STORE,
  fetchMinPerStoreSuccess,
  fetchMinPerStoreError,
  fetchDefaultConstFilterDataSuccess,
  fetchDefaultConstFilterDataError,
  FETCH_DEFAULT_CONS_FILTER_DATA,
  GET_CHECK_ALL_DATA_SUCCESS,
  fetchDefaultConstraintsDataSuccess,
  fetchDefaultConstraintsError,
  FETCH_DEFAULT_CONS_DATA,
  FETCH_DEFAULT_CONS_DATA_SUCCESS,
  UPDATE_DEFAULT_DATA,
  updateDefaultDataSuccess,
  updateDefaultDataError,
  GENERATE_EXCEL,
  GENERATE_EXCEL_SUCCESS,
  GENERATE_EXCEL_ERROR,
} from "./ConstraintsAction";

import {
  getConstraintsFilterData,
  getConstraintsTableData,
  getDefaultConstraintsFilterData,
  getDefaultConstraintsTableData,
  getPopUpData,
  updateDefaultDataData,
  updateTableData,
} from "../../routes/api";

import { cloneDeep, filter, isEmpty } from "lodash";
import moment from "moment";
import { flattenArray } from "../../utils/commonUtilities";
import { ERROR_MSG } from "../../constants/globalConstant";


let numOr0 = n => isNaN(n) ? 0 : n

function* fetchStoreGroupDataWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  let req_body = {}
  if(action.payload?.screen_type) {
    req_body = action.payload
  } 
  else{
    for (var key in l_payload) {
      if(Array.isArray(l_payload[key])){
        arrayProperty[key] = l_payload[key].map((ele) => ele.value)
      }
    }
       req_body = {
         "screen_type":"store_group_code",
         "l1_name":[l_payload?.department?.value],
        //  "level2":[l_payload?.gender?.value],
        //   "level3": [l_payload?.rbu?.value],
          // "Attributes":{
          //     "level1":[l_payload?.department?.value],
          //     "level2":[l_payload?.gender?.value],
          //     "level3":[l_payload?.rbu?.value],
          // },
          "Operators":{
              "NonAttributeColumn":["level1","level2","level3"]
          },
          "rowCount": action.payload.rowCount,
          "rowIndex": action.payload.rowIndex,
          "styleIndex": action.payload.styleIndex,
      }
  
      if(l_payload?.gender?.[0]?.value){
        req_body["l2_name"]= arrayProperty["gender"]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level2"]
      }
      if(l_payload?.rbu?.[0]?.value){
        req_body["l3_name"]= arrayProperty["rbu"]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level3"]
      }
  
      if(l_payload?.dcs?.[0]?.value){
        req_body["l4_name"]= arrayProperty["dcs"]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level4"]
      }

      if(l_payload?.level5?.[0]?.value){
        req_body["l5_name"]= arrayProperty["level5"]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
      }

      if(l_payload?.level6?.[0]?.value){
        req_body["l6_name"]= arrayProperty["level6"]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level6"]
      }

      if(l_payload?.level7?.[0]?.value){
        req_body["l7_name"]= arrayProperty["level7"]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level7"]
      }
  
      if(arrayProperty['colour']?.length > 0) {
        req_body["color"] = arrayProperty['colour']
      }
      if(arrayProperty['size']?.length > 0) {
        req_body["size"] = arrayProperty['size']
      }
      if(arrayProperty['store_grade']?.length > 0) {
        req_body["store_grade"] = arrayProperty['store_grade']
      }
      if(arrayProperty['style_name']?.length > 0) {
        req_body["style_name"] = arrayProperty['style_name']
        // req_body["style_name"] = arrayProperty['style_name']
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
      }
      if(arrayProperty['country']?.length > 0) {
        req_body["country"] = arrayProperty['country']
      }
      if(arrayProperty['assortment_indicator']?.length > 0) {
        req_body["assortment_indicator"] = arrayProperty['assortment_indicator']
      }
      if(l_payload.lms_store_attribute?.value) {
        req_body["lms_store_attribute"] = [l_payload.lms_store_attribute?.value]
      }
      if(arrayProperty["lms_store_value"]?.length > 0) {
        req_body["lms_store_value"] = arrayProperty["lms_store_value"]
      }
  }

  try {
    const response = yield call(getConstraintsTableData,req_body)
    if(response.status) {
      if(response?.data?.data?.length) {
    const func = (p_subRows=[]) => {
       return p_subRows?.map(val => {
         if(val.min_store){
          return {
            ...val,
            req: req_body,
            batchID: action.payload.pageIndex,
            min_store: Math.round(val?.min_store),
            min_store_sum: Math.round(val?.min_store_sum),
            max_store: Math.round(val?.max_store || 0),
            max_store_sum: Math.round(val?.max_store_sum || 0)
            // min_store: val?.min_store?.reduce((a, b) => 
            // (parseInt(numOr0(a)) + parseInt(numOr0(b))))
          }
         }
         return {
           ...val,
           req: req_body,
           batchID: action.payload.pageIndex
          }
        })
    }

    const responseWithAggregatedMin = response?.data?.data?.map(val => {
      return{
        ...val,
        batchID: action.payload.pageIndex,
        min_store: Math.round(val?.min_store),
        min_store_sum: Math.round(val?.min_store_sum),
        max_store: Math.round(val?.max_store),
        max_store_sum: Math.round(val?.max_store_sum),
        req: req_body,
        // min_store: val?.min_store?.reduce((a, b) => 
        // (parseInt(numOr0(a)) + parseInt(numOr0(b)))),
        ['subRows']:func(val.subRows)
      }
    })

    const { dcs } = responseWithAggregatedMin[0]

    responseWithAggregatedMin.map(item => {
      dcs?.forEach(dcName => {
        item[dcName] = item.dcs_time?.[dcName]
      })
    })

    const resp = {
      data: responseWithAggregatedMin,
      // data: response?.data?.data,
      status: {...response.status},
      error: {...response.error}
    }
      // console.log()
      yield put(fetchStoreGroupDataSuccess({ data: resp.data, styleIndex: response?.data?.styleIndex, count: response?.data?.count,out_of_data: response?.data?.out_of_data, index:Number(response?.data?.row_index) + rowsCount, "mapping" : {[action.payload.pageIndex]:{...req_body}} }));
    }
    else {
      yield put(fetchStoreGroupDataSuccess({ data: [], out_of_data: response?.data?.out_of_data, styleIndex: 0, count: 0, index:0, "mapping" : {[action.payload.pageIndex]:{...req_body}} }));
    }
  }
    else{
      yield put(fetchStoreGroupDataError({ error: response.error }));
    }

  } catch (error) {
      yield put(fetchStoreGroupDataError(error));
  }
    
}

function* fetchStoreGradeDataWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = flattenArray(l_payload[key])?.map((ele) => ele.value)
    }
  }
  let req_body = {
    "screen_type": "store_grade",
    "l1_name": [l_payload?.department?.value],
    //  "level2":[l_payload?.gender?.value],
    //  "level3": [l_payload?.rbu?.value],
    // "Attributes":{
    //     "level1":[l_payload?.department?.value],
    //     "level2":[l_payload?.gender?.value],
    //     "level3":[l_payload?.rbu?.value],
    // },
    "Operators": {
      "NonAttributeColumn": ["level1", "level2", "level3"]
    },
    "rowCount": action.payload.rowCount,
    "rowIndex": action.payload.rowIndex,
    "styleIndex": action.payload.styleIndex,
  }

  if (l_payload?.gender?.[0]?.value) {
    // req_body["l2_name"] = [l_payload?.gender?.value]
    req_body["l2_name"] = arrayProperty["gender"]
    req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"], "level2"]
  }
  if (l_payload?.rbu?.[0]?.value) {
    // req_body["l3_name"] = [l_payload?.rbu?.value]
    req_body["l3_name"] = arrayProperty["rbu"]
    req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"], "level3"]
  }

  if (l_payload?.dcs?.[0]?.value) {
    // req_body["l4_name"] = [l_payload?.dcs?.value]
    req_body["l4_name"] = arrayProperty["dcs"]
    req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"], "level4"]
  }

    if(l_payload?.level5?.[0]?.value){
      // req_body["l5_name"]= [l_payload?.level5?.value]
      req_body["l5_name"] = arrayProperty["level5"]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
    }

    if(l_payload?.level6?.[0]?.value){
      // req_body["l6_name"]= [l_payload?.level6?.value]
      req_body["l6_name"] = arrayProperty["level6"]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level6"]
    }

    if(l_payload?.level7?.[0]?.value){
      // req_body["l7_name"]= [l_payload?.level7?.value]
      req_body["l7_name"] = arrayProperty["l7_name"]
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level7"]
    }

    if(arrayProperty['colour']?.length > 0) {
      req_body["color"] = arrayProperty['colour']
    }
    if(arrayProperty['size']?.length > 0) {
      req_body["size"] = arrayProperty['size']
    }
    if(arrayProperty['store_grade']?.length > 0) {
      req_body["store_grade"] = arrayProperty['store_grade']
    }
    if(arrayProperty['style_name']?.length > 0) {
      // req_body["style_name"] = arrayProperty['style_name']
      req_body["style_name"] = arrayProperty['style_name']
      req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    }
    if(arrayProperty['country']?.length > 0) {
      req_body["country"] = arrayProperty['country']
    }
    if(arrayProperty['assortment_indicator']?.length > 0) {
      req_body["assortment_indicator"] = arrayProperty['assortment_indicator']
    }
    if(l_payload.lms_store_attribute?.value) {
      req_body["lms_store_attribute"] = [l_payload.lms_store_attribute?.value]
    }
    if(arrayProperty["lms_store_value"]?.length > 0) {
      req_body["lms_store_value"] = arrayProperty["lms_store_value"]
    }

  try {
    const response = yield call(getConstraintsTableData,req_body)
    if(response.status) {
      if(response?.data?.data?.length) {
    const func = (p_subRows) => {
       return p_subRows.map(val => {
         if(val.min_store){
          return {
            ...val,
            min_store: Math.round(val?.min_store),
            min_store_sum: Math.round(val?.min_store_sum),
            max_store: Math.round(val?.max_store || 0),
            max_store_sum: Math.round(val?.max_store_sum || 0),
            // (parseInt(numOr0(a)) + parseInt(numOr0(b))))
          }
         }
         return val
        })
    }

    const responseWithAggregatedMin = response?.data?.data?.map(val => {
      return{
        ...val,
        min_store: Math.round(val?.min_store),
        min_store_sum: Math.round(val?.min_store_sum), 
        max_store: Math.round(val?.max_store),
        max_store_sum: Math.round(val?.max_store_sum),
        // (parseInt(numOr0(a)) + parseInt(numOr0(b)))),
        ['subRows']:func(val.subRows)
      }
    })

    const { dcs } = responseWithAggregatedMin[0]

    responseWithAggregatedMin.map(item => {
      dcs?.forEach(dcName => {
        item[dcName] = item.dcs_time?.[dcName]
      })
    })

    const resp = {
      data: responseWithAggregatedMin,
      // data: response?.data?.data,
      status: {...response.status},
      error: {...response.error}
    }

      yield put(fetchStoreGradeDataSuccess({ data: resp.data, styleIndex: response?.data?.styleIndex,out_of_data: response?.data?.out_of_data, count: response?.data?.count, index:Number(response?.data?.row_index) + rowsCount}));
    }
    else {
      yield put(fetchStoreGradeDataSuccess({ data: [],out_of_data: response?.data?.out_of_data, styleIndex: 0, count: 0, index:0}));
    }
  }
    else{
      yield put(fetchStoreGradeDataError({ error: response.error }));
    }

  } catch (error) {
      yield put(fetchStoreGradeDataError(error));
  }
}

function* fetchStoreDataWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
  let req_body = {
    // "l1_name": [l_payload?.department?.value],
    // "Operators": {
      // "NonAttributeColumn": ["level1", "level2", "level3"]
    // },
    "rowCount": action.payload.rowCount,
    "rowIndex": action.payload.rowIndex,
    "styleIndex": action.payload.styleIndex,
  }
  // if (l_payload?.gender?.value) {
  //   req_body["l2_name"] = [l_payload?.gender?.value]
  //   // req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"], "level2"]
  // }
  // if (l_payload?.rbu?.value) {
  //   req_body["l3_name"] = [l_payload?.rbu?.value]
  //   // req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"], "level3"]
  // }

  // if (l_payload?.dcs?.value) {
  //   req_body["l4_name"] = [l_payload?.dcs?.value]
  //   // req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"], "level4"]
  // }

  //   if(l_payload?.level5?.value){
  //     req_body["l5_name"]= [l_payload?.level5?.value]
  //     // req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
  //   }

  //   if(l_payload?.level6?.value){
  //     req_body["l6_name"]= [l_payload?.level6?.value]
  //   }

  //   if(l_payload?.level7?.value){
  //     req_body["l7_name"]= [l_payload?.level7?.value]
  //   }

    if(arrayProperty['colour']?.length > 0) {
      req_body["color"] = arrayProperty['colour']
    }
    if(arrayProperty['size']?.length > 0) {
      req_body["size"] = arrayProperty['size']
    }
    if(arrayProperty['store_grade']?.length > 0) {
      req_body["store_grade"] = arrayProperty['store_grade']
    }
    if(arrayProperty['style_name']?.length > 0) {
      // req_body["style_name"] = arrayProperty['style_name']
      req_body["style_name"] = arrayProperty['style_name']
      // req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    }
    if(arrayProperty['assortment_indicator']?.length > 0) {
      // req_body["assortment_indicator"] = arrayProperty['assortment_indicator']
      req_body["assortment_indicator"] = arrayProperty['assortment_indicator']
      // req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    }
    if(arrayProperty['country']?.length > 0) {
      req_body["country"] = arrayProperty['country']
    }
    if(!isEmpty(action?.payload?.searchTermReq)) {
      req_body["searchColumns"] = {...action?.payload?.searchTermReq}
    }
    if(!isEmpty(action?.payload?.sortReq)) {
      req_body["sortColumn"] = {...action?.payload?.sortReq[0]}
    }
    let levelFilters = [{key: "gender", value: "l2_name"}, {key: "rbu", value: "l3_name"}, {key: "dcs", value: "l4_name"}, 
    {key: "level5", value: "l5_name"}, {key: "level6", value: "l6_name"}]
    req_body["l1_name"] = [l_payload?.department?.value]
    levelFilters.forEach(filter => {
      if(l_payload[filter.key]?.length) {
        Array.isArray(l_payload[filter.key]) && (req_body[filter.value] = flattenArray(l_payload[filter.key])?.map((ele) => ele?.value))//payload[key]?.map((ele) => ele && ele.value)
      }
    })

    if(l_payload.lms_store_attribute?.value) {
      req_body["lms_store_attribute"] = [l_payload.lms_store_attribute?.value]
    }
    if(arrayProperty["lms_store_value"]?.length > 0) {
      req_body["lms_store_value"] = arrayProperty["lms_store_value"]
    }

  try {
    const response = yield call(getConstraintsTableData,req_body)
    if (response.status) {

    if(response?.data?.data?.length) {
    const dataWithUniqueKey = response?.data?.data?.map(val => {
      return {
        ...val,
        updated_at: val.updated_at ? moment(val.updated_at).format("MM-DD-YYYY HH:mm:ss") : "",
        'unique' : val.style_id+val.store_code
      }
    })

    const { dcs } = dataWithUniqueKey[0]

    dataWithUniqueKey.map(item => {
      dcs?.forEach(dcName => {
        item[dcName] = item.dcs_time?.[dcName]
      })
    })
    
    let resp = {
      data: dataWithUniqueKey,
      status: {...response.status},
      error: {...response.error}
    }

      yield put(fetchStoreDataSuccess({ data: resp.data, out_of_data: response?.data?.out_of_data, styleIndex: response?.data?.styleIndex,count: response?.data?.count, index: Number(response?.data?.row_index) + rowsCount, req:req_body }));
  }
    else{
      yield put(fetchStoreDataSuccess({ data: [], out_of_data: response?.data?.out_of_data, styleIndex: 0,count: 0, index: 0, req:req_body }));
    }
    }
    else {
      yield put(fetchStoreDataError({ error: response.error }));
    }

  } catch (error) {
      yield put(fetchStoreDataError(error));
  }
}

function* fetchFilterDataWorker(action) {
  const level_ln_map = {
    "level1": "l1_name",
    "level2": "l2_name",
    "level3": "l3_name",
    "level4": "l4_name",
    "level5": "l5_name",
    "level6":"l6_name",
    "level7":"l7_name",
    "lms_store_attribute":"lms_store_attribute",
    "lms_store_value":"lms_store_value",
  }
  try {
    const { payload,filterType } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if (!isEmpty(payload?.[key]?.[0])) {
          payload[key] && (req[level_ln_map[key]] = flattenArray(payload[key])?.map((ele) => ele.value))
        }
      }
    }
    if(filterType){
        req["filter_type"] = filterType;
    }
    const res = yield call(getConstraintsFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      // let topObject = Object.keys(res.data.data[0])[0]
      for (const key in res.data.data[0]) {
        let k = "";
        if (key === "l1_name") {
          k = "departmentOptions";
        } else if (key === "l2_name") {
          k = "genderOptions";
        } else if (key === "l3_name") {
          k = "rbuOptions";
        } else if (key === "l4_name") {
          k = "dcsOptions";
        } else if (key === "l5_name") {
          k = "level5Options";
        }else if (key === "l6_name") {
          k = "level6Options";
        }else if (key === "l7_name") {
          k = "level7Options";
        } else if(key === "style_name") {
          k = "styleOptions";
        } else if(key === "color") {
            k = "colourOptions";
        } else if(key === "size") {
            k = "sizeOptions";
        } else if(key === "country") {
            k = "countryOptions"
        } else if(key === "store_grade") {
            k = "storeGradeOptions" 
        } else if(key === "assortment_indicator") {
          k = "assortIndOptions"
        }else if (key === "lms_store_attribute") {
          k = "lmsStoreAttributeOptions";
        }else if (key === "lms_store_value") {
          k = "lmsStoreValueOptions";
        }

        // if(res?.data?.sentFilters?.filter_type == "store_grade"){
        //   data["storeGradeOptions"] = res.data.data.map((element) => ({
        //     value: element,
        //     label: element,
        //   }));
        // }
        // else{
          // if(res?.data?.sentFilters?.filter_type == "style_name"){
          //   data["styleOptions"] = res.data?.data
          // }
          // else{
            // if(key==="style_name" || key==="color" || key==="size") {
            if(false) {
                data[k] = res.data.data.map((element) => ({
                    value: element[key],
                    label: element[key],
                  }));
            }
            else{
                data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
                    value: element,
                    label: element,
                  }));
            }
          // }
        // }
      } 
      yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key   }));
    }
  } catch (error) {
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}
// TODO: Add colour and size in req body if in case of duplicates

function* fetchPopUpDataWorker(action) {
  let l_payload = {...action.payload?.rowData}
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
    let req_body = {
       "screen_type": action?.payload?.screen_type,
       "l1_name":[l_payload?.l1_name],
       "l2_name":[l_payload?.l2_name],
       "l3_name": l_payload?.l3_name && [l_payload?.l3_name],
       "l4_name": l_payload?.l4_name && [l_payload?.l4_name],
       "l5_name": l_payload?.l5_name && [l_payload?.l5_name],
       "l6_name": l_payload?.l6_name && [l_payload?.l6_name],
      //  "l7_name": [l_payload?.l7_name],
       "store_group_code": l_payload?.store_group_code,
       "store_grade": l_payload?.store_grade,
       "style": [l_payload?.style_id],
        "Attributes":{
            "level1":[l_payload?.l1_name],
            "level2":[l_payload?.l2_name],
            "level3":[l_payload?.l3_name],
            "level4": [l_payload?.l4_name],
            "level5": [l_payload?.l5_name],
            "style_name":[l_payload?.style_id]
        },
        "Operators":{
          "NonAttributeColumn":["level1","level2","level3","style_name"]
        }
    }

    // if(action.payload?.filters?.['store_grade']?.length > 0) {
    //   req_body["store_grade"] = action.payload?.filters?.['store_grade']?.map(ele => ele.value)
    // }

    // if(arrayProperty['colour']?.length > 0) {
    //   req_body["Attributes"]["color_description"] = arrayProperty['colour']
    // }
    // if(arrayProperty['size']?.length > 0) {
    //   req_body["Attributes"]["size"] = arrayProperty['size']
    // }
    // if(arrayProperty['style_name']?.length > 0) {
    //   req_body["style_name"] = arrayProperty['style_name']
    //   req_body["Attributes"]["style_name"] = arrayProperty['style_name']
    //   req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
    // }

  try {
    if(req_body["screen_type"] === "store_group") {
      delete req_body["store_grade"]
    }
    const response = yield call(getPopUpData,req_body)
    let refStores = response?.data?.data?.map(val => {
      return{
        value: val?.store_code,
        label: val?.store_name
      }
  })
  let responseWithRefStores = response?.data?.data?.map(val => {
    return {
      ...val,
      min_store: Math.round(val?.min_store),
      max_store: Math.round(val?.max_store),
      transit_time_sum: Math.round(val?.transit_time_sum),
      wos: Math.round(val?.wos),
      ['ref_store']: refStores,
      referStore: val.store_code
    }
  })

  const { dcs } = responseWithRefStores[0]

  responseWithRefStores.map(item => {
    dcs.forEach(dcName => {
      item[dcName] = item.dcs_time?.[dcName]
    })
  })
  
  let resp = {
    data: responseWithRefStores,
    status: {...response.status},
    error: {...response.error}
  }
    if (response.status) {
      yield put(fetchPopUpDataSuccess({ data: resp.data }));
    }
    else{
      yield put(fetchPopUpDataError({ error: resp.error }));
    }

  } catch (error) {
      yield put(fetchPopUpDataError(error));
  }
}

function* updateTableDataWorker(action) {
  let dataWithSubRows = []
  let req_body = {}
  let data = []
  let screen_type = action?.payload?.screen_type

  if(screen_type == "set_all") {
    req_body = action?.payload
  }
  else{
    dataWithSubRows = action?.payload?.data
    data = dataWithSubRows.map(({subRows,uniqueKey,l1_name,l2_name,l3_name,l4_name,color,color_desc,size_desc,style_name,store_name,store_grade,min_stock,max_stock,transit_time,dc_store_1,dc_store_2,dc_store_3,dc_store_4,dc_store_5,dc_store_6,...keepAttrs}) => keepAttrs)
    req_body = {
      data,
      screen_type
    }
  }
  try {
    const response = yield call(updateTableData,req_body)
    if (response.status) {
      yield put(updateTableDataSuccess({ data: response.data }));
    }
    else{
      yield put(updateTableDataError({ error: response.error, screen_type }));
    }

  } catch (error) {
      yield put(updateTableDataError(error, screen_type ));
  }
}

function* fetchMinPerStoreWorker(action) {
  try {
    let l_payload = action.payload
    let arrayProperty = {};
    for (var key in l_payload) {
      if(Array.isArray(l_payload[key])){
        arrayProperty[key] = l_payload[key].map((ele) => ele.value)
      }
    }
      let req_body = {
        "screen_type":"min_store",
         "level1":[l_payload?.department?.value],
         "level2":[l_payload?.gender?.value],
          "level3": [l_payload?.rbu?.value],
          "Attributes":{
              "level1":[l_payload?.department?.value],
              "level2":[l_payload?.gender?.value],
              "level3":[l_payload?.rbu?.value],
          },
          "Operators":{
              "NonAttributeColumn":["level1","level2","level3"]
          }
      }

      if(l_payload?.dcs?.value){
        req_body["level4"]= [l_payload?.dcs?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level4"]
      }

      if(l_payload?.level5?.value){
        req_body["level5"]= [l_payload?.level5?.value]
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],"level5"]
      }
  
      if(arrayProperty['colour']?.length > 0) {
        req_body["color"] = arrayProperty['colour']
        req_body["Attributes"]["color_description"] = arrayProperty['colour']
      }
      if(arrayProperty['size']?.length > 0) {
        req_body["size"] = arrayProperty['size']
        req_body["Attributes"]["size"] = arrayProperty['size']
      }
      if(arrayProperty['store_grade']?.length > 0) {
        req_body["store_grade"] = arrayProperty['store_grade']
      }
      if(arrayProperty['style_name']?.length > 0) {
        req_body["style_name"] = arrayProperty['style_name']
        req_body["Attributes"]["style_name"] = arrayProperty['style_name']
        req_body["Operators"]["NonAttributeColumn"] = [...req_body["Operators"]["NonAttributeColumn"],'style_name']
      }

    const res = yield call(getConstraintsTableData, req_body);
    if (res.data.status) {
      yield put(fetchMinPerStoreSuccess({ data: Math.round(res?.data?.data[0].min_store) }));
    }
  } catch (error) {
    yield put(fetchMinPerStoreError({ error: ERROR_MSG }));
  }
}

function* fetchDefaultConstraintsWorker(action) {
  let l_payload = action.payload.filters
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
  let req_body = {
    "row_count": action.payload.rowCount,
    "row_index": action.payload.rowIndex,
    "style_index": action.payload.styleIndex,
  }

    if(arrayProperty['size']?.length > 0) {
      req_body["size"] = arrayProperty['size']
    }
    if(arrayProperty['store_code']?.length > 0) {
      req_body["store_code"] = arrayProperty['store_code']
    }
   
    if(arrayProperty['assortment_group']?.length > 0) {
      req_body["assortment_group"] = arrayProperty['assortment_group']
    }
    if(!isEmpty(action?.payload?.searchTermReq)) {
      req_body["searchColumns"] = {...action?.payload?.searchTermReq}
    }
    if(!isEmpty(action?.payload?.sortReq)) {
      req_body["sortColumn"] = {...action?.payload?.sortReq[0]}
    }
    let levelFilters = [{key: "department", value: "l2_name"}, {key: "level3", value: "l3_name"}]
    req_body["l1_name"] = [l_payload?.channel?.value]
    levelFilters.forEach(filter => {
      if(l_payload[filter.key]?.length) {
        Array.isArray(l_payload[filter.key]) && (req_body[filter.value] = flattenArray(l_payload[filter.key])?.map((ele) => ele?.value))//payload[key]?.map((ele) => ele && ele.value)
      }
    })
  try {
    const response = yield call(getDefaultConstraintsTableData,req_body)
    if (response.status) {
    let resp = {
      data: response?.data?.data,
      status: {...response.status},
      error: {...response.error}
    }
    resp.data.forEach(item => {
      item.updated_at = item.updated_at && moment(item.updated_at).format("MM-DD-YYYY hh:mm:ss")
  });
    let type = action.payload?.checkAll ? GET_CHECK_ALL_DATA_SUCCESS : FETCH_DEFAULT_CONS_DATA_SUCCESS
      yield put({ type, data: resp.data, totalCount: response.data.count, nextIndex: response.data.row_index, req:req_body });
    }
    else {
      yield put(fetchDefaultConstraintsError({ error: response.error }));
    }

  } catch (error) {
      yield put(fetchDefaultConstraintsError(error));
  }
}

function* fetchDefaultConstraintsFilterDataWorker(action) {
  const level_ln_map = {
    "level1": "l1_name",
    "level2": "l2_name",
    "level3": "l3_name",
  }
  try {
    const { payload,filterType } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if (!isEmpty(payload?.[key]?.[0])) {
          payload[key] && (req[level_ln_map[key]] = flattenArray(payload[key])?.map((ele) => ele.value))
        }
      }
    }
    
    if(filterType){
        req["filter_type"] = filterType;
    }
    const res = yield call(getDefaultConstraintsFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      for (const key in res.data.data[0]) {
        let k = "";
        if (key === "l1_name") {
          k = "channelOptions";
        } else if (key === "l2_name") {
          k = "departmentOptions";
        } else if (key === "l3_name") {
          k = "level3Options";
        } else if(key === "size") {
            k = "sizeOptions";
        } else if(key === "store_code") {
            k = "storeOptions" 
        } else if(key === "assortment_group") {
          k = "assortIndOptions"
        }
            
        data[k] = res.data.data[0][key]?.filter(val => val).map((element) => ({
            value: element,
            label: element,
        }));
      } 
      yield put(fetchDefaultConstFilterDataSuccess({ data: data, key: action?.filterKey?.key   }));
    }
  } catch (error) {
    yield put(fetchDefaultConstFilterDataError({ error: ERROR_MSG }));
  }
}

function* updateDefaultDataWorker(action) {
  let dataWithSubRows = []
  let data = []
  let req_body = {}
  let screen_type = action?.payload?.screen_type

  if(screen_type == "set_all") {
    req_body = action?.payload   
  }
  else{
    dataWithSubRows = action?.payload?.data
    data = dataWithSubRows.map(({l1_name, l2_name, l3_name, assortment_group, size, store_code, updated_at,...keepAttrs}) => keepAttrs)
  
    req_body = {
      data,
      screen_type
    }
  }
  const { row_count, row_index, style_index, ...updatedReq } = req_body;
  try {
    const response = yield call(updateDefaultDataData, updatedReq)
    if (response.status) {
      yield put(updateDefaultDataSuccess({ data: response.data }));
    }
    else{
      yield put(updateDefaultDataError({ error: response.error, screen_type }));
    }

  } catch (error) {
      yield put(updateDefaultDataError(error, screen_type ));
  }
}

function* generateExcelWorker(action) {
  let l_payload = action.payload
  let arrayProperty = {};
  for (var key in l_payload) {
    if(Array.isArray(l_payload[key])){
      arrayProperty[key] = l_payload[key].map((ele) => ele.value)
    }
  }
  let req_body = {
    "row_count": action.payload.row_count,
    "row_index": action.payload.row_index,
  }

    if(arrayProperty['size']?.length > 0) {
      req_body["size"] = arrayProperty['size']
    }
    if(arrayProperty['store_code']?.length > 0) {
      req_body["store_code"] = arrayProperty['store_code']
    }
   
    if(arrayProperty['assortment_group']?.length > 0) {
      req_body["assortment_group"] = arrayProperty['assortment_group']
    }
    if(!isEmpty(action?.payload?.searchTermReq)) {
      req_body["searchColumns"] = {...action?.payload?.searchTermReq}
    }
    if(!isEmpty(action?.payload?.sortReq)) {
      req_body["sortColumn"] = {...action?.payload?.sortReq[0]}
    }
    let levelFilters = [{key: "department", value: "l2_name"}, {key: "level3", value: "l3_name"}]
    req_body["l1_name"] = [l_payload?.channel?.value]
    levelFilters.forEach(filter => {
      if(l_payload[filter.key]?.length) {
        Array.isArray(l_payload[filter.key]) && (req_body[filter.value] = flattenArray(l_payload[filter.key])?.map((ele) => ele?.value))//payload[key]?.map((ele) => ele && ele.value)
      }
    })
  try {
      const res = yield call(getDefaultConstraintsTableData, req_body);
      if (res.data.status) {
          const { count } = res.data
          yield put({ type: GENERATE_EXCEL_SUCCESS, excelData: res?.data?.data, totalCount: count, nextIndex: l_payload.row_index + l_payload.row_count } );
      }
      else {
          yield put({ type: GENERATE_EXCEL_ERROR, error: ERROR_MSG });
      }
  }
  catch (error) {
      yield put({ type: GENERATE_EXCEL_ERROR, error: ERROR_MSG });
  }
}

function* fetchStoreGroupData() {
  yield takeLatest(FETCH_STORE_GROUP_DATA, fetchStoreGroupDataWorker);
}

function* fetchStoreGradeData() {
  yield takeLatest(FETCH_STORE_GRADE_DATA, fetchStoreGradeDataWorker);
}

function* fetchStoreData() {
  yield takeLatest(FETCH_STORE_DATA, fetchStoreDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchPopUpDataWatcher() {
  yield takeLatest(FETCH_CONSTRAINTS_POPUP, fetchPopUpDataWorker);
}

function* updateTableDataWatcher() {
  yield takeLatest(UPDATE_TABLEDATA, updateTableDataWorker);
}

function* fetchMinPerStoreWathcer() {
  yield takeLatest(FETCH_MIN_PER_STORE, fetchMinPerStoreWorker)
}

function* fetchDefaultConstraintsFilterWatcher() {
  yield takeEvery(FETCH_DEFAULT_CONS_FILTER_DATA, fetchDefaultConstraintsFilterDataWorker);
}

function* fetchDefaultConstraintsWatcher() {
  yield takeEvery(FETCH_DEFAULT_CONS_DATA, fetchDefaultConstraintsWorker);
}

function* updateDefaultDataWatcher() {
  yield takeLatest(UPDATE_DEFAULT_DATA, updateDefaultDataWorker);
}

function* generateExcelWatcher() {
  yield takeLatest(GENERATE_EXCEL, generateExcelWorker)
}

export function* constraintsSaga() {
  yield all([
    fetchStoreGroupData(),
    fetchStoreGradeData(),
    fetchStoreData(),
    fetchFilterDataWatcher(),
    fetchPopUpDataWatcher(),
    fetchMinPerStoreWathcer(),
    updateTableDataWatcher(),
    fetchDefaultConstraintsFilterWatcher(),
    fetchDefaultConstraintsWatcher(),
    updateDefaultDataWatcher(),
    generateExcelWatcher(),
  ]);
}
