import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { getExcelData, resetDownloadExcel } from "./Actions/ExcelAction";
import ExportFile from "../../components/ExportFile/ExportFile";
import DownloadProgressBar from "./DownloadProgressBar";
import * as Notify from "../../components/Notification/Notifications";
import { cloneDeep } from "lodash";

const ROW_COUNT = 500;
const ParallelExcelDownload = (props) => {
  const {
    existingData,
    nextIndex,
    totalCount,
    apiURL,
    excelData,
    totalCountExcel,
    nextIndexExcel,
    downloadNextIndex,
    formatExcelData,
    getData,
    downloadExcelError,
    excelColumnsToBeFormatted,
    filters,
    searchTermReq,
    sortReq,
    reset,
    fileName,
    excelFormatColumnIndex,
    checkOutOfData,
    outOfData,
    rowCount,
    additionalInputKeys,
    rowIndexKey,
    rowCountKey,
    styleIndex,
    preDownloadCheck,
    disableExport,
    startDownload,
    parallelCount
  } = props;


  const [overAllExcelData, setExcelData] = useState([]);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [downloadExcelLoading, setDownloadLoading] = useState(false);
  const [columnFormatting, setColumnFormatting] = useState({});

  useEffect(() => {
    if (excelData?.length > 0) {
      if (!isEmpty(overAllExcelData)) {
        setExcelData([...overAllExcelData, ...excelData]);
      } else {
        setExcelData([...excelData]);
      }
    }
    // else {
    //   setExcelData([])
    // }
    // setTotalCountExcel(totalCountExcel)
    // setNextIndexExcel(nextIndexExcel)
  }, [excelData]);

  useEffect(() => {
    const allData = [...overAllExcelData, ...excelData];
    const isNext = checkOutOfData ? !outOfData : downloadNextIndex < (totalCountExcel || totalCount);
    const isAllData = allData?.length === totalCount;
    if (downloadExcelLoading && isNext && !isAllData) {
      getNextExcelData(downloadNextIndex);
    } else if ((downloadExcelLoading && !isNext) || isAllData) {
      formatData(allData);
    }
  }, [downloadNextIndex, styleIndex]);

  const closeModal = () => {
    setExcelData([]);
    setDownloadLoading(false);
    reset();
  };

  useEffect(() => {
    if (downloadExcelError) {
      setDownloadLoading(false);
      Notify.error(downloadExcelError);
      reset();
    }
  }, [downloadExcelError]);

  const formatData = (data) => {
    setExcelData(formatExcelData ? formatExcelData(data) : data);
    excelFormatColumnIndex?.diff !== 0 &&
      excelFormatColumnIndex?.hideColumnIndex > 0 &&
      formatExcelColumns();
    setTimeout(() => {
      setDownloadLoading(false);
      setDownloadExcel(true);
    }, 1000);

    setTimeout(() => {
      setExcelData([]);
      reset();
    }, 2000);
  };

  const generateExcel = () => {
    setColumnFormatting(excelColumnsToBeFormatted);
    setExcelData(existingData);
    setDownloadLoading(true);
    setDownloadExcel(false);
    if (totalCount > existingData?.length) {
      getNextExcelData(nextIndex);
    } else {
      formatData(existingData);
    }
  };

/**
   * Transforms and maps filter data based on the filters object
   * @example
   * transformFilterData({ filterKey: [{ value: 'example' }] })
   * // returns { filterKey: ['example'] }
   * @param {Object} filters - Filters object to be transformed.
   * @returns {Object} Transformed filter data.
   * @description
   *   - Converts array values in filters to an array of their `value` properties or the items themselves.
   *   - For non-array filter values, wraps them in an array.
   */
  const formatFilters = () => {
    const filterData = {}
    for (const key in filters) {
      if (Array.isArray(filters[key]) && filters[key]?.length) {
        filterData[key] = filters[key].map((item) => item?.value || item);
      } else if (!isEmpty(filters[key])) {
        filterData[key] = [
          filters[key]?.hasOwnProperty("value")
            ? filters[key]?.value
            : filters[key]
        ];
      }
    }
    return filterData;
  };

/**
   * Generates and sends a payload to fetch excel data.
   * @param {number} nextIndex - The next index for pagination or row indexing.
   * @returns {void} No return value.
   * @description
   *   - Merges filters and sorting requirements into the payload.
   *   - Ensures correct row index and row count keys in the payload.
   *   - Includes total count and additional input values if specified.
   */
  const getNextExcelData = (nextIndex) => {
    const payload = {
      ...formatFilters(),
      row_index: nextIndex,
      row_count: rowCount || ROW_COUNT
    };
    if(!checkOutOfData) {
      payload["total"] = totalCount;
    }
    if(!isEmpty(searchTermReq)) {
      payload["searchColumns"] = searchTermReq
    }
    if(!isEmpty(sortReq)) {
      payload["sortColumn"] = Array.isArray(sortReq) ? sortReq?.[0] : sortReq;
    }
    if (rowIndexKey) {
      payload["rowIndexKey"] = "rowIndex";
    } 
    if (rowCountKey) {
      payload["rowCountKey"] = "rowCount";
    } 
    if (additionalInputKeys) {
      additionalInputKeys.forEach((key) => {
        payload[key] = props[key];
      });
    }
    if(parallelCount) {
      payload["parallelCount"] = parallelCount;
    }
    getData(payload, apiURL);
  };

  useEffect(() => {
    setExcelData(existingData);
  }, [existingData]);

  // useEffect(() => {
  //   setColumnFormatting(excelColumnsToBeFormatted)
  // }, [excelColumnsToBeFormatted])

  const formatExcelColumns = () => {
    const columnsFormattingCopy = cloneDeep(columnFormatting);
    for (const key in columnsFormattingCopy) {
      if (columnsFormattingCopy[key].hasOwnProperty("value")) {
        columnsFormattingCopy[key].value += excelFormatColumnIndex.diff;
      } else {
        columnsFormattingCopy[key] += excelFormatColumnIndex.diff;
      }
    }
    setColumnFormatting(columnsFormattingCopy);
  };

  const preCheck = () => {
    preDownloadCheck(formatFilters()).then().catch(() => Notify.error("Download Failed."))
  }

  useEffect(() => {
    if(startDownload) {
      generateExcel();
    }
  }, [startDownload])

  return (
    <div className="float-right">
      <DownloadProgressBar
        showModal={downloadExcelLoading}
        closeModal={closeModal}
        nextIndex={overAllExcelData?.length}
        totalCount={totalCountExcel || totalCount}
      />
      <ExportFile
        downloadExcelData={downloadExcel}
        callFunc={() => (preDownloadCheck ? preCheck() : generateExcel())}
        text={"Excel"}
        data={overAllExcelData}
        fileName={fileName}
        type={1}
        formateXLSX={true}
        excelColumnsToBeFormatted={columnFormatting}
        disabled={disableExport}
      />
    </div>
  );
};

const mapStateToProps = ({ excelDownload }) => {
  return {
    excelData: excelDownload.excelData,
    totalCountExcel: excelDownload.downloadTotalCount,
    nextIndexExcel: excelDownload.downloadNextIndex,
    downloadNextIndex: excelDownload.downloadNextIndex,
    downloadExcelError: excelDownload.downloadExcelError,
    outOfData: excelDownload.outOfData,
    styleIndex: excelDownload?.styleIndex
  };
};

const mapDispatchToProps = (dispatch) => ({
  getData: (payload, api) => dispatch(getExcelData(payload, api)),
  reset: () => dispatch(resetDownloadExcel())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParallelExcelDownload);
