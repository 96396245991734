import React from 'react';
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css"
import { Chart } from 'primereact/chart';

const LineChart = (props) => {
    var maxY = (props?.maxValue) + (props?.maxValue * 0.2)
    var minY = (props?.minValue) - (props?.minValue * 0.2)
    var mindigit = Math.round(minY).toString().length
    var maxdigit = Math.round(maxY).toString().length
    // var modeValue = `1e${digit-1}`
    function padLeadingZerosY(num=1, size=mindigit-1) {
        var s = num+"";
        while (s.length <= size) s =  s + "0";
        return s;
    }
    function padLeadingZerosX(num=1, size=maxdigit-1) {
        var s = num+"";
        while (s.length <= size) s =  s + "0";
        return s;
    }
    const dataX = padLeadingZerosX()
    const dataY = padLeadingZerosY()
    

    
     const minimum = Math.round(minY) % dataY
     const minimumValue = Math.round(minY)-minimum
     const maximum = Math.round(maxY) % dataX
     const maximumValue = Math.round(maxY)-maximum + parseInt(dataX)

    var i=0;
    let basicOptions = React.useMemo(() => [{
        legend: {
            position: 'bottom',
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function(tooltipItem,data) {
                    var value = props.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    if(value!==undefined)
                    {
                    value = value.toFixed(0)    
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(',');
                    return value;
                    }
                    
                }
            },
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#212529',
                    autoSkip: false,
                    // maxRotation: 0,
                    // minRotation: 0
                },
                gridLines: {
                    color: "transparent",
                    display: true,
                    drawBorder: false,
                    zeroLineColor: "#343a40",
                    zeroLineWidth: 2
                },
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#212529',
                    min:minimumValue,
                    max:maximumValue,
                    userCallback: function(value, index, values) {
                        // Convert the number to a string and splite the string every 3 charaters from the end
                        value = value.toString();
                        value = value.split(/(?=(?:...)*$)/);
                        value = value.join(',');
                        return value;
                    }
                    // fixedStepSize:10000,
                },
                gridLines: {
                    color: "transparent",
                    display: true,
                    drawBorder: false,
                    zeroLineColor: "#343a40",
                    zeroLineWidth: 2
                }
            }]
        }
    }],[props.data, minimumValue, maximumValue, props.isReviewReq, props.forecastValue]);

    const { height, width, data } = props;

    return (
        <>
                  <Chart type="line" height={height} width={width} data={data} options={basicOptions[0]} />
            <div className="charts__axes"> <span><b>{props.text}</b></span> </div>
        </>

    )
}

export default LineChart;