import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ClusterFiltersInModal from "../../../components/Clustering/ClusterFiltersInModal";
import PageLoader from "../../../components/Loader/PageLoader";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import {
  fetchFilterData,
  fetchStoreConfigDCList,
  fetchStoreStatusData,
  resetAll,
  updateStoreStatusData,
} from "../ProductStoreAction";
import SelectCellComponent from "../../../components/Table/Cellrenderer/SelectCellComponent";
import DateInput from "../../../components/Table/Cellrenderer/DateInput";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ConfirmationAlert from "../../../components/ConfirmationAlert/ConfirmationAlert";
import {
  HIDE_TABLE_COLUMNS,
  currentClientName,
  getStoreGradeColumnNamesByClient,
} from "../../../utils/commonUtilities";
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { ERROR_MSG } from "../../../constants/globalConstant";
import MultiSelectCell from "../../../components/Table/Cellrenderer/MultiSelectCell";
import "./StoreStatus.css";
import ErrorModal from "../../../components/ErrorModal/ErrorModal";
import EditModal from "./EditModal";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const getEndDate = (p_end, p_start) => {
  let d1 = moment(p_end);
  let d2 = moment(p_start);
  if (d1 > d2) {
    return moment(d1).format("YYYY-MM-DD");
  } else {
    return moment(d2).format("YYYY-MM-DD");
  }
};

const StoreStatus = (props) => {
  const [filterData, setFilterData] = useState([]);
  const [storeStatusData, setStoreStatusData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [updatedId, setUpdatedId] = useState([]);
  const [RTinstance, setRTinstance] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [req, setReq] = useState([]);
  const [initialHiddenColumns, setInitialHiddenColumns] = useState([]);
  const [storeGradeColumns, setStoreGradeColumns] = useState(
    getStoreGradeColumnNamesByClient()
  );
  const [dcOptions, setDCOptions] = useState([]);
  const [secondaryDC, setSecondaryDC] = useState({});
  const [dcUpdateId, setDCUpdate] = useState([]);
  const [errorModalMessage, setErrorMessage] = useState(null)
  const [filters, setFilters] = useState({})
  const [showEditModal, setShowEditModal] = useState(false);
  const [enableSetAll, setEnableSetAll] = useState(false);

  useEffect(() => {
    let hiddenColumns = HIDE_TABLE_COLUMNS[currentClientName];
    hiddenColumns && setInitialHiddenColumns(hiddenColumns);
    props.fetchDCList();
    props.fetchFilterData({});
    props.fetchStoreStatusData({});
  }, []);

  useEffect(() => {
    setFilterData(props.filterData);
  }, [props.filterData]);

  useEffect(() => {
    let dcObj = {};
    dcOptions.forEach((dc) => {
      dcObj[dc.value] = dc.label;
    });
    let data = cloneDeep(props.storeStatusData);
    data.forEach((store) => {
      store.showCheckbox = store.disable_status
      store.checkColor = store.disable_status
      store.primary_dc = store?.primary_dc?.map((dc) => {
        return { label: dcObj[dc], value: dc };
      });
      store.secondary_dc = store?.secondary_dc?.map((dc) => {
        return { label: dcObj[dc], value: dc };
      });
    });
    setStoreStatusData(data);
  }, [props.storeStatusData]);

  useEffect(() => {
    if (props.updateSucess) {
      // Notify.success("Store Status Saved Sucessfully!!");
      Notify.success("Updated Sucessfully!!");
      setDisabled(true);
      props.fetchStoreStatusData(filters);
      setUpdatedId([]);
      setDCUpdate([]);
    }
  }, [props.updateSucess]);

  useEffect(() => {
    if (props.updateError) {
      Notify.error(ERROR_MSG);
      setUpdatedId([]);
    }
  }, [props.updateError]);

  useEffect(() => {
    return () => {
      props.resetAll();
    };
  }, []);

  useEffect(() => {
    let options = props?.dcList?.map((dc) => {
      return {
        label: dc?.store_name,
        value: dc?.store_code,
        channel: dc?.channel,
      };
    });
    setDCOptions(options);
  }, [props.dcList]);

  const captureUpdateStoreID = (store_code) => {
    let dcUpdatedStores = cloneDeep(dcUpdateId);
    if (dcUpdatedStores?.indexOf(store_code) > -1) {
      dcUpdatedStores.splice(dcUpdatedStores?.indexOf(store_code), 1);
      setDCUpdate(dcUpdatedStores);
    }
    setUpdatedId((old) => {
      return [...old, store_code];
    });
  };

  const storecolumns = React.useMemo(
    () => [
      {
        Header: "",
        sticky: "left",
        id: "storeColumnSticky",
        columns: [
          {
            Header: "Store Number",
            accessor: "store_code",
            id: "store_code",
            Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
            filter: 'bulkFilterSpaceSeperated',
          },
          {
            Header: "Store Name",
            accessor: "store_name",
            width: 210,
            Filter: ColumnFilter,
          },
        ],
      },
      {
        Header: "Country",
        accessor: "country",
        Filter: ColumnFilter,
      },
      {
        Header: levelFiltersLabelFormatter("level1"),
        id: "l1_name",
        accessor: "channel",
        Filter: ColumnFilter,
      },
      storeGradeColumns,
      {
        Header: "Store Group",
        accessor: "store_groups",
        Cell: (instance) => <ReactTollTip {...instance} />,
        Filter: ColumnFilter,
        width: 200,
      },
      {
        Header: "Climate",
        accessor: "climate",
        Filter: ColumnFilter,
      },
      {
        Header: "Region Description",
        accessor: "region",
        Filter: ColumnFilter,
      },
      {
        Header: "District",
        accessor: "district",
        Filter: ColumnFilter,
      },
      {
        Header: "Size (Sq Ft)",
        accessor: "size",
        Cell: (inst) => numberUSFormatting(inst?.value),
        width: 170,
        Filter: NumberRangeColumnFilter,
        filter: "between",
      },
      {
        Header: "Primary DC",
        accessor: "primary_dc",
        disableFilters: true,
        Cell: (instance) => (
          <div className={"w-100 " + (instance.row.original?.disable_status ? "disabled__select" : "")}>
            <MultiSelectCell
              isDisabled={instance.row.original?.disable_status}
              {...instance}
              dropdownOptions={dcOptions
                ?.filter((dc) => dc?.channel == instance.row.original?.channel)
                .map((dc) => {
                  return { label: dc.label, value: dc.value };
                })}
              onBlurEvent={(rowIdx, columnId, values, article) => {
                instance.updateMyData(rowIdx, "secondary_dc", []);
                setDisabled(false);
                setDCUpdate((old) => {
                  return [...old, instance?.row?.original?.store_code];
                });
              }}
            />
          </div>
        ),
      },
      {
        Header: "Secondary DC",
        accessor: "secondary_dc",
        disableFilters: true,
        Cell: (instance) => {
          let primary = instance?.row?.original?.primary_dc?.map(
            (dc) => dc.value
          );
          const disable = !primary?.length || instance.row.original?.disable_status

          return (
            <div className={"w-100 " + (disable ? "disabled__select" : "")}>
              <MultiSelectCell
                isDisabled={disable}
                {...instance}
                dropdownOptions={dcOptions
                  ?.filter(
                    (dc) =>
                      !primary?.includes(dc.value) &&
                      dc?.channel == instance.row.original?.channel
                  )
                  .map((dc) => {
                    return { label: dc.label, value: dc.value };
                  })}
                onBlurEvent={(rowIdx, columnId, values, article) => {
                  setDisabled(false);
                  setDCUpdate((old) => {
                    return [...old, instance?.row?.original?.store_code];
                  });
                }}
                // value={instance?.row?.original?.secondary_dc}
              />
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "store_status",
        Filter: ColumnFilter,
        filter: "filterSingleSelectCell",
        Cell: (instance) => (
          <div>
            <SelectCellComponent
              disabled={instance.row.original?.disable_status}
              defaultValue={instance.row.original?.store_status}
              options={instance.row.original?.options}
              changeHandler={(rowIdx, columnId, value) => {
                setDisabled(false);
                captureUpdateStoreID(instance.row.original?.store_code);
                let l_instance = instance.row.original;
                if (
                  l_instance.inactive_start === "-" &&
                  value === "under_renovation"
                )
                  instance.updateMyData(
                    rowIdx,
                    "inactive_start",
                    moment(new Date()).format("YYYY-MM-DD")
                  );
                if (
                  l_instance.inactive_end === "-" &&
                  value === "under_renovation"
                )
                  instance.updateMyData(
                    rowIdx,
                    "inactive_end",
                    moment(new Date()).format("YYYY-MM-DD")
                  );
                if (value === "open" || value === "closed") {
                  instance.updateMyData(rowIdx, "inactive_start", "-");
                  instance.updateMyData(rowIdx, "inactive_end", "-");
                }
              }}
              {...instance}
            />
          </div>
        ),
      },
      {
        Header: "Modified By",
        accessor: "updated_by",
        width: 250,
        Filter: ColumnFilter,
      },
      {
        Header: "From",
        accessor: "inactive_start",
        disableFilters: true,
        Cell: (instance) =>
          instance.row.original.store_status === "under_renovation" ? (
            <DateInput
              {...instance}
              value={
                instance.row.original?.inactive_start == "-"
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : instance.row.original.inactive_start
              }
              dateFormat={"MM-DD-YYYY"}
              changeHandler={(rowIdx, columnId, value) => {
                captureUpdateStoreID(instance.row.original?.store_code);
                let l_date = getEndDate(
                  instance.row.original.inactive_end,
                  value
                );
                setDisabled(false);
                instance.updateMyData(rowIdx, "inactive_end", l_date);
              }}
            />
          ) : (
            "-"
          ),
        width: 170,
      },
      {
        Header: "Upto Date",
        accessor: "inactive_end",
        disableFilters: true,
        Cell: (instance) =>
          instance.row.original.store_status === "under_renovation" ? (
            <DateInput
              {...instance}
              min={
                instance.row.original?.inactive_start == "-"
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : instance.row.original.inactive_start
              }
              value={
                instance.row.original?.inactive_end == "-"
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : instance.row.original.inactive_end
              }
              changeHandler={(rowIdx, columnId, value) => {
                setDisabled(false);
                captureUpdateStoreID(instance.row.original?.store_code);
              }}
              dateFormat={"MM-DD-YYYY"}
            />
          ) : (
            "-"
          ),

        width: 170,
      },
    ],
    [initialHiddenColumns, dcOptions]
  );

  const getStores = (p_req) => {
    setDisabled(true);
    setUpdatedId([]);
    let req = {};
    if (!isEmpty(p_req)) {
      for (const key in p_req) {
        req[key] = p_req[key].map(function mapper(ele) {
          if (Array.isArray(ele)) {
            return ele.map(mapper);
          } else {
            return ele.value;
          }
        });
      }
      for (let i in req) {
        req[i] = req[i].flat(Infinity);
      }
    }
    setFilters(req)
    props.fetchStoreStatusData(req);
  };

  const resetTableData = () => {
    setDisabled(true);
    setStoreStatusData([]);
    setUpdatedId([]);
  };

  const updateChanges = () => {
    let uniqueUpdatedIds = [...new Set(updatedId)];
    let uniqueDCUpdatedIds = [];
    new Set(dcUpdateId).forEach((store) => {
      !uniqueUpdatedIds.includes(store) && uniqueDCUpdatedIds.push(store);
    });
    let data = RTinstance?.data?.filter(
      (val) =>
        uniqueUpdatedIds.includes(val.store_code) &&
        !uniqueDCUpdatedIds.includes(val.store_code)
    );
    let empty_primary_dc = [];
    data = data.concat(
      RTinstance?.data
        ?.filter((val) => uniqueDCUpdatedIds.includes(val.store_code))
        ?.map((item) => {
          return {
            primary_dc: item.primary_dc,
            secondary_dc: item.secondary_dc,
            store_code: item.store_code,
          };
        })
    );
    data.forEach((item) => {
      if (!item.primary_dc?.length) {
        empty_primary_dc.push(item.store_code);
      }
    });
    if(empty_primary_dc?.length) {
      let msg = [<h4 style={{fontWeight: "900"}}>Please select primary DC for below stores</h4>]
      msg.push(<div className="ml-2">{empty_primary_dc.join(", ")}</div>)
      setErrorMessage(msg)
      // setErrorMessage(`Please select primary DC for below stores ${empty_primary_dc.join(", ")}`)
    }
    else if (data.some((val) => val.store_status == "closed")) {
      // if (window.confirm("Are you sure you wish to close the Store(s)?")){
      //     props.updateStoreStatusData(data)
      // }
      setReq(data);
      setIsClosing(true);
    } else {
      props.updateStoreStatusData(data);
    }
  };

  const handleOk = () => {
    props.updateStoreStatusData(req);
    setIsClosing(false);
  };

  const handleCancel = () => {
    setIsClosing(false);
  };

  const handleClose = () => {
    setIsClosing(false);
  };

  const toggleUpdateModal = (bool = true) => {
    setShowEditModal(bool);
  }

  const onInstanceChange = (instance) => {
    const enable = !isEmpty(instance?.state?.selectedRowIds) && instance.selectedFlatRows.filter(item => !item.original.disable_status)?.length
    setEnableSetAll(enable)
  }

  return (
    <React.Fragment>
      {/* <Notification /> */}
      {showEditModal && (
        <EditModal
          DC_OPTIONS={dcOptions}
          selectedRows={RTinstance?.selectedFlatRows}
          closeModal={toggleUpdateModal}
          updateStoreStatusData={props.updateStoreStatusData}
        />
      )}
      {isClosing && (
        <ConfirmationAlert
          modalStyle={{ width: "45rem" }}
          message="Are you sure you wish to close the Store(s)?"
          handleOk={handleOk}
          handleCancel={handleCancel}
          handleClose={() => handleClose()}
        />
      )}
      {errorModalMessage && (
        <ErrorModal
          message={errorModalMessage}
          // buttonLabel={buttonLabel}
          // secondaryButtonLabel={secondaryButtonLabel}
          handleCancel={() => setErrorMessage(null)}
          handleOk={() => setErrorMessage(null)}
          handleClose={() => setErrorMessage(null)}
        />
      )}

      <section className="row section">
        <ClusterFiltersInModal
          storeConfig={true}
          storeFilterData={filterData}
          getStores={getStores}
          resetTableData={resetTableData}
        />
      </section>
      <div className="store__status__table">
        <PageLoader loader={props.storeStausLoading} gridLoader={true}>
          {props.storeStatusError ? (
            <div className="error">{ERROR_MSG}</div>
          ) : (
            <ReactTableWithPlugins
              initialHiddenColumns={initialHiddenColumns}
              getInstanceOnMount={(inst) => {
                setRTinstance(inst);
                onInstanceChange(inst);
              }}
              sortBy={[{ id: "store_code" }]}
              data={storeStatusData}
              columns={storecolumns}
              keyRT="productDetailView"
              renderMarkup="TableMarkup"
              // shouldPagination
              embededScroll
              tableId="store_config"
              features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
            />
          )}
          <div className="mt-3 d-flex align-items-center">
            <div className="indicate-div sister-store__map" style={{ background: "lightslategrey"}}></div>
            <span className="ml-2 font-italic mb-0">Closed Stores</span>
          </div>
        </PageLoader>
      </div>
      <div style={{ textAlign: "center", margin: "8px auto" }}>
        <button
          className="btn btn-primary mr-4"
          disabled={!enableSetAll}
          onClick={toggleUpdateModal}
        >
          Bulk Edit
        </button>

        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={() => updateChanges()}
        >
          Save Changes
        </button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ storeGroup, productStoreStatus }) => {
  return {
    // filterData: productStoreStatus?.filterData,
    filterData: storeGroup?.storeGroupFiltersData,
    storeStatusData: productStoreStatus?.storeStatusData,
    storeStausLoading: productStoreStatus?.storeStausLoading,
    updateSucess: productStoreStatus?.updateSucess,
    updateError: productStoreStatus?.updateError,
    storeStatusError: productStoreStatus?.storeStatusError,
    dcList: productStoreStatus?.dcList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetAll: () => dispatch(resetAll()),
  fetchFilterData: (payload) => dispatch(fetchFilterData(payload)),
  fetchStoreStatusData: (payload) => dispatch(fetchStoreStatusData(payload)),
  updateStoreStatusData: (payload) => dispatch(updateStoreStatusData(payload)),
  fetchDCList: (payload) => dispatch(fetchStoreConfigDCList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreStatus);
