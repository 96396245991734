import React, { useEffect, useState } from 'react';
import MultiSelect from "../../components/Filters/MultiSelect";
import Select from "../../components/Filters/SingleSelect";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from 'lodash'
import * as adaVisualActions from './actions/AdaVisualActions';
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import * as AdaConst from './AdaVisualConstants';
import { levelFiltersLabelFormatter } from "../../utils/filterLevelMapping";
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getFilterForClients, isMandatory, getLevelFiltersForUserPreference, getDefaultFiltersCondition } from "../../utils/commonUtilities";

/**
 * View Component with Ada Stand Alone Filters
 */
const AdaVisualFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level6, setLevel6] = useState(null);
    const [level7, setLevel7] = useState(null);
    const [articleId, setArticleId] = useState(null);
    const [store, setStore] = useState(null);
    const [assortmentIndicator, setAssortmentIndicator] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);
    const [level7Options, setLevel7Options] = useState(null);
    const [articleIdOptions, setArticleIdOptions] = useState(null);
    const [articleFilter,setArticleFilter] = useState(null);
    const [storeOptions, setStoreOptions] = useState(null);
    const [assortmentIndicatorOptions, setAssortmentIndicatorOptions] = useState(null);

    const { adaVisualActions, handleFilterRequest, resetAdaDisplayState } = props;

    useEffect(() => {
        adaVisualActions.getAdaFiltersData({});
    }, []);

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);


    useEffect(() => {
        setGenderOptions(() => props.genderOptions);
    }, [props.genderOptions]);

    useEffect(() => {
        setSubCatOptions(() => props.subCatOptions);
    }, [props.subCatOptions]);

    useEffect(() => {
        setDCSOptions(() => props.dcsOptions);
    }, [props.dcsOptions]);

    useEffect(() => {
        setLevel5Options(() => props.level5Options);
    }, [props.level5Options]);

    useEffect(() => {
        setLevel6Options(() => props.level6Options);
    }, [props.level6Options]);

    useEffect(() => {
        setLevel7Options(() => props.level7Options);
    }, [props.level7Options]);

    useEffect(() => {
        setArticleIdOptions(() => props.articleIdOptions);
        // if (department && gender) {
        //     setArticleId(() => props.articleIdOptions);
        // }
        // else {
        //     //No code here
        // }
    }, [props.articleIdOptions]);

    useEffect(() => {
        setAssortmentIndicatorOptions(() => props.assortmentIndicatorOptions);
    }, [props.assortmentIndicatorOptions]);

    useEffect(() => {
        setStoreOptions(() => props.storeOptions);
    }, [props.storeOptions]);

    const resetRestFilters = (p_index) => {
        if (p_index == 0) {
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
            level5 && setLevel5(null)
            level5Options && setLevel5Options(null)
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            assortmentIndicator && setAssortmentIndicator(null)
            store && setStore(null);
            articleId && setArticleId(null);
        }
        if (p_index == 0 || p_index == 1) {
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
            level5 && setLevel5(null)
            level5Options && setLevel5Options(null)
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            assortmentIndicator && setAssortmentIndicator(null)
            store && setStore(null);
            articleId && setArticleId(null);
        }
        if (p_index == 0 || p_index == 1 || p_index == 2) {
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
            level5 && setLevel5(null)
            level5Options && setLevel5Options(null)
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            assortmentIndicator && setAssortmentIndicator(null)
            store && setStore(null);
        }
        if (p_index == 0 || p_index == 1 || p_index == 2 || p_index==3) {
            level5 && setLevel5(null)
            level5Options && setLevel5Options(null)
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            assortmentIndicator && setAssortmentIndicator(null)
            store && setStore(null);
        }
        if(p_index === 4){
            level6 && setLevel6(null);
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
            store && setStore(null);
        }
        if(p_index === 5){
            // level7 && setLevel7(null);
            // level7Options && setLevel7Options(null);
            assortmentIndicator && setAssortmentIndicator(null)
            store && setStore(null);
        }
            level7 && setLevel7(null);
            level7Options && setLevel7Options(null);
    };

    const onDepartmentChange = (val,p_userPreference) => {
        setDepartment(val);
        if(p_userPreference?.user_preference) { 
            adaVisualActions.getAdaFiltersData({ level1: [val] },'l10');
          }
          else{
        adaVisualActions.getAdaFiltersData({ level1: [val] });
          }
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            setArticleId(null);
            setGender(valuesArray[1]);
            if(event?.user_preference) {
                adaVisualActions.getAdaFiltersData({ level1: [event?.l1_name], level2: Array.isArray(val) ? val : [val] });
              }
              else {
                adaVisualActions.getAdaFiltersData({ level1: [department], level2: req_val });
              }
            // adaVisualActions.getAdaFiltersData({ level1: [department], level2: [...req_val] });
            setRequest(old => {
                return {
                    ...old,
                    'level2': valuesArray[0],
                }
            })
        }
        else {
            setGender(val);
            if(event?.user_preference) { 
                adaVisualActions.getAdaFiltersData({ level1: [event?.l1_name], level2: [val] },"l11");
              }
              else{
               adaVisualActions.getAdaFiltersData({ level1: [department], level2: [val] })
              }
              setRequest(old => {
                return {
                    ...old,
                    'level2': val,
                }
            })
        }
        resetRestFilters(1);
    };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
            setArticleId(null);
            setSubCat(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({ level1: [department], level2: Array.isArray(gender) ? gender : [gender], level3: req_val && [...req_val] });
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                    'level4': null,
                    "level5":null,
                    'level6':null,
                    'level7':null
                    // 'article': null
                }
            });
        }
        else {
            setSubCat(val);
            if(event?.user_preference) { 
                adaVisualActions.getAdaFiltersData({ level1: [event?.l1_name], level2: [event?.l2_name], level3:[val] });
              }
              else{
                adaVisualActions.getAdaFiltersData({ level1: [department], level2: gender, level3: [val] });
              }
              setRequest(old => {
                return {
                    ...old,
                    'level3': val,
                    'level4': null,
                    "level5":null,
                    'level6':null,
                    'level7':null
                    // 'article': null
                }
            })
        }
        resetRestFilters(2);
    };

    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setArticleId(null);
            setDCS(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2:  Array.isArray(gender) ? gender : [gender], level3:subCat && [...subCat],
                level4: [...req_val]
            });
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                    "level5":null,
                    'level6':null,
                    'level7':null
                    // 'article': null
                }
            });
        }
        else {
            setDCS(val);
            adaVisualActions.getAdaFiltersData(
                { level1: [department], level2: gender, level3: [subCat], level4: [val] },
                "level5"
            );
            setRequest(old => {
                return {
                    ...old,
                    'level4': val,
                    "level5":null,
                    'level6':null,
                    'level7':null
                    // 'article': null
                }
            });
        }
        resetRestFilters(3);
    };

    const onlevel5Change =(val, event, that) =>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
            setArticleId(null);
            setLevel5(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2:  Array.isArray(gender) ? gender : [gender],level3: subCat && [...subCat],
                level4: dcs && [...dcs] , level5:[...req_val]
            });
            setRequest(old => {
                return {
                    ...old,
                    'level5': valuesArray[0],
                    'level6':null,
                    'level7':null
                    // 'article': null
                }
            });
        }
        else {
            setLevel5(val);
            adaVisualActions.getAdaFiltersData(
                { level1: [department], level2: gender, level3: [subCat], level4: [dcs] , level5:[val] },
                "level6"
            );
        }
        resetRestFilters(4);
    }

    const onlevel6Change =(val, event, that) =>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
            setArticleId(null);
            setLevel6(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2:  Array.isArray(gender) ? gender : [gender], level3: subCat &&[...subCat],
                level4: dcs && [...dcs] ,level5:level5 && [...level5], level6:[...req_val]
            });
            setRequest(old => {
                return {
                    ...old,
                    'level6': valuesArray[0],
                    'level7':null
                    // 'article': null
                }
            });
        }
        else {
            setLevel5(val);
            adaVisualActions.getAdaFiltersData(
                { level1: [department], level2: gender, level3: [subCat], level4: [dcs] , level5:[level5] ,level6:[val] },
                "level7"
            );
            setRequest(old => {
                return {
                    ...old,
                    'level6': val,
                    'level7':null
                    // 'article': null
                }
            });
        }
        resetRestFilters(5);
    }

    const onlevel7Change =(val, event, that) =>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
            setArticleId(null);
            setLevel7(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department],level2:  Array.isArray(gender) ? gender : [gender], level3: subCat && [...subCat],
                level4: dcs && [...dcs] ,level5:[...level5],level6:[...level6], level7:[...req_val]
            });
            setRequest(old => {
                return {
                    ...old,
                    'level7': valuesArray[0],
                    // 'article': null
                }
            });
        }
        else {
            setLevel5(val);
            adaVisualActions.getAdaFiltersData(
                { level1: [department], level2: gender, level3: [subCat], level4: [dcs] , level5:[level5] ,level6:[level6],level7:[val] },
                "article"
            );
            setRequest(old => {
                return {
                    ...old,
                    'level7': val
                    // 'article': null
                }
            });
        }
        // resetRestFilters(5);
    }

    const onArticleIdChange = (val, event, that) => {
        if (Array.isArray(val)) {
            setArticleId(null);
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], articleIdOptions, isEqual);
            setArticleId(valuesArray[1]);
            // adaVisualActions.getAdaFiltersData({
            //     level1: [department], level2: [...gender], level3: subCat ? [...subCat] : null,
            //     level4: dcs ? [...dcs] : null, article: [...req_val],
            // });

            setRequest(old => {
                return {
                    ...old,
                    'article': valuesArray[0]
                }
            })
        }
        else {
            setArticleId(val);
            // adaVisualActions.getAdaFiltersData({
            //     level1: [department], level2: gender, level3: [subCat], level4: [dcs],
            //     article: [val],
            // });
        }
        //resetRestFilters(4);
    };

    const onAssortmentChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], assortmentIndicatorOptions, isEqual);
            setArticleId(null);
            setAssortmentIndicator(valuesArray[1]);
            adaVisualActions.getAdaFiltersData({
                level1: [department], level2:  Array.isArray(gender) ? gender : [gender], level3: subCat && [...subCat],
                level4: dcs && [...dcs] ,level5:level5 && [...level5], level6: level6 && [...level6], assortment_indicator: [...req_val]
            });
            setRequest(old => {
                return {
                    ...old,
                    'assortment_indicator': valuesArray[0],
                }
            });
        }
        else {
            setAssortmentIndicator(val);
            adaVisualActions.getAdaFiltersData(
                { level1: [department], level2: gender, level3: [subCat], level4: [dcs] , level5:[level5] ,level6:[level6], assortment_indicator: [val] },
                "level7"
            );
            setRequest(old => {
                return {
                    ...old,
                    'assortment_indicator': val,
                }
            });
        }
        //resetRestFilters(5);
    };

    const onStoreChange = (val, event, that) => {
        if (Array.isArray(val)) {
            setStore(null);
            let valuesArray = handleSelectAll(val, event, that)
            let req_val = intersectionWith(valuesArray[0], storeOptions, isEqual);
            setStore(valuesArray[1]);
            // adaVisualActions.getAdaFiltersData({
            //     level1: [department], level2: [...gender], level3: subCat ? [...subCat] : null,
            //     level4: dcs ? [...dcs] : null, article: [...req_val],
            // });

            setRequest(old => {
                return {
                    ...old,
                    'store_code': valuesArray[0]
                }
            })
        }
        else {
            setStore(val);
            // adaVisualActions.getAdaFiltersData({
            //     level1: [department], level2: [gender], level3: [subCat], level4: [dcs],
            //     article: [val],
            // });
        }
        // resetRestFilters(4);
    };

    const applyFilters = () => {
        if (getDefaultFiltersCondition({l1_name: department, l2_name: gender}) && (articleId && articleId?.length > 0)) {
            handleFilterRequest({
                department,
                gender,
                subCat,
                dcs,
                level5,
                level6,
                level7,
                articleId,
                articleFilter,
                assortmentIndicator,
                store,
            });
            // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
            setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:department,l2_name:gender}))
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null)
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setLevel5(null);
        setLevel6(null);
        setLevel7(null);
        setArticleId(null);
        // adaVisualActions.resetAdaFilterData();
        // adaVisualActions.resetAdaVisual();
        // adaVisualActions.getAdaFiltersData({});
        resetAdaDisplayState();
        setAssortmentIndicator(null);
        setStore(null);
    };

    useEffect(() => {
        // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
        getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange})
    }, []);


    const getDDLabel = (label) => label ? label : ""

    return (
        <>
            <section className="row section" style={{flex:"auto"}}>
                {/* <Notification /> */}
                <div className="col-md-3 col-md-3-dd required">
                    <label className="fnt-md fnt-bold fnt-bold">{levelFiltersLabelFormatter("level1")}</label>
                    <Select
                        name="department"
                        value={department}
                        onChange={onDepartmentChange}
                        placeHolder={`Select ${levelFiltersLabelFormatter("level1")}`}
                        options={departmentOptions?.length ? departmentOptions : []}
                    />
                </div>
                <div className={`col-md-3 col-md-3-dd mb-3 ${isMandatory("l2_name")}`}>
                {getFilterForClients({
                            label:props.filterLabels?.level2, 
                            name: "gender", 
                            dropdownValue: gender,
                            onDropDownChange: onGenderChange, 
                            placeHolder: `Select `, 
                            optionsArray:  (genderOptions?.length) ? genderOptions : [],
                            level: "l2_name",
                            isStandAlone: true
                        })}
                    {/* <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
                    <MultiSelect
                        name="gender"
                        dropdownValue={gender}
                        onDropDownChange={onGenderChange}
                        placeHolder={`Select ${props.filterLabels?.level2}`}
                        optionsArray={genderOptions?.length ? genderOptions : []}
                    /> */}
                </div>
                {
                !shouldHideForCurrentClient("l3_name") &&
                <>
                    <div className={`col-md-3 col-md-3-dd mb-3 ${isMandatory("l3_name") }`}>
                    {getFilterForClients({
                            label:props.filterLabels?.level3, 
                            name: "subCat", 
                            dropdownValue: subCat,
                            onDropDownChange: onSubCatChange, 
                            placeHolder: props.filterLabels?.level3 ? props.filterLabels?.level3 : "", 
                            optionsArray:  (subCatOptions?.length) ? subCatOptions : [],
                            level: "l3_name",
                            isStandAlone: true
                        })}
                        {/* <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
                        <MultiSelect
                            name="subCat"
                            dropdownValue={subCat}
                            onDropDownChange={onSubCatChange}
                            placeHolder={`Select ${props.filterLabels?.level3}`}
                            optionsArray={subCatOptions?.length ? subCatOptions : []}
                        /> */}
                    </div>
                    <div className="col-md-3 fill col-md-3-dd">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                        <MultiSelect
                            name="dcs"
                            dropdownValue={dcs}
                            onDropDownChange={onDCSChange}
                            placeHolder={`Select ${props.filterLabels?.level4}`}
                            optionsArray={dcsOptions?.length ? dcsOptions : []}
                        />
                    </div>
                </>
                }
                {
                    !shouldHideForCurrentClient("l5_name") &&
                    <div className="col-md-3  fill col-md-3-dd">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                        <MultiSelect
                            name="level5"
                            dropdownValue={level5}
                            onDropDownChange={onlevel5Change}
                            placeHolder={`Select ${getDDLabel(props.filterLabels?.level5)}`}
                            optionsArray={level5Options?.length ? level5Options : []}
                        />
                    </div>
                }
                {
                    !shouldHideForCurrentClient("l6_name") &&
                    <div className="col-md-3  fill col-md-3-dd">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                        <MultiSelect
                            name="level6"
                            dropdownValue={level6}
                            onDropDownChange={onlevel6Change}
                            placeHolder={`Select ${getDDLabel(props.filterLabels?.level6)}`}
                            optionsArray={level6Options?.length ? level6Options : []}
                        />
                    </div>
                }
                 {
                    !shouldHideForCurrentClient("l7_name") &&
                    <div className="col-md-3  fill">
                        <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                        <MultiSelect
                            name="level7"
                            dropdownValue={level7}
                            onDropDownChange={onlevel7Change}
                            placeHolder={`Select ${getDDLabel(props.filterLabels?.level7)}`}
                            optionsArray={level7Options?.length ? level7Options : []}
                        />
                    </div>
                }
                <div className="col-md-3 col-md-3-dd">
                    <label className="fnt-md fnt-bold">Assortment Indicator</label>
                    <MultiSelect
                        name="assortment_indicator"
                        dropdownValue={assortmentIndicator}
                        onDropDownChange={onAssortmentChange}
                        placeHolder={"Select Assortment Indicator"}
                        optionsArray={assortmentIndicatorOptions?.length ? assortmentIndicatorOptions : []}
                    />
                </div>
                <div className="col-md-3 col-md-3-dd required">
                    <label className="fnt-md fnt-bold">{AdaConst.ADA_FILTER_ARTICLE_ID}</label>
                    <MultiSelect
                        name="articleId"
                        dropdownValue={articleId}
                        onDropDownChange={onArticleIdChange}
                        placeHolder={"Select articleId"}
                        optionsArray={articleIdOptions?.length ? articleIdOptions : []}
                        isBulkSearch
                        splitBy={","}
                    />
                </div>
                <div className="col-md-3 col-md-3-dd">
                    <label className="fnt-md fnt-bold">Store</label>
                    <MultiSelect
                        name="store_code"
                        dropdownValue={store}
                        onDropDownChange={onStoreChange}
                        placeHolder={"Select Store"}
                        optionsArray={storeOptions?.length ? storeOptions : []}
                        isBulkSearch
                    />
                </div>
                <div className="col-md-3 fill col-md-3-dd">
                    <button
                        onClick={applyFilters}
                        className="btn btn-primary filter-constraints-mode"
                        title="Apply filters"
                    >
                        <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                    </button>
                    <button
                        onClick={() => resetFilters("All")}
                        className="btn undo-constraints-mode"
                        title="Reset filters"
                    >
                        <i className="fa fa-undo mr-2"></i>Reset
                    </button>
                </div>
                <div className="required fnt-md pt-5" style={{ color: "red" }}>
                    <label></label>Fields are mandatory
                </div>
            </section>
        </>
    );
}
);

const mapStateToProps = ({ adaVisual }) => ({
    departmentOptions: adaVisual.departmentOptions,
    genderOptions: adaVisual.genderOptions,
    subCatOptions: adaVisual.subCatOptions,
    dcsOptions: adaVisual.dcsOptions,
    level5Options: adaVisual.level5Options,
    level6Options: adaVisual.level6Options,
    level7Options: adaVisual.level7Options,
    articleIdOptions: adaVisual.articleIdOptions,
    assortmentIndicatorOptions: adaVisual.assortmentIndicatorOptions,
    storeOptions: adaVisual.storeOptions,
    filterLabels: adaVisual.filterLabels,
});

const mapDispatchToProps = (dispatch) => ({
    adaVisualActions: bindActionCreators(adaVisualActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdaVisualFilters);