import { takeLatest, takeEvery, put, call, all, delay } from "redux-saga/effects";
import * as actionTypes from "../actions/DemandAction/DemandActionTypes"
import {
    getDemandConstraintsData, getDemandAddProductsData, getNewStoreAllocations, getNewStoreSizeCurve,
    saveNewStoreAllocation, getReviewAllocationDetails, reviewNewStoreAllocation,
    getArticleAllocation, updateArticleAllocation, saveNewStoreInfo, getNewReservedStoreList
} from "../../../routes/api";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import { ERROR_MSG } from "../../../constants/globalConstant";


function* getDemandData(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (!isEmpty(payload[key])) {
                if (Array.isArray(payload[key]) && key !== "dc" && key !== "sister_store_codes") {
                    req[key] = payload[key].map(item => item.value)
                }
                else {
                    req[key] = payload[key]
                }
            }
        }

        req["styleIndex"] = payload?.styleIndex
        req["sortColumn"] = payload?.sortColumn
        // req["rowCount"] = payload.rowCount

        const res = yield call(getDemandConstraintsData, req);
        let { data, message, status, out_of_data } = res.data
        if (status) {
            let demandData = cloneDeep(data)
            demandData.map(item => {
                item.ros = Number(item.ros) ? Number(item.ros).toFixed(2) : item.ros
                item.aps = Number(item.aps) ? Number(item.aps).toFixed(2) : item.aps
                item.wos = Number(item.wos) ? Number(item.wos).toFixed(2) : item.wos
                item.demand_estimated = Number(item.demand_estimated) ? Number(item.demand_estimated).toFixed(2) : item.demand_estimated
            })
            // yield put({ type: actionTypes.GET_DEMAND_DATA_SUCCESS, data: demandData  });
            yield put({ type: payload?.checkAllCall ? actionTypes.GET_CHECK_ALL_DEMAND_DATA_SUCCESS : actionTypes.GET_DEMAND_DATA_SUCCESS, data: {demandData, styleIndex: out_of_data ? 0 : res?.data?.styleIndex, out_of_data}  });
        }
        else {
            yield put({ type: actionTypes.GET_DEMAND_DATA_ERROR, error: message ? message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_DEMAND_DATA_ERROR, error: ERROR_MSG });
    }
}

function* getNewStoreAllocationsWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                req[key] = payload[key]
            }
        }

        const res = yield call(getNewReservedStoreList, req);
        let { new_reserved_store_details, message, status } = res.data

        if (status) {
            let data = cloneDeep(new_reserved_store_details)
            data.forEach(item => {
                item.reservation_start_date = moment(item.reservation_start_date).format("MM-DD-YYYY")
                item.open_date = moment(item.open_date).format("MM-DD-YYYY")
            })
            yield put({ type: actionTypes.GET_NEW_STORE_LIST_SUCCESS, data });
        }
        else {
            yield put({ type: actionTypes.GET_NEW_STORE_LIST_ERROR, error: message ? message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_NEW_STORE_LIST_ERROR, error: ERROR_MSG });
    }
}

function* getAddProductsData(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (!isEmpty(payload[key])) {
                if (Array.isArray(payload[key]) && key !== "dc") {
                    req[key] = payload[key].map(item => item.value)
                }
                else {
                    req[key] = payload[key]
                }
            }
        }

        const res = yield call(getDemandAddProductsData, req);
        let { data, message, status } = res.data

        if (status) {
            data.forEach(item => {
                item.ros = Number(item.ros) ? Number(item.ros).toFixed(2) : item.ros
                item.aps = Number(item.aps) ? Number(item.aps).toFixed(2) : item.aps
                item.demand_estimated = Number(item.demand_estimated) ? Number(item.demand_estimated).toFixed(2) : item.demand_estimated
            })
            let dataWithSisterStores = data.map(val => {
                return {
                    ...val,
                    ["sister_store"]: [{ label: "example", value: "example" }, { label: "example1", value: "example1" }],
                    ["sister_store-default"]: ""
                }

            })
            yield put({ type: actionTypes.GET_ADD_PRODUCTS_DATA_SUCCESS, data: dataWithSisterStores });
        }
        else {
            yield put({ type: actionTypes.GET_ADD_PRODUCTS_DATA_ERROR, error: message ? message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_ADD_PRODUCTS_DATA_ERROR, error: ERROR_MSG });
    }
}

function* saveNewStoreAllocationWorker(action) {
    try {
        const { payload } = action;

        payload.opening_date = moment(payload.opening_date).format('MM-DD-YYYY')
        payload.reservation_start_date = moment(payload.reservation_start_date).format('MM-DD-YYYY')

        const res = yield call(saveNewStoreInfo, payload);
        if (res.data.status) {
            yield put({ type: actionTypes.SAVE_NEW_STORE_ALLOCATION_SUCCESS });
        }
        else {
            yield put({ type: actionTypes.SAVE_NEW_STORE_ALLOCATION_ERROR, error: res.data?.message ? res.data?.message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.SAVE_NEW_STORE_ALLOCATION_ERROR, error: ERROR_MSG });
    }
}

function* getReviewDetailsWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(getReviewAllocationDetails, payload);
        if (res.data.status) {
            yield put({ type: actionTypes.GET_REVIEW_ALLOCATION_DATA_SUCCESS, data: res.data.data });
        }
        else {
            yield put({ type: actionTypes.GET_REVIEW_ALLOCATION_DATA_ERROR, error: ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_REVIEW_ALLOCATION_DATA_ERROR, error: ERROR_MSG });
    }
}

function* reviewNewStoreAllocationWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(reviewNewStoreAllocation, payload);
        if (res.data.status) {
            yield put({ type: actionTypes.REVIEW_NEW_STORE_ALLOCATION_SUCCESS, data: res.data?.uuid_str });
        }
        else {
            yield put({ type: actionTypes.REVIEW_NEW_STORE_ALLOCATION_ERROR, error: ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.REVIEW_NEW_STORE_ALLOCATION_ERROR, error: ERROR_MSG });
    }
}

function* getArticleAllocationDetailsWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(getArticleAllocation, payload);
        if (res.data.status) {
            let data = cloneDeep(res.data.result)
            data.map(item => {
                item.allocated_total = parseInt(item.allocated_total)
                item.inv_avai = parseInt(item.inv_avai)
            })
            yield put({ type: actionTypes.GET_ARTICLE_ALLOCATION_SUCCESS, data: res.data.result });
        }
        else {
            yield put({ type: actionTypes.GET_ARTICLE_ALLOCATION_ERROR, error: ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_ARTICLE_ALLOCATION_ERROR, error: ERROR_MSG });
    }
}

function* updateArticleAllocationWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(updateArticleAllocation, payload);
        if (res.data.status) {
 
            yield put({ type: actionTypes.UPDATE_ARTICLE_ALLOCATION_SUCCESS, message: "Allocated Units Updated Successfully!!" });
        }
        else {
            yield put({ type: actionTypes.UPDATE_ARTICLE_ALLOCATION_ERROR, message: res?.data?.message ? res.data.message : ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.UPDATE_ARTICLE_ALLOCATION_ERROR, message: ERROR_MSG });
    }
}

function* getNewStoreSizeCurveWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(getNewStoreSizeCurve, payload);
        if (res.data.status) {
            yield put({ type: actionTypes.GET_NEW_STORE_SIZE_CURVE_SUCCESS, data: [res?.data?.data] });
        }
        else {
            yield put({ type: actionTypes.GET_NEW_STORE_SIZE_CURVE_SUCCESS, data: [] });
            // yield put({ type: actionTypes.GET_NEW_STORE_SIZE_CURVE_ERROR, error: ERROR_MSG });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_NEW_STORE_SIZE_CURVE_SUCCESS, data: [] });
        // yield put({ type: actionTypes.GET_NEW_STORE_SIZE_CURVE_ERROR, error: ERROR_MSG });
    }
}

function* getDemandDataWatcher() {
    yield takeLatest(actionTypes.GET_DEMAND_DATA, getDemandData)
}

function* getDemandAddProductsDataWatcher() {
    yield takeLatest(actionTypes.GET_ADD_PRODUCTS_DATA, getAddProductsData)
}

function* getNewStoreAllocationsWatcher() {
    yield takeLatest(actionTypes.GET_NEW_STORE_LIST, getNewStoreAllocationsWorker)
}

function* saveNewStoreAllocationWatcher() {
    yield takeLatest(actionTypes.SAVE_NEW_STORE_ALLOCATION, saveNewStoreAllocationWorker)
}

function* getReviewDetailsWatcher() {
    yield takeLatest(actionTypes.GET_REVIEW_ALLOCATION_DATA, getReviewDetailsWorker)
}

function* reviewNewStoreAllocationWatcher() {
    yield takeLatest(actionTypes.REVIEW_NEW_STORE_ALLOCATION, reviewNewStoreAllocationWorker)
}

function* getArticleAllocationDetailsWatcher() {
    yield takeLatest(actionTypes.GET_ARTICLE_ALLOCATION, getArticleAllocationDetailsWorker)
}

function* updateArticleAllocationWatcher() {
    yield takeLatest(actionTypes.UPDATE_ARTICLE_ALLOCATION, updateArticleAllocationWorker)
}

function* getNewStoreSizeCurveWatcher() {
    yield takeLatest(actionTypes.GET_NEW_STORE_SIZE_CURVE, getNewStoreSizeCurveWorker)
}


export function* demandSaga() {
    yield all([
        getDemandDataWatcher(),
        getDemandAddProductsDataWatcher(),
        getNewStoreAllocationsWatcher(),
        saveNewStoreAllocationWatcher(),
        getReviewDetailsWatcher(),
        reviewNewStoreAllocationWatcher(),
        getArticleAllocationDetailsWatcher(),
        updateArticleAllocationWatcher(),
        getNewStoreSizeCurveWatcher()
    ])
}