import React from "react";
import Modal from "react-awesome-modal";

const DownloadProgressBar = (props) => {
  const { showModal, closeModal, nextIndex, totalCount, hidePercentage } =
    props;

  const getDownloadPerc = () => {
    if (!isNaN((nextIndex / totalCount) * 100)) {
      let value =
        (nextIndex / totalCount) * 100 < 100
          ? (nextIndex / totalCount) * 100
          : 100;
      return value.toFixed(0);
    } else {
      return 1;
    }
  };

  return (
    <Modal
      visible={showModal}
      width="20%"
      // height={downloadExcelLoading ? "20%" : ""}
      effect="fadeInDown"
      // onClickAway={props.closeAlert}
    >
      <div className="modal-header">
        <div
          className="page-heading text-center m-0"
          style={{ fontSize: "14px" }}
        >
          Download Progress
        </div>
        <button type="button" className="close" data-dismiss="modal">
          <span style={{ cursor: "pointer" }}>
            <i
              className="fa fa-times text-right m-2 "
              aria-hidden="true"
              onClick={closeModal}
            />
          </span>
        </button>
      </div>

      <div className="modal-container">
        <div className="modal-middle-container">
          <div className="required row mx-2 p-2 mb-4">
            <div className="mb-4">{`Downloading... ${
              !hidePercentage ? `${getDownloadPerc()}%` : ""
            }`}</div>
            {!hidePercentage && (
              <div class="progress w-100" style={{ height: "20px" }}>
                <div
                  class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={{ width: `${getDownloadPerc()}%` }}
                  aria-valuenow={getDownloadPerc()}
                  aria-valuemin={"0"}
                  aria-valuemax={"100"}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadProgressBar;
