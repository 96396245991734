import React from "react";
import "./CommonModal.css";

const CommonModal = (props) => {
  const {
    heading,
    isOpen,
    content,
    onClose,
    primaryButtonProps,
    tertiaryButtonProps,
  } = props;
  return isOpen ? (
    <div className="modal_container">
      <div className="modal-body__container">
        <div className="modal-header__container">
          <h2 className="modal-header__text">{heading}</h2>
          <i onClick={onClose} className="sc-ejfMa-d dAXtIp modal-close__button"></i>
        </div>
        <div className="modal-content__container">{content}</div>
        <div className="modal-buttons__container">
          {tertiaryButtonProps && (
            <button
              onClick={tertiaryButtonProps.onClick}
              className="btn undo-constraints-mode position-static mr-0"
              title={tertiaryButtonProps.onClick}
            >
              {tertiaryButtonProps.children}
            </button>
          )}
          {primaryButtonProps && (
            <button
              onClick={primaryButtonProps.onClick}
              className="btn btn-primary"
              title={primaryButtonProps.children}
            >
              {primaryButtonProps.children}
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default CommonModal;
