export const CONSTRAINTS_FILE_UPLOAD_INSTRUCTIONS = [
  "The Store Number, Style Color and Product Code must be valid",
  "Min Constraints, Max Constraints and WOS values should be non-negative integers.",
  "Duplicates at the Product Code and Store levels are prohibited.",
  "The Min constraint entered must not be greater than the Max constraint.",
  "At least one of Min Constraints, Max Constraints and WOS should be Non Null.",
  "File should not contain more than 100,000 rows."
];

export const TEMPLATE_FILE_DETAILS = {
  fileName: "Constraints_Upload_Template.xlsx",
  filePath: "Constraints_Upload_Template.xlsx"
};

export const HEADERS = [
  "StyleColor ID",
  "ProductCode",
  "Store",
  "Min",
  "Max",
  "WOS"
];

export const formatExcelData = (data) => {
  return data.map((item) => {
    return {
      "StyleColor ID": item.article,
      ProductCode: item.style_id,
      Store: item.store_code,
      Min: item.min_store,
      Max: item.max_store,
      WOS: item.wos
    };
  });
};
