import React, { useEffect, useState } from "react";
import "../Finalize.css"
import { connect } from "react-redux";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ReactTable from "../../../components/Table/ReactTable"
import { getPastAllocationPO, getStoreData, getStoreDetails, getStoreProductDetails, saveStoreData } from '../FinalizeAction'
import PageLoader from '../../../components/Loader/PageLoader';
import ProductListTable from '../ProductView/ProductListTable'
import ProductDetailsTable from '../ProductView/ProductDetailsTable'
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { numberUSFormatting, oneDecimal, twoDecimal } from "../../../utils/formatters/valueFormatters";
import { updateArticleName } from "../../../utils/filterLevelMapping"
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import DateInput from "../../../components/Table/Cellrenderer/DateInput"
import SetAllModal from "../../../components/ErrorModal/SetAllModal";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import DatePicker, { today } from "../../../components/DatePicker/DatePicker";
import moment from "moment"
import { cloneDeep, forEach } from "lodash";
import { dateFormat, findSum, getNumberOfRecordsPerPages } from "../../../utils/commonUtilities";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
const getTernaryValue = (p_value,p_alternative = null,p_return = null) => {
   return (p_value ? (p_return? p_return : p_value) :p_alternative || "-")
}


const StoreView = (props) => {
    const [selectedStore, setStore] = useState(null)
    const [RTInstance, setRTinstance] = useState({})
    const [showSetAll, setShowSetAll] = useState(false)
    const [dcSA, setDcSA] = useState(null);
    const [storeData, setStoreData] = useState([])
    const [dateSA, setDateSA] = useState(null)
    const [dcSAReq, setDcSAReq] = useState(null)
    const [keyRT, setKeyRT] = useState("")
    const [updatedId, setUpdatedId] = useState([])
    const [initialHiddenColumns, setInitialHiddenColumns] = useState([])

    const setTableInstance = (instance) => {
        props.setTableInstance(instance);
    }

    useEffect(() => {
        if (props.viewAllocationCall) {
            setKeyRT("sortAndSearch")
            setInitialHiddenColumns(["allocated_reserve"]);
        }
        else {
            setKeyRT("storeView")
        }

    }, [props.viewAllocationCall])
    
    useEffect(() => {
      props.isFinalized && setInitialHiddenColumns(["allocated_reserve"]);
  }, [props.isFinalized])

    useEffect(() => {
        if (props.storeDataError) {
            Notify.error("Error in Saving Dates !!");
        }
        if (props.saveSuccess && keyRT === "storeView") {
            Notify.success("Saved Successfully!!");
        }
        setUpdatedId([]);
    }, [props.storeDataError, props.saveSuccess]);

    useEffect(() => {
        setStoreData(props?.storeData)
    }, [props?.storeData])

    useEffect(() => {
        if(props.activeTab == "fsv" && props.isPOASNFlow){
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.isPOASNFlow) {
                req["article"] = props.articles
            }
            props.getStoreData(req)
        }
        if (props.activeTab == "fsv" && !(props.viewAllocationCall && storeData.length) && !props.isPOASNFlow) {
            let req = {
                allocationcode: props.allocationCode
            }
            if (props.viewAllocationCall) {
                req["page"] = "viewPastAllocation"
                if (props.releasedDate) {
                    req["released_date"] = props.releasedDate
                }
                // props.getPastAllocationPO({ allocationcode: props.allocationCode, articles:props.rowArticles})
            }
            props.getStoreData(req)
        }
    }, [props.activeTab,props.isPOASNFlow])

    const getStoreDetails = (isStore, store) => {
        if (isStore) {
            setStore(store)
        }
        let req = { allocationcode: props.allocationCode, store_code: isStore ? store : selectedStore }
        if(props.isPOASNFlow) {
            req['article'] = props.articles
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        props.getStoreDetails(req)
    }

    const getStoreProductDetails = (isSetInstance, instance) => {

        let req = {
            allocationcode: props.allocationCode,
            article: isSetInstance ? instance.article : RTInstance.article,
            store_code: selectedStore
        }
        if (props.viewAllocationCall) {
            req["page"] = "viewPastAllocation"
        }
        if (props.releasedDate) {
            req["released_date"] = props.releasedDate
        }
        props.isPOASNFlow && (req["partial_articles"] = props.articles)
        props.getStoreProductDetails(req)
        if (isSetInstance) {
            setRTinstance({
                allocationcode: props.allocationCode,
                store_code: selectedStore,
                article: instance.article,
                color: instance.color
            })
        }
    }


    const storeDatacolumns = React.useMemo(() => [
        {
            Header: ' ',
            sticky: "left",
            columns: [{
                Header: 'Store Number',
                accessor: 'store_code',
                Cell: (instance) => (
                    <div>
                        <div className="clickable" onClick={() => getStoreDetails(true, instance.row?.original?.store_code)}>{instance.row?.original?.store_code}</div>
                    </div>
                ),
                width: 200,
                Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
                filter: 'bulkFilterSpaceSeperated',
            }]
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [{
                Header: "Store Name",
                accessor: "store_name",
                Filter: ColumnFilter
            }]
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Store Grade",
                    accessor: "store_grade",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Climate",
                    accessor: "climate",
                    Filter: ColumnFilter,
                    filter: 'exactText',
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [
                {
                    Header: "Country",
                    accessor: "country",
                    Filter: ColumnFilter,
                }
            ],
        },
        {
            Header: ' ',
            sticky: "left",
            columns: [{
                Header: '# Style Color',
                accessor: 'style_color_cnt',
                id: "style_color_cnt",
                Filter: ColumnFilter
            }]
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Actual APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'OH',
            accessor: 'oh',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'On Order',
            accessor: 'oo',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'In Transit',
            accessor: 'it',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "LW Sales Units",
            accessor: "lw_qty",
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "WTD Sales Units",
            accessor: "tw_qty",
            Cell: (instance) => (
                <div>
                    {instance?.row?.original.tw_qty !== null ? instance?.row?.original.tw_qty: "-"}   
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'Max Constraint',
            accessor: 'max',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Allocated Quantity",
            identify: "allocated_qty",
            columns: [
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
            ]
        },
        {
            Header: 'Min Allocation',
            accessor: 'allocated_for_min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Wos Allocation',
            accessor: 'allocated_for_wos',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos ? twoDecimal(row.target_wos) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos ? twoDecimal(row.actual_wos) : 0,
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Shipping Dates",
            identify: "dates",
            columns: []
        }
    ])


    const storeProductDatacolumns = React.useMemo(() => [
        {
            Header: " ",
            sticky: "left",
            columns: [{
                Header: "  ",
                sticky: "left",
                columns: [{
                    Header: `${updateArticleName(false)} ID`,
                    accessor: 'article',
                    Cell: (instance) => (
                        <div>
                            <div className="clickable" onClick={() => getStoreProductDetails(true, instance.row?.original)}>{instance.row?.original?.article}</div>
                        </div>
                    ),
                    Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
                    filter: 'bulkFilterCommaSeperated',
                },
                {
                    Header: 'Style Description',
                    accessor: 'style_name',
                    width: 200,
                    Filter: ColumnFilter,
                },
                {
                    Header: 'Style Color',
                    accessor: 'color',
                    Filter: ColumnFilter,
                }
                ]
            }]
        },
        {
            Header: 'LMS Store Value',
            accessor: 'lms_attribute_value',
            Cell: (instance) => <ReactTollTip {...instance}/>,
            width: 200,
            Filter: ColumnFilter,
        },
        {
            Header: "LW Sales Units",
            accessor: "lw_qty",
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "WTD Sales Units",
            accessor: "tw_qty",
            Cell: (instance) => (
                <div>
                    {instance?.row?.original.tw_qty !== null ? instance?.row?.original.tw_qty: "-"}   
                </div>
            ),
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: 'Max Constraint',
            accessor: 'max',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: 'Min Constraint',
            accessor: 'min',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
        },
        {
            Header: "Allocated Reserve",
            accessor: "allocated_reserve",
            Filter: NumberRangeColumnFilter,
            filter: 'between'
        },
        {
            Header: "Allocated Quantity",
            identify: "allocated_qty",
            columns: [
                {
                    Header: 'Packs Allocated',
                    accessor: 'packs',
                    Filter: ColumnFilter
                },
                {
                    Header: '# Packs Allocated',
                    accessor: 'packs_allocated',
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                {
                    Header: '# Eaches Allocated',
                    accessor: 'loose_units_allocated',
                    Cell: (inst) => numberUSFormatting(inst?.value),
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                },
                // {
                //     Header: 'Total Allocated Units',
                //     accessor: 'allocated_quantity',
                //     Cell: (inst) => numberUSFormatting(inst?.value),
                //     Filter: NumberRangeColumnFilter,
                //     filter: 'between',
                //     width: 200
                // }
            ]
        },
        {
            Header: 'Net DC Available',
            identify: "dc_available",            
            columns:[],
            // accessor: 'dc_available',
            // Cell: (inst) => numberUSFormatting(inst?.value),
            // Filter: NumberRangeColumnFilter,
            // filter: 'between',
        },
        {
            Header: 'Original APS',
            accessor: row => row.original_aps ? oneDecimal(Number(row.original_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted APS',
            accessor: row => row.forecast_aps ? oneDecimal(Number(row.forecast_aps)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Target WOS',
            accessor: row => row.target_wos ? twoDecimal(Number(row.target_wos)) : 0,
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Forecasted WOS',
            accessor: row => row.actual_wos ? twoDecimal(Number(row.actual_wos)) : 0,
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: NumberRangeColumnFilter,
            filter: 'between',

        },
        {
            Header: 'Size',
            identify: "size",
            columns: []
        },
    ])

    const allocationDataColumns = React.useMemo(() => [
        {
            Header: '# Style Color',
            accessor: "style_color",
            id: "style_color_cnt",
        },
        {
            Header: '# Allocated Stores',
            accessor: "store_cnt"
        },
        {
            Header: 'StyleColor depth per store',
            accessor: "style_color_depth_per_store",
            width: 200
        },
        {
            Header: 'Allocated Quantity',
            columns: [],
            // accessor: "allocated_units_total",
            // Cell: (inst) => numberUSFormatting(inst?.value)
        },
        {
            Header: 'Net DC Available',
            columns: [],
            // accessor: "inventory_available",
            // Cell: (inst) => numberUSFormatting(inst?.value)
        },
        {
            Header: 'Store Grade - Allocation Qty',
            columns: [],
        },
        {
            Header: 'Store Grade - Allocation Index',
            columns: [],
        }
    ])

    const setProductListSizeColumns = () => {
        // let columns = props.columns ? props.columns : productDatacolumns
        if (props.storeDetails.sizes.length) {
            let sizeIndex = storeProductDatacolumns.findIndex(col => col.identify === "size");
            let qtyIndex = storeProductDatacolumns.findIndex(col => col.identify === "allocated_qty");
            let dcIndex = storeProductDatacolumns.findIndex(col => col.identify === "dc_available");

            props.storeDetails.sizes.forEach((s, index) => {
                storeProductDatacolumns[sizeIndex].columns.push({
                    Header: s,
                    columns: [{
                        Header: "Allocated",
                        id: `Allocated${s}`,
                        accessor: row => row.size_value[index] ? Number(row.size_value[index]).toFixed(0) : 0,
                        Filter: NumberRangeColumnFilter,
                        filter: 'between',
                    },
                    {
                        Header: "Forecast",
                        id: `Forecast${s}`,
                        accessor: row => row.demand_size[index] ? Number(row.demand_size[index]).toFixed(0) : 0,
                        Filter: NumberRangeColumnFilter,
                        filter: 'between',
                    }],
                })
            })
            props?.storeDetails?.dc_list?.length && props?.storeDetails?.dc_list?.map((val) =>{
                if(val){
                    storeProductDatacolumns[qtyIndex].columns.push({
                      Header: val + " ",
                      accessor: row => row.dc?.[val]?.["allocated_quantity"] || 0,
                      Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                      Filter: NumberRangeColumnFilter,
                      filter: 'between',
                      width: 200
                  })
                  storeProductDatacolumns[dcIndex].columns.push({
                    Header: val,
                    accessor: row => row.dc?.[val]?.["dc_available"] || 0,
                    Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',
                  })
                }  
            })
            storeProductDatacolumns.splice(qtyIndex, 0, {
                Header: "Total Allocated Units",
                accessor: row => findSum(row?.size_value) || 0,
                Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            })   
            // setTableColumns(columns)
            return true
        }
        return false
    }

    const callAllStoreApis = () => {
        let req = {
            allocationcode: props.allocationCode
        }
        if (props.isPOASNFlow) {
            req["article"] = props.articles
        }
        props.getStoreData(req)
        getStoreDetails(false)
        getStoreProductDetails(false)
    }

    const setDateColumns = () => {
        if (storeData.length) {
            let data = storeData[0]
            let dc_names = data.dc_names
            let storeDCs = props?.storeDCs
            let dateIndex = storeDatacolumns.findIndex(col => col.identify === "dates");

            storeDCs.map((dc, index) => {
                storeDatacolumns[dateIndex].columns.push({
                  Header: `DC - ${dc}`,
                  // accessor: `DC - ${dc}`,
                  Cell: (instance) =>
                    props.viewAllocationCall ? (
                      <>
                        {getTernaryValue(
                          instance.row.original?.[`DC - ${dc}`],
                          "-"
                        )}
                      </>
                    ) : instance.row.original?.[`DC - ${dc}`] ? (
                      <DateInput
                        {...instance}
                        value={
                          instance.row.original?.[`DC - ${dc}`]
                          // || instance.row.original.dates[index]
                        }
                        dateFormat={"MM-DD-YYYY"}
                        changeHandler={(rowIdx, columnId, value) => {
                          setDateSA(moment(value).format("MM/DD/YYYY"));
                          instance.updateMyData(rowIdx, `DC - ${dc}`, value);
                          setStoreData((oldData) => {
                            let newData = cloneDeep(oldData);
                            newData[rowIdx][columnId] = value;
                            return newData;
                          })
                          setUpdatedId((old) => {
                            return [...old, instance.row.original?.store_code]
                        })
                        }}
                        isDisabled={props.isFinalized}
                      />
                    ) : (
                      "-"
                    ),
                  width: 200,
                  disableFilters: true,
                });
            })

            let qtyIndex = storeDatacolumns.findIndex(col => col.identify === "allocated_qty");

            storeDCs?.length && storeDCs?.map((val) =>{
                if(val){
                    storeDatacolumns[qtyIndex].columns.push({
                      Header: val,
                      accessor: row => row.dc?.[val]?.["allocated_total"] || 0,
                      Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                      Filter: NumberRangeColumnFilter,
                      filter: 'between',
                  })
                //   allocationDataColumns[4].columns.push({
                //       Header: val + "n",
                //       accessor: row => row.dc[val]["non_allocated_qty"],
                //       Cell: (inst) => numberUSFormatting(inst?.value)
                //   })
                }  
            })
            storeDatacolumns.splice(qtyIndex, 0, {
                Header: 'Total Allocated Units',
                accessor: row => findSum(Object.values(row.dc).map(item => item.allocated_total)),
                Cell: (inst) => inst?.value ? numberUSFormatting(inst?.value) : 0,
                Filter: NumberRangeColumnFilter,
                filter: 'between',
            })
            return true
        }
        return false
    }

    const onDcSAChange = (value, event, that) => {
        let valuesArray = handleSelectAll(value, event, that)
        setDcSA(valuesArray[1]);
        setDcSAReq(valuesArray[0]);
    }

    const getIntersectionOptions = () => {
        let prepOptions = []
        let storeDCs = props?.storeDCs
        if (storeDCs?.length) {
            storeDCs.forEach((val, indx) => {
                prepOptions.push({
                    label: `DC - ${val}`,
                    value: `DC - ${val}`
                })
            })
        }
        return prepOptions;
    }

    const prepInput = React.useMemo(() => [
        {
            id: 1,
            isRequired: false,
            label: "DC",
            name: "dc",
            className: "setAll__filter",
            dropdownValue: dcSA,
            onDropDownChange: onDcSAChange,
            placeHolder: `Select DC`,
            optionsArray: getIntersectionOptions(),
            isMultiSelect: true,
        },
        {
            id: 1,
            isRequired: false,
            label: "Calendar",
            name: "calendar",
            className: "setAll__filter",
            input: true,
            Comp: <DatePicker minOrmax={{ min: today }}
            onChange={(val) => setDateSA(moment(val).format("MM/DD/YYYY"))} dateFormat="MM/DD/yyyy" />
        },
    ], [dcSA, props.tableInstance?.state?.selectedRowIds])

    const handleSetAllOk = () => {
        let setAllRows = Object.keys(props.tableInstance.state.selectedRowIds)
        if (dcSAReq?.length && dateSA) {
            setStoreData((oldData) => {
                let newData = cloneDeep(oldData);
                setAllRows.forEach(setAllRow => {
                    dcSAReq.forEach(dcReq => {
                        newData[setAllRow][dcReq.label] = dateSA
                        setUpdatedId((old) => {
                            return [...old,  newData[setAllRow]?.store_code]
                        })
                    })
                })
                return newData;
            });
        }
    }

    const handleSetAllCancel = () => {
        setShowSetAll(false);
    }

    const handleClose = () => {
        showSetAll && setShowSetAll(false);
    }

    const setAllHandler = (p_instance) => {
        if (Object.keys(p_instance.state.selectedRowIds).length > 0) {
            setShowSetAll(true);
            setDcSA(null)
            setDcSAReq(null)
            setDateSA(null)
        }
        else {
            Notify.error(`Please select atleast 1 store to use Set All functionality!!`)
        }
    }

    const handleSave = (p_instance) => {
        const { allocationCode, articles, storeDCs, saveStoreData } = props;
    
        let uniqueUpdatedIds = [...new Set(updatedId)]
        let currentSelectedStores = p_instance?.data?.filter(val => uniqueUpdatedIds.includes(val.store_code))
        let stores = {};
    
        
        forEach(currentSelectedStores, (store) => {
            let shipping_date = {}
            store.dc_names.map((dc, index) => {
            store[`DC - ${dc}`] &&
                (shipping_date[`${store.dc_codes[index]}`] = store[`DC - ${dc}`]
                ? dateFormat(store[`DC - ${dc}`])
                : "");
            
            });  
            stores[`${store.store_code}`] = {
                shipping_date,
            };
        });
    
        let req = {
          stores: stores,
          allocation_code: allocationCode,
          articles: articles,
        };
        saveStoreData(req)
      };

    const prepareStoreGradeColumns = () => {
        let data = props.storeAllocationData[0]
        data?.store_grades?.map((sg, index) => {
            if(sg){
                allocationDataColumns[5].columns.push({
                    Header: sg + " ",
                    accessor: row => row.store_allocated_qty[index],
                    width: data?.store_grades?.length == 1 ? 200 : 100
                })
                allocationDataColumns[6].columns.push({
                    Header: sg,
                    accessor: row => row.store_allocated_index[index],
                    width: data?.store_grades?.length == 1 ? 200 : 100
                })

            }
        })
        data?.dc && Object.keys(data?.dc)?.map((val) =>{
          if(val){
            allocationDataColumns[3].columns.push({
                Header: val + " ",
                accessor: row => row.dc[val]["allocated_units_total"],
                Cell: (inst) => numberUSFormatting(inst?.value)
            })
            allocationDataColumns[4].columns.push({
                Header: val,
                accessor: row => row.dc[val]["inventory_available"],
                Cell: (inst) => numberUSFormatting(inst?.value)
            })
          }  
        })
        allocationDataColumns.splice(3, 0, {
            Header: 'Total Allocated Units',
            accessor: row => findSum(Object.values(row.dc).map(item => item.allocated_units_total)),
        })
    }

    return (

        <div className="store__view__tables">
            {/* <Notification /> */}
            {showSetAll && !props.viewAllocationCall && <SetAllModal
                modalBodyStyle={{ height: '33rem' }}
                heading="Set Dates"
                disableApply={(dcSA && dateSA && dateSA !== "Invalid date") ? false : true }
                filter={prepInput} handleOk={handleSetAllOk} handleCancel={handleSetAllCancel} handleClose={() => handleClose()} />}
            <div className="f__table">
                <PageLoader loader={props.storeDataLoading} gridLoader={true}>
                    <div className="storesStore__heading">
                        <h1 className="fnt-md fnt-bold">Allocation Summary</h1>
                    </div>
                    {prepareStoreGradeColumns()}
                    {(props.storeDataError) ? (<div className="error">{props.storeDataError}</div>) :
                        (props.storeAllocationData ? (
                            <ReactTableWithPlugins
                                data={(props.storeAllocationData ? props.storeAllocationData : [])}
                                columns={allocationDataColumns}
                                renderMarkup='TableMarkup'
                                tableId="finalize_store_view"
                            />
                        ) : null)}
                </PageLoader>
            </div>
            <div className="f__table" >

                {/* <PageLoader loader={props.storeDataLoading} gridLoader={true}> */}
                {setDateColumns()}
                {(props.storeDataError) ? (<div className="error">{props.storeDataError}</div>) :
                    (storeData.length ? (
                        <>
                            <div className="storesStore__heading">
                                <h1 className="fnt-md fnt-bold">Stores Details</h1>
                            </div>
                            <ReactTableWithPlugins
                                shouldPagination
                                // embededScroll
                                totalRecordsLen={storeData ? storeData.length : 0}
                                tableWrapperStyle={{ height: 'fit-content', maxHeight: '60rem' }}
                                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                data={
                                    // props.tableInstance?.data.length ? props.tableInstance?.data : 
                                    (storeData ? storeData : [])}
                                sortBy={[{ id: 'store_code', desc: false }]}
                                columns={storeDatacolumns}
                                features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                                renderMarkup='TableMarkup'
                                keyRT={keyRT}
                                getInstanceOnMount={(instance) => {
                                    setTableInstance(instance)
                                }}
                                tableId="finalize_store_details"
                                pageSize={getNumberOfRecordsPerPages("finalize_store_view",storeData.length)}
                                pagesList={[10, 20, 30]}
                            />
                        </>
                    ) : null)}
                {/* </PageLoader> */}
                {!props.viewAllocationCall && !props.isFinalized && !props?.storeDataError && !props.allocationStatus &&
                <div div style={{ textAlign: "center", margin: '2rem' }}>
                    <button
                        style={{ margin: '2px 1rem' }}
                        className="btn btn-primary"
                        onClick={() => setAllHandler(props.tableInstance)}
                    >
                        Set Dates
                    </button>
                    <button
                    style={{ margin: "2px 1rem" }}
                    className="btn btn-primary"
                    onClick={() => handleSave(props.tableInstance)}
                    >
                        Save
                    </button>
                </div>}
            </div>
            <hr />
            <div className="store__products__table f__table">
                <PageLoader loader={props.storeDetailsDataLoading && !props.storeDataLoading} gridLoader={true}>
                    {(props.storeDetailsDataError) ? (<div className="error">{props.storeDetailsDataError}</div>) :
                        (props.storeDetails.data.length ? (
                            setProductListSizeColumns() &&
                            <>
                                <div className="storesStore__heading">
                                    <h1 className="fnt-md fnt-bold">{selectedStore}</h1>
                                    <h1 className="fnt-md fnt-bold">Articles</h1>
                                </div>
                                <ProductListTable data={props.storeDetails.data} sizes={props.storeDetails.sizes}
                                    columns={storeProductDatacolumns} initialHiddenColumns={initialHiddenColumns} />
                            </>
                        ) : null)}
                </PageLoader>
            </div>

            <div className="f__table">
                <PageLoader loader={props.storeProductDetailsLoading && !props.storeDetailsDataLoading} gridLoader={true}>
                    {(props.storeProductDetailsError) ? (<div className="error">{props.storeProductDetailsError}</div>) :
                        (props.storeProductDetails.data.length ? (
                            <ProductDetailsTable
                                isFinalized={props.isFinalized}
                                viewAllocationCall={props.viewAllocationCall}
                                releasedDate={props.releasedDate}
                                callAllApis={callAllStoreApis}
                                data={props.storeProductDetails.data}
                                selectedRow={RTInstance}
                                getProductDetails={getStoreProductDetails}
                                sizes={props.storeProductDetails.sizes}
                                productDetails = {props.storeProductDetails}
                                allocationcode={props.allocationCode}   
                                originalAllocationCode={props.originalAllocationCode}  
                                articles={props?.articles}
                            />
                        ) : null)}
                </PageLoader>
            </div>

        </div>
    )
}

const mapStateToProps = ({ finalize }) => {
    return {
        storeDataLoading: finalize.storeDataLoading,
        storeData: finalize.storeData,
        storeDCs: finalize.storeDCs,
        storeDataError: finalize.storeDataError,
        storeDetailsDataLoading: finalize.storeDetailsDataLoading,
        storeDetails: finalize.storeDetailsData,
        storeDetailsDataError: finalize.storeDetailsDataError,
        storeProductDetailsLoading: finalize.storeProductDetailsLoading,
        storeProductDetails: finalize.storeProductDetails,
        storeProductDetailsError: finalize.storeProductDetailsError,
        storeAllocationData: finalize.storeAllocationData,
        saveSuccess: finalize.saveSuccess,
        allocationStatus: finalize.allocationStatus    
    }
}

const mapDispatchToProps = (dispatch) => ({
    getStoreData: (payload) => dispatch(getStoreData(payload)),
    getStoreDetails: (payload) => dispatch(getStoreDetails(payload)),
    getStoreProductDetails: (payload) => dispatch(getStoreProductDetails(payload)),
    getPastAllocationPO: (payload) => dispatch(getPastAllocationPO(payload)),
    saveStoreData: (payload) => dispatch(saveStoreData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreView)