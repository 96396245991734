import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  FETCH_FILTER_DATA,
  fetchFilterDataSuccesss,
  fetchFilterDataError,
  FETCH_USER_CREATED_DATA,
  fetchUserCreatedDataSuccess,
  fetchUserCreatedDataError,
  FETCH_STORES_DATA,
  fetchStoresDataSuccess,
  fetchStoresDataError,
  fetchCreateFilterData,
  FETCH_CREATEFILTER_DATA,
  fetchCreateFilterSuccesss,
  fetchCreateFilterError,
  FETCH_PRODUCT_LIST_CONTRIBUTION_DATA,
  fetchProductListContributionSuccesss,
  fetchProductListContributionError,
  FETCH_PROFILED_PRODUCT_DATA,
  fetchProfiledProductSuccess,
  fetchProfiledProductError,
  SAVE_PRODUCT_PROFILE_DATA,
  saveProductProfileSuccess,
  saveProductProfileError,
  FETCH_SAVED_DATA,
  fetchSavedDataSuccess,
  fetchSavedDataError,
  DELETE_PRODUCT_PROFILE,
  deleteProductProfileSuccess,
  deleteProductProfileError
} from "./ProductProfileAction";

import {
  getProductProfileFilterData,
  getProductProfileData,
  getStoresData,
  getProductProfileCreateFilterData,
  getProductListContributionData,
  getProfiledProductData,
  saveProductProfile,
  getSavedData,
  deleteProductProile,
} from "../../routes/api";

import { isEmpty, map } from "lodash";
import React from 'react';
import ColumnFilter from "../UserManagement/ColumnFilter";
import { dateFormat, flattenArray, isObject } from "../../utils/commonUtilities";
import moment from "moment";
import { ERROR_MSG } from "../../constants/globalConstant";


function* fetchStoresDataWorker(action) {
  let req_body = action.payload
  
  try {
    const response = yield call(getStoresData,req_body)

    let l_sizeArr = response?.data?.sizeArr.reduce((a,b)=> (a[b]='-',a),{});
    let l_responseWithAllSizeArr = response?.data?.storeData.map(val => {
      return{
            ...val,
            'size_data':{...l_sizeArr,...val.size_data}
          }
      })

    let func = (p_val) => {
      for (let i in p_val) {
        p_val[i] = Number(p_val[i])?(Number(p_val[i])*100).toFixed(1)+" %":"-"
      }
      return p_val
    }

    let resposneWithPercentage = l_responseWithAllSizeArr.map(val => {
      return {
        ...val,
        overall: Number(val.overall)?(Number(val.overall)*100).toFixed(1)+" %":"-",
        size_data:func(val.size_data)
      }
    })


    let responseWithSizeData = resposneWithPercentage?.map(val => {
      return {
        ...val,
        ...val.size_data
      }
    })


    let userCreatedProfileTabsColumns = []

    userCreatedProfileTabsColumns.push({
      Header:'Store Number',
      accessor:'store_code',
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
      filter: 'bulkFilterSpaceSeperated',
    },
    {
      Header:'Overall',
      accessor:'overall',
      Filter: ColumnFilter
    })

      for (let val of response?.data?.sizeArr) {
        userCreatedProfileTabsColumns.push({
          Header: val,
          accessor: (data) => data[val],
          Filter: ColumnFilter
        })
      }


    let resp = {
      data: {'storeData': responseWithSizeData, 'articleData': response?.data?.articleData, 'column': userCreatedProfileTabsColumns,},
      status: response?.data?.status,
      error: response?.data?.error
    }


    if (response?.data?.status) {
      yield put(fetchStoresDataSuccess({ data: resp.data }));
    }
    else{
      yield put(fetchStoresDataError({ error: resp.error }));
    }

  } catch (error) {
      yield put(fetchStoresDataError(error));
  }
}

function* fetchUserCreatedDataWorker(action) {
  
  try {
    let l_payload = action.payload
    let arrayProperty = {};
    for (var key in l_payload) {
      if(Array.isArray(l_payload[key])){
        arrayProperty[key] = l_payload[key].map((ele) => ele.value)
      }
      else if(isObject(l_payload[key])){
        arrayProperty[key] = [l_payload?.[key]?.value]
      }
    }
      let req_body = {
         "l1_name":[l_payload?.level1?.value],
      }
  
      if(l_payload?.page){
        req_body["page"] = l_payload?.page
      }
  
      if(arrayProperty['level2']?.length > 0) {
        req_body["l2_name"] = arrayProperty['level2']
      }
  
      if(arrayProperty['level3']?.length > 0) {
        req_body["l3_name"] = arrayProperty['level3']
      }
  
      if(arrayProperty['level4']?.length > 0) {
        req_body["l4_name"] = arrayProperty['level4']
      }
  
      if(arrayProperty['level5']?.length > 0) {
        req_body["l5_name"] = arrayProperty['level5']
      }
  
      if(arrayProperty['level6']?.length > 0) {
        req_body["l6_name"] = arrayProperty['level6']
      }
  
      if(arrayProperty['level7']?.length > 0) {
        req_body["l7_name"] = arrayProperty['level7']
      }
  
      if(arrayProperty['colour']?.length > 0) {
        req_body["color"] = arrayProperty['colour']
      }
  
      if(arrayProperty['style_name']?.length > 0) {
        req_body["style_name"] = arrayProperty['style_name']
      }
      req_body["row_index"] = l_payload?.row_index
      req_body["row_count"] = l_payload?.row_count
      req_body["searchColumns"] = l_payload?.searchColumns
      req_body["sortColumn"] = l_payload?.sortColumn

    const response = yield call(getProductProfileData,req_body)

    let getSalesAttribute = val => {
      let l_salesattribute = ""
      if(Number(val?.clearance)){
        l_salesattribute+='Clearance, '
      }
      if(Number(val?.regular)){
        l_salesattribute+='Regular, '
      }
      if(Number(val?.promo)){
        l_salesattribute+='Promo'
      }
      return l_salesattribute
    }

    let responseWithSalesAttribute
    
    if(!l_payload?.page){
       responseWithSalesAttribute = response.data.data.map(val => {
        let timeStamp = moment.utc(val.updated_at)
        return {
          ...val,
          'sales_attribute': getSalesAttribute(val),
          'l3_name': (val.l3_name.replace("{","").replace("}","").replaceAll('"'," ")),
          'l4_name': (val.l4_name.replace("{","").replace("}","").replaceAll('"'," ")),
          'creation_date': val.updated_at ? dateFormat(val.updated_at) : '',
          'creation_time': val.updated_at ? timeStamp.format('hh:mm:ss') : ''
        }
      })
    }
    else{
      responseWithSalesAttribute = response.data.data
    }

    let resp = {
      data: responseWithSalesAttribute,
      status: {...response.status},
      error: {...response.error}
    }

    if (response.status) {
      yield put(fetchUserCreatedDataSuccess({ data: resp.data, totalCount: response.data.totalCount, nextIndex: response.data.row_index }));
    }
    else{
      yield put(fetchUserCreatedDataError({ error: resp.error }));
    }

  } catch (error) {
      yield put(fetchUserCreatedDataError(error));
  }
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload,filterType } = action;
    let req = {};
    if (!isEmpty(payload)) {
      for (const key in payload) {
        if(!isEmpty(payload?.[key]?.[0])) {
            req[key] = flattenArray(payload[key]).map((ele) => ele.value)
        }
      }
    }
    if(filterType){
      req["filter_type"] = filterType;
  }
    const res = yield call(getProductProfileFilterData, req);
    console.log('ss',res)
    if (res.data.status) {
      console.log('ss1')
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      // let topObject = Object.keys(res.data.data[0])[0]
      // if(filterType != "style"){
        for (const key in res.data.data[0]) {
        let k = "";
          if (key === "l1_name") {
            k = "level1Options";
          } else if (key === "l2_name") {
            k = "level2Options";
          } else if (key === "l3_name") {
            k = "level3Options";
          } else if (key === "l4_name") {
            k = "level4Options";
          } else if (key === "l5_name") {
            k = "level5Options";
          } else if (key === "l6_name") {
            k = "level6Options";
          }else if (key === "l7_name") {
            k = "level7Options";
          }
          else if(key === "style_name") {
            k = "styleOptions";
          } 
          else if(key === "color") {
              k = "colourOptions";
          }
          else if(key === "country") {
            k = "countryOptions"
          }
          // if(key==="color" || key === "style_name") {
          //     data[k] = res.data.data.map((element) => ({
          //         value: element[key],
          //         label: element[key],
          //       }));
          // }
          // else{
              data[k] = res.data.data[0][key].filter(element => element).map((element) => ({
                  value: element,
                  label: element,
                }));
          // }
        }     
      // }
      // else{
      //     data["styleOptions"] = res.data.data
      // }
      console.log('ss2')
      yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key  }));
    }
    else {
      console.log('ss3')
      yield put(fetchFilterDataError({ error: res.error}));
    }
  } catch (error) {
    console.log(error,'ss4')
    yield put(fetchFilterDataError({ error: ERROR_MSG }));
  }
}

function* fetchCreateFilterDataWorker(action) {
  try {
    const { payload } = action;
    const res = yield call(getProductProfileCreateFilterData, payload);

    if (res.data.status) { 
      let data = {};

      for(let i in res.data) {
        if(i === "colorFilterData" || i === "launchDateFilterData"){
        let property = res.data[i][0] && Object.keys(res.data[i][0])[0]
         data[i] = res.data[i]
        //  .slice(1,100)
         .map(val => {
          return {
            label: val[property],
            value: val[property]
          }
        })
      }
      else if (i === "pmFilterData") { 
        let l_pmFilterData = res.data[i][0]
          for(let j in l_pmFilterData) {
            if(j !== "max_price") {
              data[j] = l_pmFilterData?.[j]?.map(val => {
                  return {
                    label: val,
                    value: val
                  }
              })
            }
          }

          // if(!payload.hasOwnProperty('l7_name')){
          //   data['l7_name'] = res.data[i][0]['l7_name'].filter(element => element).map(val => {
          //     return {
          //         label: val,
          //         value: val
          //     }
          //   })
          // }

          if(payload.hasOwnProperty('l7_name_options')){
            data['l7_name'] = payload.l7_name_options
          }

          if(!payload.hasOwnProperty('l6_name')){
            data['l6_name'] = res.data[i][0]['l6_name'].filter(element => element).map(val => {
              return {
                  label: val,
                  value: val
              }
            })
          }

          if(payload.hasOwnProperty('l6_name_options')){
            data['l6_name'] = payload.l6_name_options
          }

          if(!payload.hasOwnProperty('l5_name')){
            data['l5_name'] = res.data[i][0]['l5_name'].filter(element => element).map(val => {
              return {
                  label: val,
                  value: val
              }
            })
          }

          if(payload.hasOwnProperty('l5_name_options')){
            data['l5_name'] = payload.l5_name_options
          }

          if(!payload.hasOwnProperty('l4_name')){
            data['l4_name'] = res.data[i][0]['l4_name'].filter(element => element).map(val => {
              return {
                  label: val,
                  value: val
              }
            })
          }

          if(payload.hasOwnProperty('l4_name_options')){
            data['l4_name'] = payload.l4_name_options
          }
          
          if(!payload.hasOwnProperty('l3_name')){
            data['l3_name'] = res.data[i][0]['l3_name'].filter(element => element).map(val => {
              return {
                  label: val,
                  value: val
                }
            })
            // data['l3_name'] = payload?.level3Options;
          }
          
          if(payload.hasOwnProperty('l3_name_options')){
            data['l3_name'] = payload.l3_name_options
          }

          data = {...data}   
          console.log(data,payload,'l_pmFilterData') 
      }
        // else if (i === "pmFilterData" && !res.data[i][0]['l5_name']) {
        //   if(!payload.hasOwnProperty('level4')){
        //     data['l4_name'] = res.data[i][0]['l4_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //     data['l3_name'] = payload?.level3Options
        //   }
        //   if(payload.hasOwnProperty('level4')){
        //     data['l4_name'] = payload?.level4Options
        //     data['l3_name'] = payload?.level3Options
        //   }
        //     if(!payload.hasOwnProperty('level3') && !payload.hasOwnProperty('level4')){
        //     data['l3_name'] = res.data[i][0]['l3_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //   }
        // }
        // else if (i === "pmFilterData" && res.data[i][0]['l5_name']){
        //   if(!payload.hasOwnProperty('level4')){
        //     data['l4_name'] = res.data[i][0]['l4_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //     data['l3_name'] = payload?.level3Options
        //   }
        //   if(!payload.hasOwnProperty('level5')){
        //     data['l5_name'] = res.data[i][0]['l5_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //     data['l3_name'] = payload?.level3Options
        //   }
        //   if(payload.hasOwnProperty('level4')){
        //     data['l4_name'] = payload?.level4Options
        //     data['l3_name'] = payload?.level3Options
        //   }
        //     if(!payload.hasOwnProperty('level3') && !payload.hasOwnProperty('level4')){
        //     data['l3_name'] = res.data[i][0]['l3_name'].filter(element => element).map(val => {
        //       return {
        //           label: val,
        //           value: val
        //       }
        //     })
        //   }

        // }
      }

      data['max_price'] = res.data['pmFilterData'][0]['max_price']
      
      yield put(fetchCreateFilterSuccesss({ data: data }));
    }
    else {
      yield put(fetchCreateFilterError({ error: res.error}));
    }
  } catch (error) {
    console.log(error,'l_pmFilterData')
    yield put(fetchCreateFilterError({ error: ERROR_MSG }));
  }
}

function* fetchProductListContributionDataWorker(action) {
  try {
    const { payload } = action;

    let l_req_body = {}

    for(let i in payload){
      if((payload[i] || payload[i] === 0)){
        if(Array.isArray(payload[i]) && payload[i].length){
          l_req_body[i] = payload[i].map(val => {
            return val.value
          })
        } 
        else if(((typeof(payload[i])) === "object")){
          if(i === "sales_attribute"){
            let sales_attribute = []
            for(let j in payload[i]){
              if(payload[i][j]){
                sales_attribute.push(j)
              }
             }
             if(sales_attribute.length){
              l_req_body[i] = sales_attribute
             }
          }
          else if(! (payload[i].value == "All")){
            l_req_body[i] = payload[i].value
          }
        }
        else {
          l_req_body[i] = payload[i]
        }
      }
    }


    const res = yield call(getProductListContributionData, l_req_body);

    if (res.data.status) {
      yield put(fetchProductListContributionSuccesss({ data: res.data.data }));
    }
    else {
      yield put(fetchProductListContributionError({ error: res.data?.message }));
    }
  } catch (error) {
    yield put(fetchProductListContributionError({ error: ERROR_MSG }));
  }
}

function* fetchProfiledProductWorker(action) {
  try {
    const { payload } = action;

    let l_req_body = {}

    l_req_body['product_code'] = payload.product_code
    l_req_body['static_dynamic'] = payload.static_dynamic
    l_req_body['level1'] = payload.level1.value
    l_req_body['level2'] = payload.level2.value
    if(payload?.country) {
      l_req_body['country'] = payload.country.map(item => item.value).filter(country => country !== "*")
    }
    if(payload.sales_attribute){
      let sales_attribute = []
      for(let j in payload.sales_attribute){
        if(payload.sales_attribute[j])
        sales_attribute.push(j)
       }
       if(sales_attribute.length){
        l_req_body['sales_attribute'] = sales_attribute
       }
    }
    if(payload.days){
      l_req_body['days'] = payload.days.value
    }
    else{
      l_req_body['min_date'] = payload.min_date
      l_req_body['max_date'] = payload.max_date
    }


    const response = yield call(getProfiledProductData, l_req_body);
    

    let l_sizeArr = response?.data?.unique_sizes.reduce((a,b)=> (a[b]='-',a),{});
    let l_responseWithAllSizeArr = response?.data?.data.map(val => {
      return{
            ...val,
            'size_data':{...l_sizeArr,...val.size_level_proportion}
          }
      })

    let func = (p_val) => {
      for (let i in p_val) {
        p_val[i] = Number(p_val[i])?(Number(p_val[i])*100).toFixed(1)+" %":"-"
      }
      return p_val
    }

    let resposneWithPercentage = l_responseWithAllSizeArr.map(val => {
      return {
        ...val,
        overall: Number(val.overall_proportion)?(Number(val.overall_proportion)*100).toFixed(1)+" %":"-",
        "size_data":func(val.size_data)
      }
    })


    let responseWithSizeData = resposneWithPercentage?.map(val => {
      return {
        ...val,
        ...val.size_data
      }
    })


    let userCreatedProfileTabsColumns = []

    userCreatedProfileTabsColumns.push({
      Header:'Store Number',
      accessor:'store_code',
      Filter:(instance) => <ColumnFilter {...instance} placeholder="Search in bulk..." />,
      filter: 'bulkFilterSpaceSeperated',
      Cell: ({ value, row }) =>
        <div className="row min-100">
          <span className={"min-100 pt-2 "+ (row?.original?.impute_store_tag ? "sister-store__map": "")}>
            {value}
          </span>
        </div>
    },
    {
      Header:'Overall',
      accessor:'overall',
      Filter: ColumnFilter,
      Cell: ({ value, row }) =>
      <div className="row min-100">
        <span className={"min-100 pt-2 "+ (row?.original?.impute_store_tag ? "sister-store__map": "")}>
          {value}
        </span>
      </div>
    })

      for (let val of response?.data?.unique_sizes) {
        userCreatedProfileTabsColumns.push({
          Header: val,
          accessor: (data) => data[val],
          Filter: ColumnFilter,
          Cell: ({ value, row }) =>
          <div className="row min-100">
            <span className={"min-100 pt-2 "+ (row?.original?.impute_store_tag ? "sister-store__map": "")}>
              {value}
            </span>
          </div>
        })
      }



    let resp = {
      data: {'profiledData': responseWithSizeData, 'column': userCreatedProfileTabsColumns, 'originalData':response?.data?.data},
      status: response?.data?.status,
      error: response?.data?.error
    }


    if (response.data.status) {
      yield put(fetchProfiledProductSuccess({ data: resp.data }));
    }
    else {
      yield put(fetchProfiledProductError({ error: resp.error }));
    }
  } catch (error) {
    yield put(fetchProfiledProductError({ error: ERROR_MSG }));
  }
}

function* saveProductProfileWorker(action) {
  try {
    const { payload } = action;
    let payloadFilters = payload.filters
    let l_req_body = {}

    for(let i in payloadFilters){

        if(Array.isArray(payloadFilters[i]) ){
          if(i ==="color_description"){
            l_req_body["color"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="season_code"){
            l_req_body["launchDateFilterData"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="level3"){
            l_req_body["level3"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="level4"){
            l_req_body["level4"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="level5"){
            l_req_body["level5"] = payloadFilters[i].map(val => {
              return val.value
            })
          }if(i ==="level6"){
            l_req_body["level6"] = payloadFilters[i].map(val => {
              return val.value
            })
          }if(i ==="level7"){
            l_req_body["level7"] = payloadFilters[i].map(val => {
              return val.value
            })
          }
          if(i ==="country"){
            l_req_body["country"] = payloadFilters[i].map(val => val.value).filter(country => country !== "*")
          }
        } 
        else if(((typeof(payloadFilters[i])) === "object") && payloadFilters[i]!= null){
          if(i === "sales_attribute"){
            l_req_body['regular'] = payloadFilters[i].Regular?1:0
            l_req_body['clearance'] = payloadFilters[i].Clearance?1:0
            l_req_body['promo'] = payloadFilters[i].Promo?1:0
          }
          else if( i === "season_code"){
            l_req_body["launchDateFilterData"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level3"){
            l_req_body["level3"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level4"){
            l_req_body["level4"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level5"){
            l_req_body["level5"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level6"){
            l_req_body["level6"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          } 
          else if( i === "level7"){
            l_req_body["level7"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          if(i == "color_description"){
            l_req_body["color"] = payloadFilters[i]?payloadFilters[i]:[]
          }
          else if(i === "days"){
            l_req_body["period"] = payloadFilters[i]?.value?payloadFilters[i]?.label:""
          }
          else{
            l_req_body[i] = payloadFilters[i]?.value?payloadFilters[i]?.value:""
          }
        }
        else {
          if(i == "profileDescription"){
            l_req_body["description"] = payloadFilters[i] 
          }
          else if(i == "profileName"){
            l_req_body["name"] = payloadFilters[i] 
          }
          else if(i == "season_code") {
            l_req_body["launchDateFilterData"] = payloadFilters[i]?payloadFilters[i]:[]
          }
          else if(i == "color_description"){
            l_req_body["color"] = payloadFilters[i]?payloadFilters[i]:[]
          }
          else if( i === "level3"){
            l_req_body["level3"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level4"){
            l_req_body["level4"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level5"){
            l_req_body["level5"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level6"){
            l_req_body["level6"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else if( i === "level7"){
            l_req_body["level7"] = payloadFilters[i]?.value?[payloadFilters[i]?.value]:[]
          }
          else{
            l_req_body[i] = (payloadFilters[i] || payloadFilters[i] == 0)?payloadFilters[i]:""
          }
        }
    }

    let savedData = {}

    for(let i in payloadFilters){
      if(Array.isArray(payloadFilters[i]) && i ==="color_description"){
          savedData[i] = payloadFilters[i].map(val => {
            return val.value
          })
      } 
      else if(((typeof(payloadFilters[i])) === "object") && payloadFilters[i]!= null){
        if(i === "sales_attribute"){
          savedData[i] = payloadFilters[i]
          for( let j in savedData[i]){
            savedData[i][j] = 1
          }
        }
        else{
          savedData[i] = payloadFilters[i]
        }
          savedData[i] = payloadFilters[i]
      }
      else {
        savedData[i] = (payloadFilters[i] || payloadFilters[i] == 0)?payloadFilters[i]:""
      }
  }

  savedData['profiledProductData'] = payload?.profiledProductData
  savedData['profiledProductColumns'] = payload?.profiledProductColumns
  savedData['colourOptions'] = payload?.colourOptions
  savedData['seasonCodeOptions'] = payload?.seasonCodeOptions
  savedData['level7Options'] = payload?.level7Options
  savedData['level6Options'] = payload?.level6Options
  savedData['level5Options'] = payload?.level5Options
  savedData['level4Options'] = payload?.level4Options
  savedData['level3Options'] = payload?.level3Options
  savedData['level2Options'] = payload?.level2Options

  let updated_req_body = {
    ...l_req_body,
    'articleData': payload?.filters.articleData,
    'storeData': payload?.storeData,
    'savedData': savedData,
    "user_created_by": payload?.user_created_by
  }


    const res = yield call(saveProductProfile, updated_req_body);

    if (res.data.status) {
      yield put(saveProductProfileSuccess({ data: res.data.data }));
    }
    else if(res.data?.status === false) {
      yield put(saveProductProfileError({ error: "Duplicate" }));
    }
    else {
      yield put(saveProductProfileError({ error: res.error }));
    }
  } catch (error) {
    yield put(saveProductProfileError({ error: ERROR_MSG }));
  }
}

function* fetchSavedDataWorker(action) {
  try {
    const { payload } = action;
    let l_req_body = {
      "product_profile_code" : payload
    }

    const res = yield call(getSavedData, l_req_body);

    if (res.data.status) {
      yield put(fetchSavedDataSuccess({ data: res.data.data }));
    }
    else {
      yield put(fetchSavedDataError({ error: res.error }));
    }
  } catch (error) {
    yield put(fetchSavedDataError({ error: ERROR_MSG }));
  }
}

function* deleteProductProfileWorker(action){
  try {
    const { payload } = action;
    let l_req_body = {
      "product_profile_code" : payload
    }

    const res = yield call(deleteProductProile, l_req_body);

    if (res.data.status) {
      yield put(deleteProductProfileSuccess({ data: res.data.data }));
    }
    else {
      yield put(deleteProductProfileError({ error: res.error }));
    }
  } catch (error) {
    yield put(deleteProductProfileError({ error: ERROR_MSG }));
  }
  
}


function* fetchStoresDataWatcher() {
  yield takeLatest(FETCH_STORES_DATA, fetchStoresDataWorker);
}

function* fetchUserCreatedDataWatcher() {
  yield takeLatest(FETCH_USER_CREATED_DATA, fetchUserCreatedDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* fetchCreateFilterDataWatcher() {
  yield takeLatest(FETCH_CREATEFILTER_DATA, fetchCreateFilterDataWorker);
}

function* fetchProductListContributionDataWatcher() {
  yield takeLatest(FETCH_PRODUCT_LIST_CONTRIBUTION_DATA, fetchProductListContributionDataWorker)
}

function* fetchProfiledProductWatcher() {
  yield takeLatest(FETCH_PROFILED_PRODUCT_DATA, fetchProfiledProductWorker)
}

function* saveProductProfileWatcher() {
  yield takeLatest(SAVE_PRODUCT_PROFILE_DATA, saveProductProfileWorker)
}

function* fetchSavedDataWatcher() {
  yield takeLatest(FETCH_SAVED_DATA, fetchSavedDataWorker)
}

function* deleteProductProfileWatcher(){
  yield takeLatest(DELETE_PRODUCT_PROFILE, deleteProductProfileWorker)
}

export function* productProfileSaga() {
  yield all([
    fetchUserCreatedDataWatcher(),
    fetchFilterDataWatcher(),
    fetchStoresDataWatcher(),
    fetchCreateFilterDataWatcher(),
    fetchProductListContributionDataWatcher(),
    fetchProfiledProductWatcher(),
    saveProductProfileWatcher(),
    fetchSavedDataWatcher(),
    deleteProductProfileWatcher(),
  ]);
}
