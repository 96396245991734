import React, { useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "../../../components/Loader/PageLoader";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import {
  getStoreCapacity,
  saveStoreCapacity,
  reset,
} from "./actions/StoreCapacityAction";
import "../../ProductStoreStatus/ProductStoreStatus.css";
import { useState } from "react";
import InputCell from "../../../components/Table/Cellrenderer/InputCell";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { cloneDeep } from "lodash";
import * as Notify from "../../../components/Notification/Notifications";
import { calculateMaximumEaches } from "./helperFunctions";
import { isEmpty } from "lodash";
import EditPackDetails from "../ProductView/EditPackDetails";
import { getPackDetails, resetEditUnitsSuccess } from "../FinalizeAction";
import AvailableTable from "./AvailableTable";
import { useRef } from "react";

const PARALLEL_API_COUNT = 10;

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const StoreCapacity = (props) => {
  const {
    allocationCode,
    getStoreCapacityData,
    saveStoreCapacityData,
    storeCapacityData,
    storeCapacityLoading,
    activeTab,
    saveSuccess,
    saveError,
    articles,
    isFinalized
  } = props;

  const [tableData, setData] = useState([]);
  const [dcAvailableMap, setDcMap] = useState({});
  const [editData, setEditData] = useState({});
  const [dcUpdated, setDcUpdated] = useState({});
  const [currentAPICallIndex, setCurrentIndex] = useState(0);
  const [tableColumns, setTableColumns] = useState([]);
  const [packsAvailable, setPacksAvailable] = useState({});
  const [packEditData, setPackEditData] = useState({});
  const [packsUpdated, setPacksUpdated] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [articleData, setArticleData] = useState([]);
  const childRef = useRef();

  const columns = [
    {
      id: "expander", // Make sure it has an ID
      Header: " ",
      sticky: "left",

      columns: [
        {
          Header: "Store number",
          accessor: "store_code",
          Cell: ({ value, row }) =>
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            row.canExpand ? (
              <div className="row" {...row.getToggleRowExpandedProps()}>
                <span className="expand__text__number">{value}</span>
                {row.isExpanded ? (
                  <div className="cursor-pointer missing-config-color">
                    <i
                      className="fa fa-angle-down fa-lg ml-2 expand-collapse"
                      title="Collpase"
                    ></i>
                  </div>
                ) : (
                  <div className="cursor-pointer missing-config-color">
                    <i
                      className="fa fa-angle-right fa-lg ml-2 expand-collapse"
                      title="Expand"
                    ></i>
                  </div>
                )}
              </div>
            ) : (
              <span className="store-group-mapping-color-width-height"> </span>
            ),
          disableFilters: true,
        },
      ],
    },
    {
      Header: "Store Name",
      accessor: "store_name",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
      width: 200,
    },
    {
      Header: "Department",
      accessor: "dept",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Capacity Category",
      accessor: "capacity_category",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Store Capacity",
      accessor: "capacity",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Store Inventory",
      accessor: "store_inv",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Allocated Reserve",
      accessor: "allocated_reserve",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "Net Store Capacity Available",
      accessor: "net_capacity_available",
      Cell: ({ value, row }) =>
        row.canExpand ? (
          <span {...row.getToggleRowExpandedProps()}>{value}</span>
        ) : (
          <span className="store-group-mapping-color-width-height"> </span>
        ),
      disableFilters: true,
    },
    {
      Header: "# Style Color",
      accessor: "article_count",
      disableFilters: true,
    },
    {
      Header: "Style Color",
      accessor: "article",
      disableFilters: true,
    },
    {
      Header: "OH+OO+IT",
      accessor: "oh_oo_it",
      // Cell: ({ value, row }) =>
      //   row.canExpand ? (
      //     <span {...row.getToggleRowExpandedProps()}>
      //       {row.original.oh + row.original.oo + row.original.it}
      //     </span>
      //   ) : (
      //     <span>{row.original.oh_oo_it}</span>
      //   ),
      disableFilters: true,
    },
    {
      Header: "Total Allocated Units",
      accessor: (row) =>
        Object.values(row.allocated_quantity_size).reduce((pre, curr) => pre + curr),
      disableFilters: true,
    },
    {
      Header: "Eaches Allocated",
      identify: "eaches",
      disableFilters: true,
    },
    {
      Header: "DC Available",
      identify: "dc_available",
      disableFilters: true,
    },
  ];

  useEffect(() => {
    if (activeTab === "fsc") {
      setTableColumns(columns);
      getData();
    }
  }, [activeTab]);

  const getData = () => {
    setData([]);
    let req = { 
      allocationcode: allocationCode 
    }
    if(articles?.length) {
      req["article"] = articles
    }
    getStoreCapacityData(req);
  };

  useEffect(() => {
    if (storeCapacityData?.data?.length) {
      setData(storeCapacityData);
      createArticleData(storeCapacityData?.data);
    }
  }, [storeCapacityData]);

  useEffect(() => {
    // This IF condition checking whether all the parallel api calls are done or not
    let allArticles = [];
    if (!isEmpty(editData)) {
      allArticles = Object.keys(editData);
    }
    if (!isEmpty(packEditData)) {
      allArticles = [...allArticles, ...Object.keys(packEditData)];
    }
    if (saveSuccess && currentAPICallIndex >= allArticles?.length) {
      Notify.success("Allocated Units Edited Sucessfully!!");
      props.reset();
      setDcMap({});
      setPacksAvailable({});
      setEditData({});
      setDcUpdated({});
      getData();
    } else if (saveSuccess) {
      // Parallel api calls once the previous set of calls getting completed
      let originalAllocationCode =
        allocationCode === props.originalAllocationCode
          ? ""
          : props.originalAllocationCode;
      saveStoreCapacityData({
        allocationCode,
        dcAvailableMap,
        packsAvailable,
        editData,
        packEditData,
        originalAllocationCode,
        index: currentAPICallIndex,
        API_COUNT: PARALLEL_API_COUNT,
      });
      setCurrentIndex((prev) => prev + PARALLEL_API_COUNT); // Setting the current index of an article to have the parallel save api calls
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (saveError) {
      Notify.error(saveError);
      props.reset();
    }
  }, [saveError]);

  const onEdit = (
    dc,
    article,
    store_code,
    size,
    updatedDCValue,
    entered_value,
    focusedValue,
    eachesDCAvailable,
    index
  ) => {
    setDcUpdated((old) => {
      return {
        ...old,
        [article]: {
          ...old?.[article],
          [dc]: eachesDCAvailable,
        },
      };
    });

    setDcMap((old) => {
      return {
        ...old,
        [article]: {
          ...old?.[article],
          [dc]: {
            ...old?.[article]?.[dc],
            [size]: updatedDCValue,
          },
        },
      };
    });

    setEditData((old) => {
      return {
        ...old,
        [article]: {
          ...old?.[article],
          [store_code]: {
            ...old?.[article]?.[store_code],
            [dc]: {
              ...old?.[article]?.[store_code]?.[dc],
              [size]: entered_value,
            },
          },
        },
      };
    });
    childRef.current.updateAvailableData(entered_value, focusedValue, article, size, dc);
  };

  //This function will call the save api for the first time among the parallel calls
  const onSave = () => {
    setCurrentIndex(1);
    let originalAllocationCode =
      allocationCode === props.originalAllocationCode
        ? ""
        : props.originalAllocationCode;
    saveStoreCapacityData({
      allocationCode,
      dcAvailableMap,
      packsAvailable,
      editData,
      packEditData,
      originalAllocationCode,
      index: 0,
      API_COUNT: 1,
    });
  };

  const getMaximum = (instance, dcKey, size, index) => {
    const maxValue = calculateMaximumEaches(
          editData,
          tableData.data,
          dcAvailableMap,
          instance,
          dcKey,
          size,
          index
        )

    return maxValue;
  };

  const getEachesDCAvailable = (row, dcKey, store_code, size, value, focusedValue) => {
    // const preDCValue = dcUpdated?.[row.article]?.[dcKey] >= 0
    //   ? dcUpdated?.[row.article]?.[dcKey]
    //   : row.dc_available_size_final?.[dcKey];
    const preDCValue = row[`dcavailable${dcKey}`];

    // const preSizeValue =
    //   editData?.[row.article]?.[store_code]?.[dcKey]?.[size] >= 0
    //     ? editData?.[row.article]?.[store_code]?.[dcKey]?.[size]
    //     : row.size_value?.[dcKey]?.[row?.size?.[dcKey]?.indexOf(size)];
    const preSizeValue = focusedValue
    const updatedDCValue =
      (preDCValue || 0) - (Number(value) - (preSizeValue || 0));
    return updatedDCValue;
  };

  useEffect(() => {
    if (tableData?.sizes?.length) {
      let eachesIndex = columns.findIndex((col) => col.identify === "eaches");
      let dcIndex = columns.findIndex((col) => col.identify === "dc_available");
      columns[eachesIndex].columns = [];
      columns[dcIndex].columns = [];

      tableData?.dcs?.forEach((dc) => {
        let name = Object.values(dc)[0];
        let key = Object.keys(dc)[0];
        columns[eachesIndex].columns.push({
          Header: name,
          id: `${name + eachesIndex}`,
          accessor: (row) => row.allocated_quantity_size[key],
          disableFilters: true,
        });
        columns[dcIndex].columns.push({
          Header: name,
          // id: `dcavailable${key}`,
          accessor: `dcavailable${key}`,
          // Cell: (instance) => {
          //   const row = instance.row.original;
          //   return row?.dc_available_size_final?.hasOwnProperty(key) ? (
          //     <span>
          //       {row.value ? row.value : row.dc_available_size_final?.[key]}
          //       {/* {Number(dcUpdated?.[row.article]?.[key]) >= 0
          //         ? dcUpdated?.[row.article]?.[key]
          //         : row.dc_available_size_final?.[key]} */}
          //     </span>
          //   ) : (
          //     ""
          //   );
          // },
          disableFilters: true,
        });
      });

      tableData?.sizes?.forEach((size) => {
        let index;
        columns.push({
          Header: size,
          // columns: [
          //   {
          //     Header: "Eaches Allocated",
          columns: tableData.dcs.map((dc) => {
            let dcKey = Object.keys(dc)[0];
            return {
              Header: Object.values(dc)[0],
              id: dcKey + size,
              accessor: (row) => {
                index = row.size?.[dcKey]?.indexOf(size);
                return row?.[dcKey + size] || row.size_value?.[dcKey]?.[index];
              },
              Cell: (instance) => {
                index = instance.row?.original?.size?.[dcKey]?.indexOf(size);
                return !isFinalized && !instance.row.canExpand &&
                  instance.row.original?.size?.[dcKey]?.includes(size) ? (
                  <InputCell
                    {...instance}
                    style={{ textAlign: "center" }}
                    min={0}
                    max={getMaximum(instance, dcKey, size, index)}
                    step={1}
                    type="number"
                    blurHandler={(
                      rowIdx,
                      columnId,
                      values,
                      row_id,
                      temp_id,
                      row1,
                      focusedValue
                    ) => {
                      if(Number(focusedValue) !== Number(values)) {
                        const parentRowId = Number(
                          instance.row.id.split(".").slice(0, -1).join(".")
                        ); // string
                        const store_code =
                          tableData?.data?.[parentRowId].store_code;
                        const row = instance.row.original;
                        const preDCValue =
                          dcAvailableMap?.[row.article]?.[dcKey]?.[size] >= 0
                            ? dcAvailableMap?.[row.article]?.[dcKey]?.[size]
                            : row.eaches_available?.[dcKey]?.[size];

                        const preSizeValue =
                          editData?.[row.article]?.[store_code]?.[dcKey]?.[
                            size
                          ] >= 0
                            ? editData?.[row.article]?.[store_code]?.[dcKey]?.[
                                size
                              ]
                            : row.size_value?.[dcKey]?.[
                                row?.size?.[dcKey]?.indexOf(size)
                              ];
                        const updatedDCValue =
                          (preDCValue || 0) -
                          (Number(values) - (preSizeValue || 0));

                        const eachesDCAvailable = getEachesDCAvailable(
                          row,
                          dcKey,
                          store_code,
                          size,
                          values,
                          focusedValue
                        );
                        onEdit(
                          dcKey,
                          row.article,
                          store_code,
                          size,
                          updatedDCValue,
                          Number(values),
                          focusedValue,
                          eachesDCAvailable,
                          index
                        );
                        instance.updateMyData(rowIdx, `dcavailable${dcKey}`, eachesDCAvailable, row_id);
                      }
                    }}
                  />
                ) : (
                  instance?.value || ""
                );
              },
              disableFilters: true,
            };
          }).concat([{
            Header: 'OH+OO+IT',
            id: `OH+OO+IT_${size}`,
            accessor: row => row?.oh_oo_it_size_value[size],
            disableFilters: true
            }]),
          //   },
          // ],
        });
      });
      setTableColumns(columns);
    }
  }, [tableData]);

  const createArticleData = (data) => {
    let articles = [];
    data.forEach((row) => {
      articles = articles.concat(row.subRows);
    });
    setArticleData(articles);
  };

  return (
    <>
      <div className="store__view__tables p-4">
        <PageLoader loader={storeCapacityLoading} gridLoader={true}>
          {!!articleData?.length && (
            <AvailableTable
              ref={childRef}
              data={articleData}
              tableData={tableData}
            />
          )}
          <ReactTableWithPlugins
            tableWrapperStyle={{ height: "fit-content", maxHeight: "60rem" }}
            headerWrapperStyle={{ position: "sticky", top: 0, zIndex: "4" }}
            embededScroll
            data={tableData?.data || []}
            columns={tableColumns}
            containSubRow={true}
            // shouldPagination
            renderMarkup="TableMarkup"
            keyRT="sortAndSearch"
            tableId="store_capacity"
            hideColumnsFilter
            isHideCount={true}
            hideDropdown
            totalCount={tableData?.data?.length}
          />
          <div className="row justify-content-center">
            <button
              onClick={onSave}
              className="btn btn-primary products_lower_Div_button"
              disabled={
                !Object.keys(dcAvailableMap)?.length
              }
            >
              Save
            </button>
          </div>
        </PageLoader>
      </div>
    </>
  );
};

const mapStateToProps = ({ storeCapacity }) => {
  return {
    storeCapacityLoading: storeCapacity.storeCapacityLoading,
    storeCapacityError: storeCapacity.storeCapacityError,
    storeCapacityData: storeCapacity.storeCapacityData,
    newAllocationCode: storeCapacity.alloacationCode,
    saveSuccess: storeCapacity.saveSuccess,
    saveError: storeCapacity.saveError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStoreCapacityData: (payload) => dispatch(getStoreCapacity(payload)),
  saveStoreCapacityData: (payload) => dispatch(saveStoreCapacity(payload)),
  getPackDetails: (payload) => dispatch(getPackDetails(payload)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreCapacity);
