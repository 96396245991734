import React, { useEffect, useRef, useState } from "react";
import SetAllModal from "../ErrorModal/SetAllModal";
import { Typography } from "@mui/material";
import CommonModal from "../CommonModal";
import { saveAs } from "file-saver";
import PageLoader from "../Loader/PageLoader";
import * as Notify from "../../components/Notification/Notifications";
import Modal from "react-awesome-modal";
import "./UploadHandler.css";
import XLSX from 'xlsx'

const MAX_RECORDS = 100000;

const UploadHandler = (props) => {
  const {
    heading,
    showModal,
    setShowModal,
    hideDownloadTemplateButton,
    uploadInstructions,
    templateDetails,
    uploadApi,
    maxFileSize,
    headers
  } = props;

  const [file, setFile] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [maxLimitError, setMaxLimitError] = useState();
  const [disableUpload, setDisableUpload] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fileUploadRef = useRef();

  const changeHandler = (event) => {
    if (event.target.files.length) {
      const { files } = event.target;
      setFile(files[0]);
      if (maxFileSize) {
        const sizeInMegabytes = (files[0].size / (1024 * 1024)).toFixed(2);
        if (sizeInMegabytes >= maxFileSize) {
          setMaxLimitError(`File size should be < ${maxFileSize}MB`);
          setDisableUpload(true);
          return;
        }
      }
      setDisableUpload(false);
    }
  };

  const validateHeaders = () => {
    const reader = new FileReader();
    
    reader.onload = function(e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      
      // Get the headers (first row)
      const columns = [];
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      const rowCount = range.e.r - range.s.r + 1;
      if(rowCount <= 1) {
        Notify.error("File must contain at least one row.");
        return;
      }
      else if(rowCount > MAX_RECORDS + 1) {
        Notify.error("File should not contain more than 100,000 rows.");
        return;
      }
      const firstRow = range.s.r;
      
      for(let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({r: firstRow, c: col});
        const cell = worksheet[cellAddress];
        const header = cell ? cell.v : `Column ${col + 1}`;
        columns.push(header);
      }
      const missingHeaders = headers.filter(item => !columns.includes(item));
      if(missingHeaders?.length) {
        Notify.error(`${missingHeaders.join(", ")} header${missingHeaders?.length > 1 ? "s " : " "} ${missingHeaders?.length > 1 ? "are" : "is"} missing in the file.`)
      }
      else {
        handleUpload();
      }
    };
    reader.readAsArrayBuffer(file);
  }

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      const res = await uploadApi(formData);
      setIsLoading(false);
      if (res.data?.status) {
        Notify.success(res.data?.message);
        setShowModal(false);
      }
      else {
        const error = res?.data?.message || "File upload failed."
        Notify.error(error);
      }
    } catch (error) {
      Notify.error("File upload failed.");
    }
  };


  const UploadContainer = () => {
    return (
      <PageLoader loader={isLoading}>
        <div className="upload-modal-body">
          <div className="d-flex align-items-center flex-column upload_btn__container">
            <input
              type="file"
              id="docpicker"
              multiple={false}
              accept=".csv,.xlsx"
              onChange={changeHandler}
              ref={fileUploadRef}
              className="upload-file-input"
            />
            <button
              className="btn btn-primary px-2 upload_btn mb-4"
              onClick={() => {
                fileUploadRef.current?.click();
              }}
            >
              <i
                className="fa fa-upload"
                title="Configure"
                aria-hidden="true"
              ></i>
            </button>
            {file ? (
              <Typography variant="body1">Filename: {file.name}</Typography>
            ) : (
              <Typography variant="body1">
                Select a file.
                {" (.xlsx/.csv)"}
                {maxFileSize ? `, Size should be < ${maxFileSize}MB` : ""}
              </Typography>
            )}
            {/* {maxLimitError ? ( */}
            <span className="mt-2 text-danger file-size-error">
              {maxLimitError}
            </span>
            {/* ) : null} */}
          </div>
          <div className="d-flex justify-content-center mt-5 upload_file_buttons">
            {!hideDownloadTemplateButton && (
              <button
                className="btn btn-primary px-2"
                onClick={() => {
                  setShowUploadModal(true);
                }}
              >
                Download Template
              </button>
            )}
            <div>
              <button
                className="btn undo-constraints-mode px-2 position-static"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                disabled={disableUpload}
                className="btn btn-primary px-2"
                onClick={() => (headers?.length ? validateHeaders() : handleUpload())}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </PageLoader>
    );
  };

  const downloadTemplate = async () => {
    try {
      const { filePath, fileName } = templateDetails;
      // Trigger the file download
      const fileToBeDownloaded = await import(
        `../../Assets/Templates/${filePath}`
      );
      saveAs(fileToBeDownloaded.default, fileName);
    } catch (error) {
      Notify.error("Error in download")
    }
  };

  return (
    <>
      <Modal
        visible={showModal}
        effect="fadeInDown"
        onClickAway={() => setShowModal(false)}
        width="30rem"
      >
        {/* <Notification /> */}

        <div className="modal-container">
          <div className="modal-header">
            <h5 className="page-heading text-center m-0">{heading}</h5>
            <button type="button" className="close" data-dismiss="modal">
              <span className="pointer">
                <i
                  className="fa fa-times text-right m-2"
                  aria-hidden="true"
                  onClick={() => setShowModal(false)}
                />
              </span>
            </button>
          </div>
          <div className="modal-middle-container px-5 pb-5">
            <UploadContainer />
          </div>
        </div>
      </Modal>
      <CommonModal
        size="medium"
        heading="File upload validations"
        isOpen={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        primaryButtonProps={{
          children: "Download",
          onClick: () => {
            downloadTemplate();
            setShowUploadModal(false);
          },
        }}
        tertiaryButtonProps={{
          children: "Cancel",
          onClick: () => setShowUploadModal(false),
        }}
        content={
          <ul>
            {[...uploadInstructions].map((instruction) => (
              <li>
                <Typography variant="text" component="span">
                  {instruction}
                </Typography>
              </li>
            ))}
          </ul>
        }
      ></CommonModal>
      {/* <SetAllModal
        modalBodyStyle={{ height: "25rem", justifyContent: "space-around" }}
        messageStyle={{ margin: "auto", color: "red" }}
        handleOk={handleUpload}
        handleCancel={() => setShowModal(false)}
        handleClose={() => setShowModal(false)}
        disableApply={true}
        modalBody={uploadContainer}
        heading={heading}
        hideFooter={true}
        modalClass={"upload-modal"}
      /> */}
    </>
  );
};

export default UploadHandler;
