import React, { useEffect } from "react";
import XLSX from 'xlsx'
import XML from 'xml-js'
import { forEach } from "lodash";
import { saveFile } from '../../utils/fileSave'

const ExportFile = React.memo((props) => {
    const {
      fileName,
      text,
      data,
      type,
      callFunc,
      sheetName,
      downloadExcelData,
      formateXLSX,
      excelColumnsToBeFormatted,
      disabled
    } = props;

    const downloadExcel = () => {
        if (data.length) {
            const worksheet = XLSX.utils.json_to_sheet(data)
            if (formateXLSX) {
              let range = XLSX.utils.decode_range(worksheet["!ref"]);

              // This function formats the numbers and add $ for currency coloumns.
              forEach(excelColumnsToBeFormatted, (C) => {
                for (let R = range.s.r; R <= range.e.r; ++R) {
                  let addr = XLSX.utils.encode_cell({
                    r: R + 1,
                    c: C.value ? C.value : C,
                  });

                  if (worksheet[addr] && worksheet[addr].v && worksheet[addr].v != "-") {
                    worksheet[addr].t = "n";
                    if (C.isCurruncy) {
                      worksheet[addr].z = "$ #,##0.00";
                    } else if (!C?.isFractional) {
                      worksheet[addr].z = "#,##0";
                    } else {
                      worksheet[addr].z = "#,##0.00";
                    }
                  }
                }
              });
            }

            const workbook = {}
            if(sheetName){
                workbook.Sheets = {
                    [sheetName] : worksheet
                }
                workbook.SheetNames = [sheetName]
            }
            else {
                workbook.Sheets = {
                    "Sheet1": worksheet
                }
                workbook.SheetNames = ["Sheet1"]
            }
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
            saveFile(excelBuffer, `${fileName}.xlsx`)
        }
    }

    const downloadXml = () => {
        var result = XML.json2xml(data, { compact: true });
        saveFile(result, `${fileName}.xml`)
    }

    const options = {
        "1": downloadExcel,
        "2": downloadXml
    }

    useEffect(() => {
        if(props.downloadExcelData) {
            downloadExcel()
        }
    }, [props.downloadExcelData])

    return (
        <button disabled={disabled} className="btn btn-primary px-2" onClick={callFunc ? callFunc : options[type]}>
            <i
                className="fa fa-download mr-2"
                title="Configure"
                aria-hidden="true"
            ></i>
            {text}
        </button>
    )
})

export default ExportFile;
